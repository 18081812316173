import React, { useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb, Box, Flex, Button, Text } from '@chakra-ui/react';
import { FaSearch, FaCut } from 'react-icons/fa';

import getCroppedImg from './CropImage';

import '../Styles/Cropper.css';

function ImageCropper(props) {
  const [isLoading, setLoading] = useState(false);
  const [croppedArea, setCroppedArea] = useState({});
  const [zoom, setZoom] = useState(1);

  const [cropData, setCropData] = useState({
    imageUrl: props.imageUrl,
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspectRatio: 4 / 3,
  });

  const onCropChange = (crop) => {
    setCropData((cropDatas) => ({
      ...cropDatas,
      crop,
    }));
  };

  const onCropComplete = async (croppedAreas, croppedAreaPixels) => {
    console.log(croppedAreas);
    setCroppedArea(croppedAreaPixels);
  };

  const onZoomChange = (zooms) => {
    setCropData((cropDatass) => ({
      ...cropDatass,
      zooms,
    }));

    setZoom(zoom);
  };

  const onCropConfirmed = (e) => {
    e.preventDefault();
    setLoading(true);

    getCroppedImg(props.imageUrl, croppedArea).then((croppedImage) => {
      props.imageUpdater(croppedImage);
      console.log(croppedImage);
      setCropData({
        imageUrl: croppedImage.url,
        zoom: 1,
        crop: { x: 0, y: 0 },
        aspectRatio: 4 / 3,
      });

      setZoom(1);

      setLoading(false);
    });
  };

  useEffect(() => {
    setCropData((cropData) => ({
      ...cropData,
      imageUrl: props.imageUrl,
    }));
  }, [props.imageUrl]);

  return (
    <Flex width="100%" as="section" direction="column" minWidth="0" minHeight="0" alignItems="flex-start" zIndex="1">
      <Flex
        position="relative"
        height="300px"
        width="400px"
        maxWidth="400px"
        zIndex="2"
        className="crop-container"
        boxShadow="0 0 0.2rem rgb(0 0 0 / 50%)">
        <Cropper
          image={cropData.imageUrl}
          crop={cropData.crop}
          zoom={cropData.zoom}
          aspect={cropData.aspectRatio}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
          classes={{ cropAreaClassName: 'dark' }}
        />
      </Flex>

      <Flex className="controls" width="400px" mt={3}>
        <Slider
          aria-label="slider-ex-4"
          defaultValue={1}
          min={1}
          max={3}
          step={0.01}
          value={zoom}
          onChange={(zoom) => {
            onZoomChange(zoom);
            setZoom(zoom);
          }}>
          <SliderTrack bg="green.100">
            <SliderFilledTrack bg="primary" />
          </SliderTrack>
          <SliderThumb boxSize={6} _focus={{ outline: 'none' }} boxShadow="0px 0px 0px .05rem #4bac61">
            <Box color="primary" as={FaSearch} />
          </SliderThumb>
        </Slider>
      </Flex>

      <Button
        width="400px"
        onClick={(e) => {
          onCropConfirmed(e);
        }}
        variant="outline"
        colorScheme="green"
        _focus={{ outline: 'none' }}
        mt={2}
        border="1px"
        isLoading={isLoading}>
        <FaCut size="18px" color="#4bac61" />
        <Text fontWeight="medium" colorScheme="green" ml={2}>
          Crop
        </Text>
      </Button>
    </Flex>
  );
}

export default ImageCropper;
