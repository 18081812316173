import React from 'react';
import { Flex, Image } from '@chakra-ui/react';

import BgSignupImage from '../assets/bg-signup.jpg';
import './Styles/UnauthenticatedCard.css';

function UnauthenticatedCard(props) {
  return (
    <Flex direction="column" justifyContent="stretch" position="relative" width={{ base: '100%', lg: 'auto' }} mt={8}>
      {/* <Flex direction="row" justifyContent="center" alignItems="center" mb={2}>
                <Image
                    boxSize="35px"
                    src={Fork}
                    alt="Logo FeedInApp"
                    mr={2}
                />
                <Text fontSize="2xl" fontWeight="medium" color="#4bac61">
                    FeedInApp
                </Text>
            </Flex> */}
      <Flex
        direction={{ base: 'column', sm: 'row' }}
        height="555px"
        width={{ base: '100%', lg: '860px' }}
        borderRadius="xl"
        overflow="hidden"
        boxShadow="xl"
        border="1px solid"
        borderColor="separator">
        <Flex flex="1" className="bg-overlay" display={{ base: 'none', sm: 'flex' }}>
          <Image
            boxSize="100%"
            objectFit="cover"
            src={BgSignupImage}
            alt="Piatto bello condito"
            filter={props.overlayContent && 'brightness(40%)'}
          />
          {props.overlayContent && <Flex className="overlay-content">{props.overlayContent}</Flex>}
        </Flex>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            props.onSubmit(e);
          }}
          ref={props.formRef}>
          {props.children}
        </form>
      </Flex>
    </Flex>
  );
}

export default UnauthenticatedCard;
