import React, { useState } from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { Step, Steps, useSteps } from 'chakra-ui-steps';

import { BounceLoader } from 'react-spinners';

import ActivityNameStep from './ActivityNameStep';
import CreateCategoryStep from './CreateCategoryStep';
import SignupStep from './SignupStep';
import RecapStep from './RecapStep';
import { onSingIn } from './SignupStepModel';

const steps = [
  { label: 'Nome', description: "Dai un nome all'attività" },
  { label: 'Prima categoria', description: 'Crea una categoria ed un piatto' },
  { label: 'Registrati', description: 'Crea un account' },
  { label: 'Conferma', description: 'Sottoscrivi abbonamento' },
];

function NewSignup() {
  const [restaurantName, setRestaurantName] = useState(undefined);
  const [categoryName, setCategoryName] = useState(undefined);
  const [dishName, setDishName] = useState(undefined);
  const [dishPrice, setDishPrice] = useState(undefined);

  const [loading, setLoading] = useState(false);

  const { nextStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const onComplete = (user, callback) => {
    setLoading(true);
    onSingIn(restaurantName, categoryName, dishName, dishPrice, user)
      .then((res) => {
        setLoading(false);
        console.log(res);
        nextStep();
        if (!!callback) callback();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Flex flexDir="column" width={{ base: '95%', md: '75%' }} alignItems="center">
      {!loading ? (
        <Steps color="#4bac61" activeStep={activeStep}>
          <Step label={steps[0].label} description={steps[0].description}>
            <ActivityNameStep onNameChange={(name) => setRestaurantName(name)} onAdvance={nextStep} />
          </Step>
          <Step label={steps[1].label} description={steps[1].description}>
            <CreateCategoryStep
              onCategoryNameChange={(name) => setCategoryName(name)}
              onDishNameChange={(name) => setDishName(name)}
              onDishPriceChange={(price) => setDishPrice(price)}
              onAdvance={nextStep}
            />
          </Step>
          <Step label={steps[2].label} description={steps[2].description}>
            <SignupStep onAdvance={onComplete} />
          </Step>
          <Step label={steps[3].label} description={steps[3].description}>
            <RecapStep />
          </Step>
        </Steps>
      ) : (
        <>
          <BounceLoader
            size={60}
            color="#4BAC61"
            loading
            margin={2}
            style={{ position: 'absolute', left: '50%', top: '50%' }}
          />
          <Heading as="h4" size="lg" mt="4">
            Il menu è quasi pronto ...
          </Heading>
        </>
      )}
    </Flex>
  );
}

export { NewSignup };
