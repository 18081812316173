import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';

function DeleteDialog(props) {
  const cancelRef = useRef();

  return (
    <AlertDialog isOpen={props.isOpen} leastDestructiveRef={cancelRef} onClose={props.onClose} flex={0} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent as="div">
          <AlertDialogHeader fontSize="lg" fontWeight="bold" as="div">
            {props.title}
          </AlertDialogHeader>

          <AlertDialogBody>{props.message}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={props.onClose}>
              Annulla
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                props.onConfirm();
                props.onClose();
              }}
              ml={3}>
              Elimina
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

DeleteDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default DeleteDialog;
