import { GoogleProvider } from '../../../contexts/SocialMediasAuthProvider';
import { auth } from '../../../../utils/firebase';
import { createMenuRecord, createRestaurant, updateUserInfo } from '../../../../API';

export const signIn = (email, password) => auth.createUserWithEmailAndPassword(email, password);

export const signInSocial = (provider) => {
  if (!!provider) return auth.signInWithPopup(GoogleProvider);
  return auth.signInWithPopup(provider);
};

export const onSingIn = (restaurantName, categoryName, dishName, dishPrice, user) =>
  new Promise((resolve, reject) => {
    window.console.log(user);
    createRestaurant({
      restaurantName,
      uid: user.user.uid,
    })
      .then((res) => {
        console.log(res);
        createMenuRecord({
          categoryName,
          uid: user.user.uid,
          itemName: dishName,
          price: dishPrice,
          restaurantKey: res.data.data,
        })
          .then((response2) => {
            console.log(response2);
            updateUserInfo({
              firstName: !!user.additionalUserInfo.profile ? user.additionalUserInfo.profile.given_name : '',
              lastName: !!user.additionalUserInfo.profile ? user.additionalUserInfo.profile.family_name : '',
              picture: !!user.additionalUserInfo.profile ? user.additionalUserInfo.profile.picture : '',
              email: !!user.additionalUserInfo.profile ? user.additionalUserInfo.profile.email : '',
              uid: user.user.uid,
            })
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
