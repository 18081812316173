import React, { useState, createRef } from 'react';
import { Flex, Text, Heading, Input, Button } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

import PropTypes from 'prop-types';
import UnauthenticatedCard from '../../../UnauthenticatedCard';

function ActivityNameStep({ onNameChange, onAdvance }) {
  const formRef = createRef();
  const [name, setName] = useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
    onNameChange(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    onAdvance(e);
  };

  return (
    <UnauthenticatedCard onSubmit={(e) => handleFormSubmit(e)} formRef={formRef}>
      <Flex height="100%" width="100%" p={3} direction="column" justifyContent="center">
        <Heading as="h1" size="2xl" fontWeight="bold" color="blackAlpha.800" alignSelf="flex-start">
          Iniziamo!
        </Heading>

        <Text color="blackAlpha.800" size="normal" mt={10} fontWeight="medium">
          Inserisci il nome della tua attività
        </Text>

        <Input
          width="100%"
          variant="flushed"
          placeholder="Come si chiama la tua attività?"
          value={name}
          onChange={handleNameChange}
          isRequired
        />

        <Button
          variant="undecorated"
          color="#FFF"
          background="linear-gradient(90deg, rgba(75,172,97,1) 0%, rgba(6,94,43,1) 100%)"
          _hover={{ background: 'rgba(6,94,43,1)' }}
          _activeLink={{ background: 'rgba(6,94,43,1)' }}
          width="100%"
          type="submit"
          mt={10}
          isLoading={false}
          disabled={name.length <= 0}
          rightIcon={<ChevronRightIcon />}>
          Avanti
        </Button>
      </Flex>
    </UnauthenticatedCard>
  );
}

ActivityNameStep.protoTypes = {
  onNameChange: PropTypes.func.isRequired,
  onAdvance: PropTypes.func.isRequiredq,
};

export default ActivityNameStep;
