import { Redirect, Route } from 'react-router';
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { updateUserCredentials } from '../Redux/Actions/Actions';
import { useAuth } from './contexts/AuthContext';
import LoadingScreen from './LoadingScreen';

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUserCredentials(user)),
});

function PrivateRoute({ component: Component, currentUser, updateUser, ...rest }) {
  const { theUser } = useAuth();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if ((theUser !== undefined && currentUser && currentUser.uid) || theUser === null) setLoading(false);
  }, [theUser, currentUser]);

  if (isLoading) return <LoadingScreen />;
  if (theUser === null) return <Redirect to="/login" />;
  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
