/* eslint-disable no-plusplus */
/* eslint-disable new-cap */
/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { jsPDF } from 'jspdf';

const baseURL = 'https://feedinapp.com/web/';
const SIZE = '300x300';

export const savePdf = (restaurant, categories, dishes, printUnavailable, printAllCategories, selectedCategories) => {
  /* INPUT DELLA FUTURA FUNZIONE --------------------*/
  /* printUnavailable=true -> stampa anche piatti non disponibili */

  /* printAllCategories=true -> stampa tutte le categorie
   printAllCategories=false -> stampa solo le categorie che hanno la chiave
   in selectedCategories  */
  /* ------------------------------*/

  const doc = new jsPDF();
  doc.setFont('times', 'bold');
  doc.setFontSize('22');
  doc.text(restaurant.name, 105, 20, null, null, 'center');
  doc.setFont('times', 'normal');
  doc.setFontSize('14');
  doc.text(restaurant.description, 105, 30, null, null, 'center');
  let height = 50;
  const pageHeight = 300;
  let print = true;
  for (const key in dishes) {
    if (printAllCategories) {
      print = true;
    } else if (selectedCategories.find((cat) => cat === key) !== undefined) {
      print = true;
    } else {
      print = false;
    }

    if (print) {
      if (height + 20 >= pageHeight) {
        doc.addPage();
        height = 20;
      }
      height += 14;
      doc.setFont('times', 'bold');
      doc.setFontSize('16');
      doc.text(categories.find((cat) => cat.categoryKey === key).name, 105, height, null, null, 'center');
      doc.setFont('times', 'normal');
      doc.setFontSize('14');
      height += 10;
      if (height + 20 >= pageHeight) {
        doc.addPage();
        height = 20;
      }
      dishes[key].forEach((dish) => {
        if (printUnavailable || dish.available) {
          doc.text(`${dish.name}    ${dish.price ? `€${dish.price}` : ''}`, 105, height, null, null, 'center');
          if (dish.description) {
            doc.setFontSize('11');
            doc.setFont('times', 'italic');
            height += 6;
            if (height + 20 >= pageHeight) {
              doc.addPage();
              height = 20;
            }
            doc.text(dish.description, 105, height, null, null, 'center');
            doc.setFontSize('14');
            doc.setFont('times', 'normal');
          }
          height += 10;
          if (height + 40 >= pageHeight) {
            doc.addPage();
            height = 20;
          }
        }
      });
    }
  }
  // window.open(doc.output('bloburl'), '_blank');
  doc.save(`menu${restaurant.name}.pdf`);
};

const getImageSrc = (key) => {
  const content = baseURL + key;
  const URL = `https://chart.googleapis.com/chart?chs=${SIZE}&cht=qr&chl=${content}&choe=UTF-8`;
  return URL;
};

export const printQrCode = (restaurant) => {
  const doc = new jsPDF();

  let x = 0;
  let y = 10;
  let j = 0;
  let k = 0;
  let items = 0;
  const qrSize = 70;
  const A4pageWidth = 210;
  const vPadding = 10;
  const textPadding = 10;

  for (let i = 0; i < 12; ++i) {
    if (items >= 12) {
      doc.addPage();
      x = 0;
      y = 10;
      j = 0;
      k = 0;
      items = 0;
    }
    const imageData = new Image(300, 300);
    imageData.src = getImageSrc(restaurant.restaurantKey);
    doc.addImage(imageData, 'PNG', x, y, qrSize, qrSize);
    doc.setFontSize(16);
    doc.text(`${restaurant.name}`, x + textPadding, y + qrSize - 3);
    items += 1;
    if (x >= A4pageWidth - qrSize) {
      x = 0;
      k = 0;
      y = ++j * qrSize + vPadding;
    } else {
      x = ++k * qrSize;
    }
  }
  doc.save(`${restaurant.name}_qrcode.pdf`);
};
