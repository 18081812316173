/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { connect } from 'react-redux';

import { auth } from '../../utils/firebase';
import { updateUserCredentials } from '../../Redux/Actions/Actions';

const AuthContext = createContext({
  registrati: null,
  accedi: null,
  esci: null,
  reimpostaPassword: null,
  theUser: undefined,
  accediConSocial: null,
  isWebmaster: null,
  anonymousSignIn: null,
  tempUser: null,
  anonymousLogin: null,
});

export const useAuth = () => useContext(AuthContext);

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserCredentials: (userCredentials) => dispatch(updateUserCredentials(userCredentials)),
});

function AuthProvider(props) {
  const [theUser, setTheUser] = useState(undefined);
  const [loading, setLoading] = useState(true);
  // const [isWebmaster, setWebmaster] = useState(false);
  const [tempUser, setTempUser] = useState(null);

  /* Osserva: restituisce una promise!!! */
  const registrati = (email, password) => {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    return auth.currentUser.linkWithCredential(credential);
  };

  const anonymousSignIn = () =>
    firebase
      .auth()
      .signInAnonymously()
      .then((credentials) => {
        setTempUser(credentials);
        return credentials;
      })
      .catch(() => {
        setTempUser(null);
      });

  const accedi = (email, password) => auth.signInWithEmailAndPassword(email, password);

  const esci = () => auth.signOut();

  const reimpostaPassword = (email) => auth.sendPasswordResetEmail(email);

  const accediConSocial = (provider) => auth.currentUser.linkWithPopup(provider);

  const anonymousLogin = () => anonymousSignIn().then((credentials) => credentials);

  const value = {
    theUser,
    registrati,
    accedi,
    esci,
    reimpostaPassword,
    accediConSocial,
    // isWebmaster,
    anonymousSignIn,
    tempUser,
    anonymousLogin,
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && user.isAnonymous) {
        setTheUser(null);
        setTempUser(user);
        setLoading(false);
        return;
      }
      setTheUser(user);

      setLoading(false);

      if (user && !user.isAnonymous) props.updateUserCredentials(user);
    });

    return unsubscribe;
  }, []);

  return <AuthContext.Provider value={value}>{!loading && props.children}</AuthContext.Provider>;
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);
