/*
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_RESTAURANT_ID = 'SET_RESTAURANT_ID';
export const SET_RESTAURANT_NAME = 'SET_RESTAURANT_NAME';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_ITEMS_BY_CATEGORIES = 'SET_ITEMS_BY_CATEGORIES';
export const SET_RESTAURANT_INFO = 'SET_RESTAURANT_INFO';
export const SET_POSTS_FEED = 'SET_POSTS_FEED';
export const SET_RESTAURANT_NOT_FOUND = 'SET_RESTAURANT_NOT_FOUND'; */

/**
 * NEW APPLICATION'S ACTION TYPES
 */

export const DATA_FETCHED = 'DATA_FETCHED'; // To be called when restaurant's, categories' and dishes' datas are all available
export const EDIT_RESTAURANT = 'EDIT_RESTAURANT';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const RESTORE_CATEGORY = 'RESTORE_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const DELETE_DISH = 'DELETE_DISH';
export const RESTORE_DISH = 'RESTORE_DISH';
export const EDIT_DISH = 'EDIT_DISH';
export const ADD_DISH = 'ADD_DISH';
export const ADD_RESTAURANT = 'ADD_RESTAURANT';
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_DISHES = 'LOAD_DISHES';
export const LOAD_MENU = 'LOAD_MENU';
export const UPDATE_USER_CREDENTIALS = 'UPDATE_USER_CREDENTIALS';
export const REPOSITION_CATEGORIES = 'REPOSITION_CATEGORIES';
export const SAVE_DISH = 'SAVE_DISH';
export const FETCH_MENU = 'FETCH_MENU';
export const FETCH_POSTS = 'FETCH_POSTS';
export const ADD_POST = 'ADD_POST';
export const UPDATE_LOCAL_POST_IMAGES = 'UPDATE_LOCAL_POST_IMAGES';
export const DELETE_POST = 'DELETE_POST';
export const APPEND_SUBSCRIPTION_STATUS = 'APPEND_SUBSCRIPTION_STATUS';
