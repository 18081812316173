import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import firebase from '../../utils/firebase';

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = () => ({});

/**
 * @FIXME Quando viene modificata la posizione di un piatto in una categoria
 * il DragDropContext dovrebbe aggiornare sul database l'attributo pos di tutti i piatti
 * con posizione tra start+1 e destination (compresi). Così com'è adesso viene aggiornato
 * soltanto l'attributo pos del piatto spostato e non di tutti quelli accanto,
 * causando potenzialmente problemi.
 */
function UnitTesting({ currentUser }) {
  useEffect(() => {
    firebase
      .firestore()
      .collection(`customers/${currentUser.uid}/subscriptions`)
      .get()
      .then((docSnapshot) => docSnapshot.forEach((doc) => console.log(doc.data().status)));
  }, [currentUser]);

  return <>Bella zio</>;
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitTesting);
