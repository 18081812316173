import { addPost } from '../Redux/Actions/Actions';
import { database } from '../utils/firebase';

export const makeTextOnlyPost = (restaurantKey, content, label) => (dispatch) =>
  new Promise((resolve, reject) => {
    console.log("The restaurant's key is", restaurantKey);
    const postToAdd = {
      data: Date.now(),
      text: content,
      restaurantKey,
      visible: true,
      label,
    };

    database
      .ref(`post/${restaurantKey}/posts`)
      .push(postToAdd)
      .then((post) => {
        database
          .ref(`post/${restaurantKey}/posts`)
          .child(post.key)
          .update({ postKey: post.key })
          .then((res) => {
            dispatch(addPost({ ...postToAdd, postKey: post.key }));
            resolve({
              res,
              postKey: post.key,
            });
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
