import React from 'react';
import { Flex } from '@chakra-ui/react';

import ImageGallery from 'react-image-gallery';
import './Styles/image-gallery.css';

function Carousel(props) {
  const imagesOptions = [];

  props.images.forEach((image) => {
    imagesOptions.push({ original: image });
  });

  return (
    <Flex
      width={{ base: '100%', lg: '612px' }}
      height={{ base: '350px', lg: '459px' }}
      justifyContent="center"
      alignItems="center"
      background="gray.400">
      <ImageGallery
        items={imagesOptions}
        showBullets="true"
        showThumbnails="false"
        showPlayButton="false"
        useBrowserfullScreen="false"
        slideInterval={3000}
      />
    </Flex>
  );
}

export default Carousel;
