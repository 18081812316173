import React, { useState, useRef } from 'react';
import {
  Flex,
  Text,
  Image,
  Stack,
  HStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
  Input,
  Button,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { AiOutlineUnlock } from 'react-icons/ai';
import { BiShow } from 'react-icons/bi';
import { FcGoogle } from 'react-icons/fc';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { GoogleProvider } from './contexts/SocialMediasAuthProvider';
import { useAuth } from './contexts/AuthContext';
import { updateUserCredentials } from '../Redux/Actions/Actions';

import BgSignupImage from '../assets/bg-signup.jpg';
import Logo from '../assets/Fork-green.png';
import './Styles/Signup.css';

const mapDispatchToProps = (dispatch) => ({
  updateUserCredentials: (userCredentials) => dispatch(updateUserCredentials(userCredentials)),
});

function Login(props) {
  const [showPsw, setShowPsw] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClick = () => setShowPsw(!showPsw);

  const cronologia = useHistory();

  const handleSocialLogin = async (provider) => {
    try {
      setLoading(true);
      setError('');
      await accediConSocial(provider);
      cronologia.push('/');
    } catch (error) {
      setError(t('form_social_login_failed'));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const currentEmail = emailRef.current.value;
    const idxOfAt = emailRef.current.value ? emailRef.current.value.indexOf('@') : -1;
    const idxOfDot = emailRef.current.value ? emailRef.current.value.indexOf('.') : -1;

    const validateEmail =
      currentEmail &&
      idxOfAt !== -1 &&
      idxOfAt !== 0 &&
      idxOfAt !== currentEmail.length - 1 &&
      idxOfDot !== -1 &&
      idxOfDot !== 0 &&
      idxOfDot !== currentEmail.length - 1;

    if (emailRef.current.value.length <= 0) setError(t('form_enter_email_address'));
    else if (pswRef.current.value.length <= 0) setError(t('form_enter_both_psw'));
    /* All fields are non-empty */ else if (!validateEmail) setError(t('form_invalid_email'));
    else {
      setError('');
      setLoading(true);
      accedi(currentEmail, pswRef.current.value)
        .then((credentials) => {
          props.updateUserCredentials(credentials.user);
          Cookies.set('auth_status', '1', { expires: 1 });
          cronologia.push('/');
        })
        .catch(() => {
          setError(t('form_login_fail'));
          setLoading(false);
        });
    }
  };

  const { t } = useTranslation();
  const { accedi, accediConSocial } = useAuth();

  const emailRef = useRef();
  const pswRef = useRef();

  return (
    <Flex
      direction={{ base: 'column', sm: 'row' }}
      height="555px"
      width="860px"
      borderRadius="xl"
      overflow="hidden"
      boxShadow="xl"
      border="1px solid"
      borderColor="separator">
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}>
        <Flex direction="column" alignItems="center">
          <Text fontWeight="semibold" color="catdescriptiongrey">
            {t('form_welcome')}
          </Text>
          <Flex height="50px" alignItems="center" justifyContent="center" width="75%">
            <Image boxSize="90%" src={Logo} alt="Logo FeedInApp" maxHeight="100%" mr={3} />
            <Text fontSize="3xl" fontWeight="bolder" color="primary">
              FeedInApp
            </Text>
          </Flex>
          <Text pl={10} pr={10} mt={3} fontSize="small" color="descriptionGrey" textAlign="center" />
          {error && (
            <Alert status="error" borderRadius="xl" width="80%" mt={4}>
              <AlertIcon />
              {error}
            </Alert>
          )}
          <Stack spacing={4} width="100%" mt={6}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <HiOutlineMailOpen color="descriptionGrey" />
              </InputLeftElement>
              <Input type="text" placeholder="Email" borderRadius="2xl" ref={emailRef} isRequired />
            </InputGroup>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <AiOutlineUnlock color="descriptionGrey" />
              </InputLeftElement>
              <Input
                type={showPsw ? 'text' : 'password'}
                placeholder="Password"
                borderRadius="2xl"
                ref={pswRef}
                isRequired
              />
              <InputRightElement width="4.5rem">
                <IconButton
                  h="1.75rem"
                  size="sm"
                  background="#FFF"
                  isRound
                  onClick={handleClick}
                  icon={<BiShow color="descriptionGrey" />}>
                  {showPsw ? 'Hide' : 'Show'}
                </IconButton>
              </InputRightElement>
            </InputGroup>
            <Button
              variant="undecorated"
              color="#FFF"
              background="linear-gradient(90deg, rgba(75,172,97,1) 0%, rgba(6,94,43,1) 100%)"
              _hover={{ background: 'rgba(6,94,43,1)' }}
              _activeLink={{ background: 'rgba(6,94,43,1)' }}
              type="submit"
              isLoading={loading}>
              {t('form_login')}
            </Button>
          </Stack>
          <Flex width="80%" direction="row-reverse" mt={1} mr={2} alignSelf="flex-end">
            <Link to="/forgot-password">
              <Text fontSize="small" color="#0366D6" _hover={{ textDecoration: 'underline' }}>
                {t('form_forgot_psw')}
              </Text>
            </Link>
          </Flex>
          <Flex mt={2}>
            <Text fontSize="small" color="descriptionGrey" mr={1}>
              {t('form_no_account')}
            </Text>
            <Link to="/signup">
              <Text fontSize="small" fontWeight="semibold" color="highlight" _hover={{ textDecoration: 'underline' }}>
                {t('form_subscribe')}
              </Text>
            </Link>
          </Flex>
          <Flex width="65%" mt={4} mb={4}>
            <Text className="barred-form-text" fontSize="small" color="formSep" width="100%">
              {t('form_or')}
            </Text>
          </Flex>
          <Text fontSize="small" fontWeight="bold" color="#B8B8B8">
            {t('form_with_socials')}
          </Text>
          <HStack spacing={4} mt={2}>
            <IconButton
              aria-label="Accedi con Google"
              boxShadow="md"
              background="white"
              isRound
              icon={<FcGoogle />}
              onClick={() => handleSocialLogin(GoogleProvider)}
            />
          </HStack>
        </Flex>
      </form>

      <Flex flex="1" className="bg-overlay" display={{ base: 'none', sm: 'flex' }}>
        <Image boxSize="100%" objectFit="cover" src={BgSignupImage} alt="Piatto bello condito" className="blurry" />
      </Flex>
    </Flex>
  );
}

export default connect(null, mapDispatchToProps)(Login);
