import React, { useEffect, useState } from 'react';
import { Flex, Text, Heading, Input, Button } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import InputNumber from 'rc-input-number';

import { useHistory } from 'react-router';
import UnauthenticatedCard from '../../UnauthenticatedCard';
import { useMultistepSignup } from '../../contexts/MultistepSignupContext';
import { useAuth } from '../../contexts/AuthContext';
import { createMenu } from '../../../utils/firebase';

import 'rc-input-number/assets/index.css';

function FirstDishes() {
  const { category, setCategory, dish, setDish, restaurant, resKey } = useMultistepSignup();
  const { tempUser } = useAuth();
  const cronologia = useHistory();

  const [categoryName, setCategoryName] = useState(category || '');
  const [dishName, setDishName] = useState((dish && dish.name) || '');
  const [dishPrice, setDishPrice] = useState((dish && dish.price) || 0);

  const advance = () => {
    setCategory(categoryName);
    setDish({
      name: dishName,
      price: dishPrice,
    });
    const data = {
      categoryName,
      uid: tempUser.user.uid,
      itemName: dishName,
      price: dishPrice,
      restaurantKey: resKey,
    };
    console.log(data);
    createMenu(data).then((res) => {
      console.log(res.data);
    });
  };

  useEffect(() => {
    if (resKey) console.log('Restaurant key: ', resKey);
  }, [resKey]);

  const onCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const onDishNameChange = (e) => {
    setDishName(e.target.value);
  };

  const onDishPriceChange = (val) => {
    setDishPrice(val);
  };

  if (!restaurant) cronologia.push('./signup-1');

  return (
    <UnauthenticatedCard
      onSubmit={() => {
        cronologia.push('/signup-3');
      }}>
      <Flex width="100%" height="100%" p={3} direction="column" justifyContent="center">
        <Heading as="h1" size="lg" fontWeight="bold" color="blackAlpha.800" alignSelf="flex-start">
          Inizia a creare il tuo menù!
        </Heading>

        <Text color="blackAlpha.800" size="normal" mt={10} fontWeight="medium">
          Prova ad inserire una categoria
        </Text>

        <Input
          width="100%"
          variant="flushed"
          placeholder="es Primi piatti, Antipasti"
          value={categoryName}
          onChange={onCategoryNameChange}
          isRequired
        />

        <Text color="blackAlpha.800" size="normal" mt={6} fontWeight="medium">
          Inserisci un piatto
        </Text>
        <Flex direction="row" alignItems="flex-end">
          <Input
            width="100%"
            variant="flushed"
            placeholder="es Tagliatelle, Penne"
            flex="3"
            value={dishName}
            onChange={onDishNameChange}
            isRequired
          />
          <Flex flex="1" ml={3}>
            <InputNumber step="0.01" defaultValue="0" min="0" value={dishPrice} onChange={onDishPriceChange} required />
            <Text ml={2}>{'\u20AC'}</Text>
          </Flex>
        </Flex>

        <Button
          variant="undecorated"
          color="#FFF"
          background="linear-gradient(90deg, rgba(75,172,97,1) 0%, rgba(6,94,43,1) 100%)"
          _hover={{ background: 'rgba(6,94,43,1)' }}
          _activeLink={{ background: 'rgba(6,94,43,1)' }}
          width="100%"
          type="submit"
          mt={10}
          isLoading={false}
          rightIcon={<ChevronRightIcon />}
          onClick={advance}>
          Avanti
        </Button>

        <Text color="blackAlpha.700" size="sm" mt={10}>
          Tutte le informazioni inserite adesso potranno essere successivamente modificate dalla pagina di gestione.
        </Text>
      </Flex>
    </UnauthenticatedCard>
  );
}

export default FirstDishes;
