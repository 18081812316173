import firebase, { database } from '../utils/firebase';
import { uploadImage } from './uploadImage';

const uploadImageTask = (icon, restaurantKey) =>
  uploadImage(`images/${restaurantKey}/icon/${restaurantKey}`, icon).then((imageURL) =>
    database.ref(`restaurants/${restaurantKey}`).update({ icon: imageURL }),
  );

export const saveEdits = (address, name, email, phone, facebook, instagram, website, icon) => (_, state) =>
  new Promise((reject, resolve) => {
    const updateTasks = [];
    console.log('The passed icon: ', icon);

    if (icon) {
      if (!!state().restaurant.icon) {
        updateTasks.push(
          firebase
            .storage()
            .refFromURL(state().restaurant.icon)
            .delete()
            .then(() => uploadImageTask(icon, state().restaurant.restaurantKey)),
        );
      } else {
        updateTasks.push(uploadImageTask(icon, state().restaurant.restaurantKey));
      }
    }

    if (name !== state().restaurant.name)
      updateTasks.push(database.ref(`restaurants/${state().restaurant.restaurantKey}`).update({ name }));

    if (email !== state().restaurant.email)
      updateTasks.push(database.ref(`restaurants/${state().restaurant.restaurantKey}`).update({ email }));

    if (phone !== state().restaurant.phone)
      updateTasks.push(database.ref(`restaurants/${state().restaurant.restaurantKey}`).update({ phone }));

    if (facebook !== state().restaurant.facebook)
      updateTasks.push(database.ref(`restaurants/${state().restaurant.restaurantKey}`).update({ facebook }));

    if (instagram !== state().restaurant.instagram)
      updateTasks.push(database.ref(`restaurants/${state().restaurant.restaurantKey}`).update({ instagram }));

    if (website !== state().restaurant.website)
      updateTasks.push(database.ref(`restaurants/${state().restaurant.restaurantKey}`).update({ website }));

    if (!!address)
      updateTasks.push(
        database.ref(`restaurants/${state().restaurant.restaurantKey}`).child('address').update(address),
      );

    Promise.all(updateTasks)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
