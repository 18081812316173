import React from 'react';
import ReactDOM from 'react-dom';

import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import TimeAgo from 'javascript-time-ago';
import it from 'javascript-time-ago/locale/it.json';
import { store } from './Redux/Store/store';
import { FeedInAppTheme } from './Components/Theme/theme';
import App from './App';

TimeAgo.addDefaultLocale(it);

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={FeedInAppTheme} resetCSS>
      <Provider store={store}>
        <App />
      </Provider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
