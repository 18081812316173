import React from 'react';
import { Flex } from '@chakra-ui/react';
import { connect } from 'react-redux';
import RestaurantOverlay from './Restaurant/RestaurantOverlay';
import './Restaurant/Styles/Restaurant.css';
import Aside from './Restaurant/Aside';

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
});

function Restaurant(props) {
  return (
    <Flex
      direction={{ base: 'column-reverse', lg: 'row' }}
      className="whatever"
      justifyContent="center"
      alignItems={{ base: 'center', lg: 'flex-start' }}>
      <RestaurantOverlay restaurantName="Osteria La Torre" width={{ base: '100%', md: '614px' }}>
        {props.children}
      </RestaurantOverlay>
      <Aside display={{ base: 'none', lg: 'flex' }} restaurantId={props.restaurant && props.restaurant.restaurantKey} />
    </Flex>
  );
}

export default connect(mapStateToProps)(Restaurant);
