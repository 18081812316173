import React, { useState, useEffect } from 'react';
import { Flex, Text, Input, Tooltip, Box, IconButton, HStack } from '@chakra-ui/react';
import { RiEdit2Line } from 'react-icons/ri';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';

import './Styles/Editor.css';

function Editor(props) {
  const [innerValue, setInnerValue] = useState(props.value || '');
  const [editMode, setEditMode] = useState(false);

  const confirmEdit = () => {
    setEditMode(false);
    props.onChange(innerValue);
  };

  const discardEdit = () => {
    setEditMode(false);
    setInnerValue(props.value || '');
  };

  useEffect(() => {
    if (props.value) setInnerValue(props.value);
    else setInnerValue('');
  }, [props.value]);

  return (
    <Flex as="article" width={props.width} direction="column" alignItems="flex-start" mb={props.mb}>
      <Text
        lineHeight={props.spacingMode && '250%'}
        fontSize={{ base: 'sm', md: 'initial' }}
        textTransform={props.toUpper && 'uppercase'}
        fontWeight="medium"
        color={props.color}
        letterSpacing="0.1rem">
        {props.label}
      </Text>
      <HStack width="100%" spacing={0} className="inputGroup" as="fieldset" position="relative">
        {!editMode ? (
          <>
            <Box
              border="1px solid"
              borderColor="gray.200"
              padding={{ base: '4px 15px', md: '8px 15px' }}
              flex="1"
              className="inputView"
              color={innerValue.length <= 0 && '#455768'}
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              width="0"
              fontSize={{ base: 'sm', md: 'initial' }}>
              {innerValue || props.placeholder}
            </Box>
            <Tooltip label="Modifica" placement="top" hasArrow>
              <IconButton
                icon={<RiEdit2Line />}
                variant="ghost"
                height="100%"
                padding={{ base: '6.5px 10px', md: '12px 15px' }}
                borderRadius="none"
                border="1px solid"
                borderColor="gray.200"
                className="penBox"
                onClick={() => {
                  setEditMode(true);
                }}
                aria-label={`Edit ${props.label}`}
              />
            </Tooltip>
          </>
        ) : (
          <>
            <Input
              variant="outline"
              fontSize={{ base: 'sm', md: 'initial' }}
              _focus={{
                borderColor: '#1890ff',
                boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
              }}
              padding="14px 15px"
              height="100%"
              borderRadius="none"
              flex="1"
              border="1px solid rgba(127,127,127, 0.5)"
              value={innerValue}
              onChange={(e) => setInnerValue(e.target.value)}
              className="inputBox"
              placeholder={props.placeholder || 'Type something'}
              autoFocus
            />
            <Flex
              position="absolute"
              bottom="0"
              right="0"
              transform="translateY(100%)"
              backgroundColor="#fff"
              zIndex="3">
              <IconButton
                icon={<CheckIcon />}
                variant="ghost"
                height="100%"
                padding="16px 15px"
                borderRadius="none"
                border="1px solid"
                borderColor="gray.500"
                onClick={() => {
                  confirmEdit();
                }}
                aria-label="confirm edit"
                marginRight="1px"
              />
              <IconButton
                icon={<CloseIcon />}
                variant="ghost"
                height="100%"
                padding="16px 15px"
                borderRadius="none"
                border="1px solid"
                borderColor="gray.500"
                onClick={() => {
                  discardEdit();
                }}
                aria-label="discard edit"
              />
            </Flex>
          </>
        )}
      </HStack>
    </Flex>
  );
}

Editor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  // as: PropTypes.string,
  // mt: PropTypes.number,
  mb: PropTypes.number,
  // fontSize: PropTypes.string,
  // fontWeight: PropTypes.string,
  toUpper: PropTypes.bool,
  spacingMode: PropTypes.bool,
};

Editor.defaultProps = {
  value: null,
  onChange: () => {},
  label: null,
  color: 'blackAlpha.700',
  width: '100%',
  placeholder: 'Type something!',
  // as: 'h1',
  // fontSize: 'lg',
  // mt: 0,
  mb: 0,
  // fontWeight: 'medium',
  toUpper: true,
  spacingMode: true,
};

export default Editor;
