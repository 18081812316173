import React from 'react';

import { Flex, Heading } from '@chakra-ui/react';
import { FaUserSecret, FaPen, FaCoins, FaShieldAlt } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import SidebarItem from './SidebarItem';

function DashboardOverlay(props) {
  const location = useLocation();

  return (
    <Flex width="100%" direction="column" bg="#FAFAFA" alignItems="center">
      {/* <FloatingLabelInput label="Change username" width="100%"/> */}

      {/* Container */}
      <Flex
        width={{ base: '100%', lg: '1000px' }}
        direction="column"
        border="1px solid"
        borderColor="blackAlpha.200"
        bg="white"
        mb={6}>
        {/* s Top bar */}
        <Flex width="100%" p={4} boxShadow="md">
          <Heading as="h4" size="md" color="blackAlpha.700">
            My Profile
          </Heading>
        </Flex>

        {/** Contains the sidebar and the actual page */}
        <Flex width="100%">
          {/* The sidebar */}
          <Flex
            width={{ base: '50px', lg: '260px' }}
            direction="column"
            position="sticky"
            top="51px"
            flexShrink="0"
            pt={4}
            alignSelf="flex-start">
            <SidebarItem
              icon={FaUserSecret}
              name="Profilo"
              link="dashboard/"
              active={location.pathname === '/dashboard/'}
            />
            <SidebarItem
              icon={FaPen}
              name="Modifica profilo"
              link="dashboard/edit"
              active={location.pathname === '/dashboard/edit'}
            />
            <SidebarItem
              icon={FaCoins}
              name="Piano"
              link="dashboard/plan"
              active={location.pathname === '/dashboard/plan'}
            />
            <SidebarItem
              icon={FaShieldAlt}
              name="Privacy"
              link="dashboard/privacy"
              active={location.pathname === '/dashboard/privacy'}
            />
          </Flex>
          <Flex width="100%" borderLeft="1px solid" borderLeftColor="blackAlpha.200">
            {props.children}
          </Flex>
        </Flex>
      </Flex>
      {/** isWebmaster && <Badge color="#800080" bg="#ffb3ff" height="auto">Webmaster</Badge>/* */}
    </Flex>
  );
}

export default DashboardOverlay;
