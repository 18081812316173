import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Stack,
  VStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
  Input,
  Button,
  Icon,
} from '@chakra-ui/react';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { AiOutlineUnlock } from 'react-icons/ai';
import { BiShow } from 'react-icons/bi';
import { FcGoogle } from 'react-icons/fc';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import { GoogleProvider } from '../../../contexts/SocialMediasAuthProvider';
import UnauthenticatedCard from '../../../UnauthenticatedCard';
import * as Model from './SignupStepModel';

import '../../../Styles/Signup.css';

function SignupStep({ onEmailChange, onPasswordChange, onAdvance }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pswConfirm, setPswConfirm] = useState('');
  const [showPsw, setShowPsw] = useState(false);
  const [showConfirmPsw, setShowConfirmPsw] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validator, setValidator] = useState(undefined);

  const toggleShowPsw = () => setShowPsw(!showPsw);
  const handleClickConfirmPsw = () => setShowConfirmPsw(!showConfirmPsw);

  useEffect(() => {
    if (!validator) setValidator(new SimpleReactValidator());
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!!onEmailChange) onEmailChange(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (!!onPasswordChange) onPasswordChange(e.target.value);
  };

  const handleSocialLogin = (provider, e) => {
    e.preventDefault();
    setLoading(true);
    Model.signInSocial(provider)
      .then((res) => {
        setLoading(false);
        onAdvance(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleSignup = (e) => {
    e.preventDefault();
    setLoading(true);
    Model.signIn(email, password)
      .then((res) => {
        onAdvance(res, () => setLoading(false));
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const { t } = useTranslation();

  return (
    <UnauthenticatedCard onSubmit={handleSignup}>
      <Flex direction="column" alignItems="center" width="80%">
        <Text fontSize="lg" fontWeight="semibold" color="blackAlpha.800" width="100%">
          Registrati per continuare
        </Text>

        <VStack width="100%" mt={3}>
          <Button
            width="100%"
            background="white"
            borderRadius="5px"
            border="1px solid"
            borderColor="gray.400"
            p={3}
            leftIcon={<Icon color="white" as={FcGoogle} pr={3} h={8} w={8} />}
            onClick={(e) => handleSocialLogin(GoogleProvider, e)}>
            Registrati con Google
          </Button>
        </VStack>

        <Text className="barred-form-text" fontSize="small" width="100%" mt={4} color="gray.500">
          {t('form_or')}
        </Text>

        <Stack spacing={4} width="100%" mt={4}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <HiOutlineMailOpen color="descriptionGrey" />{' '}
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Email"
              variant="flushed"
              value={email}
              onChange={(e) => handleEmailChange(e)}
              isRequired
            />
            {!!validator && validator.message('email', email, 'required|email', { className: 'text-danger' })}
          </InputGroup>

          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <AiOutlineUnlock color="descriptionGrey" />
            </InputLeftElement>
            <Input
              type={showPsw ? 'text' : 'password'}
              variant="flushed"
              placeholder="Password"
              value={password}
              onChange={(e) => handlePasswordChange(e)}
              isRequired
            />
            <InputRightElement width="4.5rem">
              <IconButton
                h="1.75rem"
                size="sm"
                background="#FFF"
                isRound
                onClick={toggleShowPsw}
                icon={<BiShow color="descriptionGrey" />}>
                {showPsw ? 'Hide' : 'Show'}
              </IconButton>
            </InputRightElement>
          </InputGroup>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <AiOutlineUnlock color="descriptionGrey" />
            </InputLeftElement>
            <Input
              type={showConfirmPsw ? 'text' : 'password'}
              variant="flushed"
              placeholder={t('form_confirm_psw')}
              value={pswConfirm}
              onChange={(e) => setPswConfirm(e.target.value)}
              isRequired
            />
            <InputRightElement width="4.5rem">
              <IconButton
                h="1.75rem"
                size="sm"
                background="#FFF"
                isRound
                onClick={handleClickConfirmPsw}
                icon={<BiShow color="descriptionGrey" />}>
                {showConfirmPsw ? 'Hide' : 'Show'}
              </IconButton>
            </InputRightElement>
          </InputGroup>
        </Stack>
        <Button
          variant="undecorated"
          color="#FFF"
          background="linear-gradient(90deg, rgba(75,172,97,1) 0%, rgba(6,94,43,1) 100%)"
          _hover={{ background: 'rgba(6,94,43,1)' }}
          _activeLink={{ background: 'rgba(6,94,43,1)' }}
          type="submit"
          isLoading={loading}
          disabled={email.length <= 0 || password.length <= 0 || pswConfirm.length <= 0 || password !== pswConfirm}
          width="100%"
          mt={5}>
          {t('form_signup')}
        </Button>

        <Flex mt={2}>
          <Text fontSize="small" color="descriptionGrey" mr={1}>
            {t('form_already_account')}
          </Text>
          <Link to="/login">
            <Text fontSize="small" fontWeight="semibold" color="highlight" _hover={{ textDecoration: 'underline' }}>
              {t('form_signin')}
            </Text>
          </Link>
        </Flex>
      </Flex>
    </UnauthenticatedCard>
  );
}

SignupStep.propTypes = {
  onAdvance: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func,
  onPasswordChange: PropTypes.func,
};

export default SignupStep;
