import React from 'react';
import {
  Flex,
  Heading,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem,
  MenuDivider,
  useBreakpointValue,
  Image,
} from '@chakra-ui/react';
import { FaEdit, FaSignOutAlt, FaUtensils, FaHome, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { updateUserCredentials } from '../../Redux/Actions/Actions';

import { useAuth } from '../contexts/AuthContext';
import MenuIcon from './MenuIcon';

import FeedinAppLogo from '../../assets/Fork-green.png';
import AvatarTemplate from '../../assets/avatar_placeholder.png';
import '../Styles/Topbar.css';

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user) => dispatch(updateUserCredentials(user)),
});

function Topbar({ currentUser, updateUser, ...props }) {
  const { esci } = useAuth();
  const cronologia = useHistory();
  // const [error, setError] = useState('');
  const location = useLocation();

  const brand = useBreakpointValue({
    base: (
      <Link to="/" style={{ height: '100%', display: 'flex', alignItems: 'center', marginRight: '1.125rem' }}>
        <Image src={FeedinAppLogo} objectFit="cover" width="30px" height="30px" />
      </Link>
    ),
    md: (
      <Link
        to="/"
        style={{ color: '#4BAC61', height: '100%', display: 'flex', alignItems: 'center', marginRight: '1.125rem' }}>
        <Heading as="h4" size="sm" className="blackhover">
          FeedInApp
        </Heading>
      </Link>
    ),
  });

  const logOut = () => {
    // setError('');
    esci()
      .then(() => {
        updateUser({});
        Cookies.remove('auth_status');
        cronologia.push('/login');
      })
      .catch((error) => {
        console.log(error);
        // setError(t('dash_logout_failed'));
      });
  };

  return (
    <Flex
      height="50px"
      border="1px solid"
      borderColor="blackAlpha.200"
      width="100%"
      alignItems="center"
      boxShadow="0 1px 2px rgba(0,0,0,.1)"
      className="frosted-glass"
      justifyContent="space-between"
      position="sticky"
      top="0"
      zIndex="2">
      <Flex
        width={{ base: '100%', md: '750px', lg: '970px', xl: '1170px' }}
        height="100%"
        mr="auto"
        ml="auto"
        pl={3}
        pr={3}
        alignItems="center">
        {brand}
        <MenuIcon active={location.pathname === '/post'} icon={<FaHome size="16px" />} label="Homepage" link="post" />
        <MenuIcon
          active={location.pathname.indexOf('/dashboard') === 0}
          icon={<FaUser size="16px" />}
          label="User"
          link="dashboard/"
        />
        <MenuIcon
          active={location.pathname.indexOf('/') === 0 && location.pathname.length <= 1}
          icon={<FaUtensils size="16px" />}
          label="Menu"
          link=""
        />
        <Menu>
          <MenuButton as={Button} variant="ghost" ml="auto">
            <Avatar src={props.avatar || AvatarTemplate} size="sm" boxShadow="md" />
          </MenuButton>
          <MenuList>
            <MenuGroup title={`Welcome${currentUser && currentUser.displayName ? `, ${currentUser.displayName}` : ''}`}>
              <MenuItem
                fontWeight="sm"
                fontSize="sm"
                icon={<FaEdit size="16px" />}
                _hover={{ bg: '#4bac61', color: 'white' }}
                onClick={() => {
                  cronologia.push('/dashboard/');
                }}>
                Edit Profile
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuItem
              onClick={() => {
                logOut();
              }}
              fontWeight="sm"
              fontSize="sm"
              color="#cb2431"
              icon={<FaSignOutAlt size="16px" />}
              _hover={{ bg: '#cb2431', color: 'white' }}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
