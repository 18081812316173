import firebase from 'firebase/app';
import 'firebase/database';

export const getUserInfo = (userKey) => {
  if (firebase.app.length === 0) return null;
  return firebase
    .database()
    .ref('users')
    .child(userKey)
    .once('value', () => {});
};

export const getRestaurantInfo = (restaurantKey) => {
  if (firebase.app.length === 0) return null;
  return firebase
    .database()
    .ref('restaurants')
    .child(restaurantKey)
    .once('value', () => {});
};

export const getCategories = (restaurantKey) => {
  if (firebase.app.length === 0) return null;
  return firebase
    .database()
    .ref('categories')
    .child(`${restaurantKey}/categories`)
    .once('value', () => {});
};

export const getMenu = (restaurantKey) => {
  if (firebase.app.length === 0) return null;
  return firebase
    .database()
    .ref('menu')
    .child(`${restaurantKey}/items`)
    .once('value', () => {});
};

export const getPosts = (restaurantKey) => {
  if (firebase.app.length === 0) return null;
  return firebase
    .database()
    .ref('posts')
    .child(restaurantKey)
    .once('value', () => {});
};
