import React, { useState, useRef } from 'react';
import {
  Flex,
  Text,
  Stack,
  VStack,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
  Input,
  Button,
  Alert,
  AlertIcon,
  Icon,
} from '@chakra-ui/react';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { AiOutlineUnlock } from 'react-icons/ai';
import { BiShow } from 'react-icons/bi';
import { FcGoogle } from 'react-icons/fc';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { GoogleProvider } from './contexts/SocialMediasAuthProvider';
import UnauthenticatedCard from './UnauthenticatedCard';
import { cookies } from '../utils/cookies';

import './Styles/Signup.css';
import { updateUserInfo } from '../utils/firebase';

function Signup() {
  const [showPsw, setShowPsw] = useState(false);
  const [showConfirmPsw, setShowConfirmPsw] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClick = () => setShowPsw(!showPsw);
  const handleClickConfirmPsw = () => setShowConfirmPsw(!showConfirmPsw);

  const cronologia = useHistory();

  const handleSocialLogin = async (provider) => {
    try {
      setLoading(true);
      setError('');
      accediConSocial(provider).then((user) => {
        console.log(user);
        const data = {
          firstName: user.additionalUserInfo.profile.given_name,
          lastName: user.additionalUserInfo.profile.family_name,
          picture: user.additionalUserInfo.profile.picture,
          email: user.additionalUserInfo.profile.email,
          uid: user.user.uid,
        };
        updateUserInfo(data).then((res) => {
          console.log(res);
          console.log(data);
          cronologia.push('/signup-4/');
        });
      });
    } catch (error) {
      setError(t('form_social_login_failed'));
    } finally {
      setLoading(false);
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();

    const currentEmail = emailRef.current.value;
    const idxOfAt = emailRef.current.value ? emailRef.current.value.indexOf('@') : -1;
    const idxOfDot = emailRef.current.value ? emailRef.current.value.indexOf('.') : -1;

    const validateEmail =
      currentEmail &&
      idxOfAt !== -1 &&
      idxOfAt !== 0 &&
      idxOfAt !== currentEmail.length - 1 &&
      idxOfDot !== -1 &&
      idxOfDot !== 0 &&
      idxOfDot !== currentEmail.length - 1;

    if (emailRef.current.value.length <= 0) setError(t('form_enter_email_address'));
    else if (pswRef.current.value.length <= 0 || pswConfirmRef.current.value.length <= 0)
      setError(t('form_enter_both_psw'));
    /* All fields are non-empty */ else if (!validateEmail) setError(t('form_invalid_email'));
    else if (pswRef.current.value !== pswConfirmRef.current.value) setError(t('form_psw_mismatch'));
    else {
      setLoading(true);
      setError('');
      await registrati(currentEmail, pswRef.current.value)
        .then((user) => {
          cookies.set('isNewUser', user.additionalUserInfo.isNewUser, {
            path: '/',
            expires: new Date(Date.now() + 2592000),
          });
          cronologia.push('/signup-4');
        })
        .catch(() => {
          setError(t('form_signup_fail'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const { t } = useTranslation();
  const { registrati, accediConSocial } = useAuth();

  const emailRef = useRef();
  const pswRef = useRef();
  const pswConfirmRef = useRef();

  return (
    <UnauthenticatedCard onSubmit={() => handleSubmit}>
      <Flex direction="column" alignItems="center" width="80%">
        <Text fontSize="lg" fontWeight="semibold" color="blackAlpha.800" width="100%">
          Registrati per continuare
        </Text>

        <VStack width="100%" mt={3}>
          <Button
            width="100%"
            background="white"
            borderRadius="5px"
            border="1px solid"
            borderColor="gray.400"
            p={3}
            leftIcon={<Icon color="white" as={FcGoogle} pr={3} h={8} w={8} />}
            onClick={() => handleSocialLogin(GoogleProvider)}>
            Registrati con Google
          </Button>
          {/*  <Button width="100%" color="white" borderRadius="5px" border="1px solid" borderColor="gray.400" p={3} leftIcon={<Icon as={FaFacebookF} color="white" pr={3} h={6} w={6} />}
                        onClick={() => handleSocialLogin(FacebookProvider)} colorScheme="facebook">
                        Registrati con Facebook
                    </Button> */}
        </VStack>

        <Text className="barred-form-text" fontSize="small" width="100%" mt={4} color="gray.500">
          {t('form_or')}
        </Text>

        {error && (
          <Alert status="error" borderRadius="xl" width="80%" mt={4}>
            <AlertIcon />
            {error}
          </Alert>
        )}
        <Stack spacing={4} width="100%" mt={4}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <HiOutlineMailOpen color="descriptionGrey" />
            </InputLeftElement>
            <Input type="text" placeholder="Email" variant="flushed" ref={emailRef} isRequired />
          </InputGroup>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <AiOutlineUnlock color="descriptionGrey" />
            </InputLeftElement>
            <Input
              type={showPsw ? 'text' : 'password'}
              variant="flushed"
              placeholder="Password"
              ref={pswRef}
              isRequired
            />
            <InputRightElement width="4.5rem">
              <IconButton
                h="1.75rem"
                size="sm"
                background="#FFF"
                isRound
                onClick={handleClick}
                icon={<BiShow color="descriptionGrey" />}>
                {showPsw ? 'Hide' : 'Show'}
              </IconButton>
            </InputRightElement>
          </InputGroup>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <AiOutlineUnlock color="descriptionGrey" />
            </InputLeftElement>
            <Input
              type={showConfirmPsw ? 'text' : 'password'}
              variant="flushed"
              placeholder={t('form_confirm_psw')}
              ref={pswConfirmRef}
              isRequired
            />
            <InputRightElement width="4.5rem">
              <IconButton
                h="1.75rem"
                size="sm"
                background="#FFF"
                isRound
                onClick={handleClickConfirmPsw}
                icon={<BiShow color="descriptionGrey" />}>
                {showConfirmPsw ? 'Hide' : 'Show'}
              </IconButton>
            </InputRightElement>
          </InputGroup>
        </Stack>
        <Button
          variant="undecorated"
          color="#FFF"
          background="linear-gradient(90deg, rgba(75,172,97,1) 0%, rgba(6,94,43,1) 100%)"
          _hover={{ background: 'rgba(6,94,43,1)' }}
          _activeLink={{ background: 'rgba(6,94,43,1)' }}
          type="submit"
          isLoading={loading}
          width="100%"
          mt={5}>
          {t('form_signup')}
        </Button>
        <Flex mt={2}>
          <Text fontSize="small" color="descriptionGrey" mr={1}>
            {t('form_already_account')}
          </Text>
          <Link to="/login">
            <Text fontSize="small" fontWeight="semibold" color="highlight" _hover={{ textDecoration: 'underline' }}>
              {t('form_signin')}
            </Text>
          </Link>
        </Flex>
        {/**
                     *  <Flex width="65%" mt={4} mb={4}>
                            <Text className="barred-form-text" fontSize="small" color="formSep" width="100%">
                                { t("form_or") }
                            </Text>
                        </Flex>
                        <Text fontSize="small" fontWeight="bold" color="#B8B8B8">
                            { t("form_with_socials") }
                        </Text>
                        <HStack spacing={4} mt={2}>
                            <IconButton aria-label="Accedi con Google" boxShadow="md" background="white" isRound icon={<FcGoogle />} onClick={ ()=> handleSocialLogin(GoogleProvider) }/>
                            <IconButton aria-label="Accedi con Facebook" boxShadow="md" background="white" isRound icon={<FaFacebookF color="#3b5998"/>} onClick={ ()=> handleSocialLogin(FacebookProvider) }/>
                            <IconButton aria-label="Accedi con Twitter" boxShadow="md" background="white" isRound icon={<AiOutlineTwitter color="#00acee" />} onClick={ ()=> handleSocialLogin(TwitterProvider) } />
                        </HStack>
                     */}
      </Flex>
    </UnauthenticatedCard>
  );
}

export default Signup;

/**
 * Old error message
 * <Box background="danger" width="80%" borderRadius="xl" pl={4} pr={4} pt={2} pb={2} mt={3}>
        <Text textAlign="center" color="#680000">
            {error}
        </Text>
    </Box>
 */
