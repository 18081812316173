import React, { useEffect, useState, useRef } from 'react';
import { Flex, Image, Heading, Text, Box, Icon, CloseButton, Accordion, Button, useToast } from '@chakra-ui/react';
import { FaImage } from 'react-icons/fa';
import { BiMap } from 'react-icons/bi';
import { connect } from 'react-redux';
import UUID from 'react-uuid';
import SyncLoader from 'react-spinners/SyncLoader';

import OpenHourItem from './OpenHourItem';
import ImageLoader from './ImageLoader';

import bgPlaceholder from '../../../assets/bg-placeholder.jpg';
import LoadingToast from './utils/LoadingToast';
import { uploadRestaurantImage } from '../../../API/updateRestaurantImage';
import { updateRestaurantImage, deletePreviousRestaurantImage } from '../../../Redux/Actions/Actions';

import { blankLangSheet } from './utils/SupportedLangs';
import InternationalEditor from './InternationalEditor';
import { savePdf } from '../../../utils/print';

import './Styles/Restaurant.css';
import { database } from '../../../utils/firebase';

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
  dishes: state.dishes,
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  updateLocalRestaurantImage: (imgURL) => dispatch(updateRestaurantImage(imgURL)),
  deletePreviousRestaurantImage: () => dispatch(deletePreviousRestaurantImage()),
});

function RestaurantHome(props) {
  const [catchphrase, setCatchphrase] = useState({ ...blankLangSheet });
  const [displayImage, setDisplayImage] = useState(null);
  const [openHours, setOpenHours] = useState(null);
  const [didCoverLoad, setDidCoverLoad] = useState(undefined);

  useEffect(() => {
    const homeImageDidLoad = window.sessionStorage.getItem('homeImageDidLoad');
    if (homeImageDidLoad !== null) setDidCoverLoad(homeImageDidLoad);
    else setDidCoverLoad(false);
  }, []);

  useEffect(() => {
    if (didCoverLoad !== undefined) {
      console.log(window.sessionStorage.getItem('homeImageDidLoad'));
      if (window.sessionStorage.getItem('homeImageDidLoad') === null && !didCoverLoad) {
        window.sessionStorage.setItem('homeImageDidLoad', false);
        console.log('Setting session storage to false');
      } else if (didCoverLoad) {
        window.sessionStorage.setItem('homeImageDidLoad', true);
        console.log('Setting session storage to true');
      }
    }
  }, [didCoverLoad]);

  useEffect(() => {
    if (!!openHours) console.log('New open hours:', openHours);
  }, [openHours]);

  const toast = useToast();
  const thisToast = useRef();
  const toastID = 'image-upload-toast';

  const openHoursToString = (openHours) => {
    let openHoursString = '';

    if (openHours.length > 0) {
      openHours.forEach((interval) => {
        openHoursString += `${interval.open}-${interval.close}, `;
      });

      openHoursString = openHoursString.substring(0, openHoursString.length - 2);
    }

    return openHoursString;
  };

  useEffect(() => {
    const openHoursList = {};

    if (!!props.restaurant && !!props.restaurant.openHours) {
      ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].forEach((day) => {
        Object.assign(openHoursList, { [day]: [] });
        if (props.restaurant.openHours[day].length <= 0) return;

        const openHours = props.restaurant.openHours[day].replaceAll(' ', '').split(',');
        openHours.forEach((oh) => {
          const openCloseHours = oh.toString().split('-');

          const openH = openCloseHours[0].split(':')[0];
          const openM = openCloseHours[0].split(':')[1];

          const closeH = openCloseHours[1].split(':')[0];
          const closeM = openCloseHours[1].split(':')[1];

          openHoursList[day].push({
            id: UUID(),
            open: `${openH}:${openM}`,
            close: `${closeH}:${closeM}`,
          });
        });
      });

      setOpenHours(openHoursList);
    }
  }, [props.restaurant]);

  useEffect(() => {
    if (!!catchphrase) console.log(catchphrase);
  }, [catchphrase]);

  useEffect(() => {
    if (props.restaurant) {
      setDisplayImage({
        image: props.restaurant.cover || bgPlaceholder,
        metaData: null,
      });

      setCatchphrase((prevCatchphrase) => ({
        ...prevCatchphrase,
        IT: props.restaurant.description || '',
        GB: props.restaurant.descriptionEN || '',
      }));
    }
  }, [props.restaurant]);

  const onLoadingToastClose = () => {
    if (thisToast.current) toast.close(thisToast.current);
  };

  const addLoadingToast = () => {
    if (!toast.isActive(toastID))
      thisToast.current = toast({
        id: toastID,
        position: 'bottom-left',
        render: () => (
          <LoadingToast
            title="Caricamento immagine in corso"
            content="Attendi il completamento dell'operazione, sarai notificato appena conclusa."
            accent
            onClose={() => onLoadingToastClose()}
          />
        ),
      });
  };

  const updateCatchphrase = (newCatchphrase) => {
    setCatchphrase(newCatchphrase);
  };

  const copySuccess = useToast();
  const displaySuccessToast = () => {
    copySuccess({
      title: 'Successo.',
      description: 'Il link al menù è stato copiato correttamente.',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const handleImagePicked = (preview, image) => {
    if (image && preview)
      setDisplayImage({
        image: preview,
        metaData: image,
      });
  };

  const handleUndoImagePick = () => {
    props.deletePreviousRestaurantImage();
  };

  const onSaveSuccess = () => {
    setTimeout(() => {
      toast({
        title: 'Salvataggio avvenuto con successo.',
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'bottom-left',
      });
    }, 1000);
  };

  const onSaveError = (err) => {
    console.log(err);
    setTimeout(() => {
      toast({
        title: "Impossibile aggiornare l'immagine.",
        message: 'Consulta la console per ulteriori informazioni',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'bottom-left',
      });
    }, 1000);
  };

  const handleSave = () => {
    if (!!displayImage.image && displayImage.image !== props.restaurant.cover) {
      addLoadingToast();
      uploadRestaurantImage(props.restaurant.restaurantKey, displayImage.metaData)
        .then((imageURL) => {
          console.log('Percorso B');
          props
            .updateLocalRestaurantImage(imageURL)
            .then(() => {
              onSaveSuccess();
            })
            .catch((err) => onSaveError(err));
        })
        .catch((err) => onSaveError(err));
    }

    if (catchphrase['IT'] !== props.restaurant.description)
      database.ref('restaurants').child(props.restaurant.restaurantKey).update({ description: catchphrase['IT'] });

    if (
      !('descriptionEN' in props.restaurant) ||
      ('descriptionEN' in props.restaurant && catchphrase['GB'] !== props.restaurant.descriptionEN)
    )
      if (catchphrase['GB'].length <= 0)
        database.ref('restaurants').child(`${props.restaurant.restaurantKey}/descriptionEN`).remove();
      else
        database.ref('restaurants').child(props.restaurant.restaurantKey).update({ descriptionEN: catchphrase['GB'] });

    const newOpenHours = {};

    Object.keys(openHours).forEach((day) => {
      Object.assign(newOpenHours, { [day]: openHoursToString(openHours[day]) });
    });

    database.ref('restaurants').child(`${props.restaurant.restaurantKey}/openHours`).set(newOpenHours);
    console.log(newOpenHours);
  };

  return (
    <>
      {/* border="1px solid red */}
      <Flex
        width="100%"
        justifyContent="center"
        height={{ base: 'calc(100vh - 84px)', md: 'calc(100vh - 102px)' }}
        mt={-4}>
        {' '}
        {/* The container */}
        <Flex
          width={{ base: '100%', md: '614px' }}
          border="1px solid"
          borderColor="blackAlpha.200"
          boxShadow="sm"
          background="white"
          direction="column"
          padding={0}
          margin={0}
          mt={8}
          position="relative"
          overflowY="scroll"
          className="noScrollbar">
          {' '}
          {/* The actual post */}
          <ImageLoader onLoadEnd={handleImagePicked}>
            {(imgs, previews, styles, controls) => (
              <Box
                width="100%"
                position="relative"
                as="label"
                htmlFor="rest-home-file-upload"
                backgroundColor="#edf2f7">
                <Image
                  objectFit="cover"
                  src={(displayImage && displayImage.image) || bgPlaceholder}
                  height={{ base: '150px', md: '250px' }}
                  width="100%"
                  opacity={didCoverLoad ? 1 : 0}
                  onLoad={() => setDidCoverLoad(true)}
                />
                {!window.sessionStorage.getItem('homeImageDidLoad') && !didCoverLoad && (
                  <Flex position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)">
                    <SyncLoader size={15} color="#4BAC61" loading margin={2} />
                  </Flex>
                )}
                <Box
                  border="1px solid"
                  borderColor="blackAlpha.300"
                  background="gray.100"
                  borderRadius="100%"
                  position="absolute"
                  p={{ base: 3, md: 6 }}
                  top="100%"
                  display="flex"
                  transform="translateY(-75%)"
                  right="7%"
                  cursor="pointer"
                  transition="all 0.3s"
                  _hover={{ background: '#edf2f7' }}
                  _focus={{ background: '#e2e8f0' }}
                  as="label"
                  htmlFor="rest-home-file-upload"
                  opacity={didCoverLoad ? 1 : 0}>
                  <Icon as={FaImage} w={{ base: 6, md: 10 }} h={{ base: 6, md: 10 }} color="gray.700" />
                </Box>
                <CloseButton
                  position="absolute"
                  right="0"
                  top="0"
                  size="lg"
                  stroke="white"
                  onClick={handleUndoImagePick}
                />
                <input
                  id="rest-home-file-upload"
                  type="file"
                  accept="image/png, image/jpeg, image/webp"
                  onChange={controls}
                  style={styles}
                />
              </Box>
            )}
          </ImageLoader>
          <form
            method="post"
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              direction: 'column',
              width: '100%',
              padding: '1.125rem',
            }}>
            <Text
              fontSize="12px"
              fontWeight="bold"
              alignSelf="flex-start"
              mb={1}
              color="blackAlpha.800"
              mt={1}
              display={{ base: 'flex', lg: 'none' }}>
              Condividi il tuo menù
            </Text>
            <Flex width="100%" ml={2} justifyContent="flex-start" display={{ base: 'flex', lg: 'none' }}>
              <Box
                padding={2}
                paddingLeft={4}
                paddingRight={4}
                border="1px solid rgba(0,0,0, 0.8)"
                borderColor="gray.500"
                borderRadius="3xl"
                backgroundColor="gray.100"
                cursor="pointer"
                mr={4}
                fontSize="xs"
                ml={{ base: '0', sm: '2' }}
                onClick={() => savePdf(props.restaurant, props.categories, props.dishes, false, true, undefined)}>
                Stampa Menù
              </Box>
              <Box
                padding={2}
                paddingLeft={4}
                paddingRight={4}
                border="1px solid rgba(0,0,0, 0.8)"
                borderColor="gray.500"
                borderRadius="3xl"
                backgroundColor="gray.100"
                cursor="pointer"
                fontSize="xs"
                ml={{ base: '0', sm: '2' }}
                onClick={() => {
                  displaySuccessToast();
                  navigator.clipboard.writeText(`https://feedinapp.com/web/${props.restaurant.restaurantKey}`);
                }}>
                Copia link
              </Box>
            </Flex>
            <Heading mt={2} color="blackAlpha.700" as="h4" size="md" alignSelf="flex-start" />

            {/* <Flex direction="column" width="100%" mt={3}>
                                <Text fontSize="sm" color="#3182ce">
                                    Catchphrase
                                </Text>
                                <Input variant="flushed" placeholder="Insert your catchphrase" />
                            </Flex> */}
            <InternationalEditor
              label="Descrizione"
              value={catchphrase}
              placeholder="Descrizione ristorante"
              onChange={(catchphrase) => {
                updateCatchphrase(catchphrase);
              }}
              mb={3}
              color="#21588C"
              toUpper={false}
              spacingMode={false}
              verboseLang={false}
            />

            {props.restaurant &&
              props.restaurant.address &&
              Object.values(props.restaurant.address).filter((addrItm) => addrItm.length > 0).length > 0 && (
                <Flex width="100%" mt={6} alignItems="center">
                  <>
                    <Icon as={BiMap} w={6} h={6} color="teal" />
                    <Text ml={2}>{Object.values(props.restaurant.address).join(` \u2022 `)}</Text>
                  </>
                </Flex>
              )}

            <Flex width="100%" direction="column" mt={4} justifyContent="center">
              <Heading mt={2} mb={1} color="blackAlpha.700" as="h4" size="sm">
                Gestisci orari apertura
              </Heading>

              <Accordion allowToggle>
                {['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day) => (
                  <OpenHourItem
                    key={day}
                    day={day}
                    openHours={!!openHours ? openHours[day] : []}
                    onChange={(newHours) => {
                      if (!!openHours && typeof openHours[day] !== 'undefined')
                        setOpenHours((oldOH) => ({
                          ...oldOH,
                          [day]: newHours,
                        }));
                    }}
                    onDelete={(day, deletedID) =>
                      setOpenHours((oldOpenHours) => {
                        const newDayOH = openHours[day].filter((oh) => oh.id !== deletedID);
                        return {
                          ...oldOpenHours,
                          [day]: newDayOH,
                        };
                      })
                    }
                  />
                ))}
              </Accordion>
            </Flex>

            <Button
              colorScheme="green"
              background="#4bac61"
              _focus={{ outline: 'none' }}
              alignSelf="flex-start"
              mt={6}
              type="submit"
              onClick={handleSave}>
              <Text pl={3} pr={3}>
                Salva
              </Text>
            </Button>
          </form>
        </Flex>
      </Flex>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantHome);
