import firebase from 'firebase/app';
import 'firebase/database';
/**
 * data.categoryName << Nome del ristorante
 * data.uid << UID dell'utente
 * data.itemName << nome piatto
 * data.price << prezzo piatto
 * data.restaurantKey << key ristorante
 */
export const createMenuRecord = (data) => {
  if (!data.categoryName || !data.uid || !data.itemName || !data.price || !data.restaurantKey)
    return {
      status: 'failure/missing-data',
    };

  return firebase
    .database()
    .ref(`categories/${data.restaurantKey}/categories`)
    .push({
      name: data.categoryName,
      userKey: data.uid,
      restaurantKey: data.restaurantKey,
      visible: true,
    })
    .then((newCategory) => {
      firebase
        .database()
        .ref(`categories/${data.restaurantKey}/categories/${newCategory.key}`)
        .update({ categoryKey: newCategory.key })
        .then(() =>
          firebase
            .database()
            .ref(`menu/${data.restaurantKey}/items`)
            .push({
              name: data.itemName,
              userKey: data.uid,
              restaurantKey: data.restaurantKey,
              available: true,
              categoryKey: newCategory.key,
              price: data.price,
              pos: 0,
            })
            .then((dish) =>
              firebase
                .database()
                .ref(`menu/${data.restaurantKey}/items/${dish.key}`)
                .update({
                  itemKey: dish.key,
                })
                .then(() =>
                  firebase
                    .database()
                    .ref(`menu/${data.restaurantKey}`)
                    .update({
                      numberOfElements: 1,
                    })
                    .then(() =>
                      firebase
                        .database()
                        .ref(`categories/${data.restaurantKey}`)
                        .update({
                          numberOfItems: 1,
                        })
                        .then(() => ({
                          status: 'success',
                          dishKey: dish.key,
                          categoryKey: newCategory.key,
                        }))
                        .catch((err) => ({
                          status: 'failure/could-not-update-category-restaurantKey',
                          payload: err,
                        })),
                    )
                    .catch((err) => ({
                      status: 'failure/could-not-update-restaurant-restaurantKey',
                      payload: err,
                    })),
                ),
            )
            .catch((err) => ({
              status: 'failure/could-not-create-dish',
              payload: err,
            })),
        )
        .catch((err) => ({
          status: 'failure/could-not-update-category-key',
          payload: err,
        }));
    });
};
