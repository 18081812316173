import React, { useState, createRef, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';

import './Styles/Checkbox.css';

function Checkbox(props) {
  const [checked, setChecked] = useState(props.isChecked || false);
  const checkbox = createRef();

  const toggleChecked = () => {
    if (!props.disabled) {
      props.onChange();
      setChecked(!checked);
    }
  };

  useEffect(() => {
    setChecked(props.isChecked || false);
  }, [props.checked]);

  return (
    <Box
      className="form-check"
      display="inline-block"
      alignSelf="flex-start"
      pl={props.pl}
      pr={props.pr}
      pt={props.pt}
      pb={props.pb}
      ml={props.ml}
      mr={props.mr}
      mt={props.mt}
      mb={props.mb}
      width={props.width}
      height={props.height}
      onClick={() => {
        checkbox.current.focus();
      }}>
      <input
        type="checkbox"
        className="form-check-input"
        id="checkbox"
        checked={checked}
        ref={checkbox}
        onChange={() => {}}
      />

      <Text
        as="label"
        className={props.disabled ? 'form-check-label-disabled' : 'form-check-label'}
        htmlFor="checked"
        onClick={toggleChecked}
        pl={props.textpl}
        pr={props.textpr}
        pt={props.textpt}
        pb={props.textpb}
        ml={props.textml}
        mr={props.textmr}
        mt={props.textmt}
        mb={props.textmb}
        fontSize={props.fontSize}
        fontWeight={props.fontWeight}
        color={props.color}>
        {props.label || 'Missing label prop'}
      </Text>
    </Box>
  );
}

export default Checkbox;
