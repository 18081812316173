import { appendSubscriptionStatus } from '../Redux/Actions/Actions';
import firebase from '../utils/firebase';

export const fetchSubscriptionStatus = () => (dispatch, state) =>
  new Promise((resolve, reject) => {
    const theUser = state().currentUser;
    if (!theUser)
      reject(
        new Error({
          message: 'error/user-undefined',
        }),
      );

    firebase
      .firestore()
      .collection(`customers/${theUser.uid}/subscriptions`)
      .get()
      .then((docSnapshot) => {
        console.log(docSnapshot);

        const status = docSnapshot.docs[0] ? docSnapshot.docs[0].data().status : 'undefined';

        dispatch(appendSubscriptionStatus(status));
        resolve(appendSubscriptionStatus(status));
      });
  });
