import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/firestore';

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = firebase.initializeApp(firebaseConfig);

const functions = firebase.functions();

export const addWebmasterRole = functions.httpsCallable('addWebmasterRole');
export const addPremiumRole = functions.httpsCallable('addPremiumRole');
export const revokePremiumRole = functions.httpsCallable('revokePremiumRole');
export const createRestaurant = functions.httpsCallable('createRestaurant');
export const createMenu = functions.httpsCallable('createMenuRecord');
export const updateUserInfo = functions.httpsCallable('updateUserInfo');

export const auth = app.auth();
export const database = firebase.database();
export default app;