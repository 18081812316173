import firebase from 'firebase/app';
import 'firebase/database';

/**
 * data.restaurantName << Nome del ristorante
 * data.uid << UID dell'utente
 */
export const createRestaurant = (data) => {
  let restaurantKey = null;
  if (!data.restaurantName || !data.uid)
    return {
      status: 'failure/missing-data',
    };

  return firebase
    .database()
    .ref('restaurants')
    .push({
      name: data.restaurantName,
      userKey: data.uid,
      openHours: {
        mon: '',
        tue: '',
        wed: '',
        thu: '',
        fri: '',
        sat: '',
        sun: '',
      },
      allergeni: {
        a1: false,
        a10: false,
        a11: false,
        a12: false,
        a13: false,
        a14: false,
        a2: false,
        a3: false,
        a4: false,
        a5: false,
        a6: false,
        a7: false,
        a8: false,
        a9: false,
      },
      cover:
        'https://firebasestorage.googleapis.com/v0/b/civil-willow-211119.appspot.com/o/bg-placeholder.jpg?alt=media&token=aa57fb46-05b4-4315-b44a-07e368de093e',
      description: '',
      address: {
        cap: '',
        city: '',
        province: '',
        street: '',
        streetNumber: '',
      },
    })
    .then((newRestaurant) => {
      restaurantKey = newRestaurant.key;
      return firebase
        .database()
        .ref('restaurants')
        .child(newRestaurant.key)
        .update({
          restaurantKey: newRestaurant.key,
        })
        .then(() =>
          firebase
            .database()
            .ref('users')
            .child(data.uid)
            .set({
              restaurantKey: newRestaurant.key,
              userKey: data.uid,
            })
            .then(() => ({
              status: 'success',
              data: restaurantKey,
            }))
            .catch((err) => ({
              status: 'failure/user-record-creation-failed',
              error: err,
            })),
        )
        .catch((err) => ({
          status: 'failure/restaurant-key-update-failed',
          error: err,
        }));
    })
    .catch((err) => ({
      status: 'failure/restaurant-creation-failed',
      error: err,
    }));
};
