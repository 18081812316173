import React, { useState } from 'react';
import { Flex, Text, Heading, Input, Button } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import InputNumber from 'rc-input-number';
import PropTypes from 'prop-types';

import UnauthenticatedCard from '../../../UnauthenticatedCard';
import 'rc-input-number/assets/index.css';

function CreateCategoryStep({ onCategoryNameChange, onDishNameChange, onDishPriceChange, onAdvance }) {
  const [categoryName, setCategoryName] = useState('');
  const [dishName, setDishName] = useState('');
  const [dishPrice, setDishPrice] = useState(0);

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
    onCategoryNameChange(e.target.value);
  };

  const handleDishNameChange = (e) => {
    setDishName(e.target.value);
    onDishNameChange(e.target.value);
  };

  const handleDishPriceChange = (val) => {
    setDishPrice(val);
    onDishPriceChange(val);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdvance(e);
  };

  return (
    <UnauthenticatedCard onSubmit={handleSubmit}>
      <Flex width="100%" height="100%" p={3} direction="column" justifyContent="center">
        <Heading as="h1" size="lg" fontWeight="bold" color="blackAlpha.800" alignSelf="flex-start">
          Inizia a creare il tuo menù!
        </Heading>

        <Text color="blackAlpha.800" size="normal" mt={10} fontWeight="medium">
          Prova ad inserire una categoria
        </Text>

        <Input
          width="100%"
          variant="flushed"
          placeholder="es Primi piatti, Antipasti"
          value={categoryName}
          onChange={handleCategoryNameChange}
          isRequired
        />

        <Text color="blackAlpha.800" size="normal" mt={6} fontWeight="medium">
          Inserisci un piatto
        </Text>
        <Flex direction="row" alignItems="flex-end">
          <Input
            width="100%"
            variant="flushed"
            placeholder="es Tagliatelle, Penne"
            flex="3"
            value={dishName}
            onChange={handleDishNameChange}
            isRequired
          />
          <Flex flex="1" ml={3}>
            <InputNumber
              step="0.01"
              defaultValue="0"
              min="0"
              value={dishPrice}
              onChange={handleDishPriceChange}
              required
            />
            <Text ml={2}>{'\u20AC'}</Text>
          </Flex>
        </Flex>

        <Button
          variant="undecorated"
          color="#FFF"
          backgroundColor="primary"
          background="linear-gradient(90deg, rgba(75,172,97,1) 0%, rgba(6,94,43,1) 100%)"
          _hover={{ background: 'rgba(6,94,43,1)' }}
          _activeLink={{ background: 'rgba(6,94,43,1)' }}
          width="100%"
          type="submit"
          mt={10}
          disabled={categoryName.length <= 0 || dishName.length <= 0}
          isLoading={false}
          rightIcon={<ChevronRightIcon />}>
          Avanti
        </Button>

        <Text color="blackAlpha.700" size="sm" mt={10}>
          Tutte le informazioni inserite adesso potranno essere successivamente modificate dalla pagina di gestione.
        </Text>
      </Flex>
    </UnauthenticatedCard>
  );
}

CreateCategoryStep.propTypes = {
  onCategoryNameChange: PropTypes.func.isRequired,
  onDishNameChange: PropTypes.func.isRequired,
  onDishPriceChange: PropTypes.func.isRequired,
  onAdvance: PropTypes.func.isRequired,
};

export default CreateCategoryStep;
