import React, { useState, useEffect } from 'react';
import { Flex, Avatar, Button, Box, Text, Heading, Icon, ButtonGroup, useBreakpointValue } from '@chakra-ui/react';
import { FaPen, FaPhone, FaFacebookF, FaInstagram, FaGlobe } from 'react-icons/fa';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import ImageLoader from '../Restaurant/ImageLoader';

import FloatingLabelInput from './FloatingLabelInput';
import FLIMapsAutocomplete from './FLIMapsAutocomplete';
import { saveEdits } from '../../../API/updateProfileInfo';

import AvatarPlaceholder from '../../../assets/avatar_placeholder.png';

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) => ({
  saveEdits: (address, name, email, phone, facebook, instagram, website, icon) =>
    dispatch(saveEdits(address, name, email, phone, facebook, instagram, website, icon)),
});

function EditProfile({ currentUser, restaurant, saveEdits }) {
  const [address, setAddress] = useState('');
  const [fullAddress, setFullAddress] = useState(null);

  const [isPhoneIconActive, setPhoneIconActive] = useState(false);
  const [isFacebookIconActive, setFacebookIconActive] = useState(false);
  const [isInstagramIconActive, setInstagramIconActive] = useState(false);
  const [isWebsiteIconActive, setWebsiteIconActive] = useState(false);

  const [profileAvatar, setProfileAvatar] = useState(null);
  const [profileAvatarData, setProfileAvatarData] = useState(null);

  const [restaurantName, setRestaurantName] = useState(undefined);
  const [restaurantEmail, setRestaurantEmail] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [website, setWebsite] = useState(undefined);
  const [instagram, setInstagram] = useState(undefined);
  const [facebook, setFacebook] = useState(undefined);
  const [updatedAddress, setUpdatedAddress] = useState(undefined);

  const [validator, setValidator] = useState(undefined);

  function truncate(str, n) {
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
  }

  useEffect(() => {
    setValidator(new SimpleReactValidator());
  }, []);

  useEffect(() => {
    setProfileAvatar(restaurant.icon || AvatarPlaceholder);
  }, [restaurant]);

  useEffect(() => {
    if (profileAvatarData) console.log(profileAvatarData);
  }, [profileAvatarData]);

  useEffect(() => {
    if (currentUser && currentUser.photoURL) setProfileAvatar(currentUser.photoURL);
  }, [currentUser]);

  useEffect(() => {
    if (!!fullAddress) {
      setUpdatedAddress({
        cap: fullAddress[0].address_components.find((addressComponent) =>
          addressComponent.types.includes('postal_code'),
        ).long_name,
        city: fullAddress[0].address_components.find((addressComponent) =>
          addressComponent.types.includes('administrative_area_level_3'),
        ).short_name,
        province: fullAddress[0].address_components.find((addressComponent) =>
          addressComponent.types.includes('administrative_area_level_2'),
        ).short_name,
        street: fullAddress[0].address_components.find((addressComponent) => addressComponent.types.includes('route'))
          .short_name,
        streetNumber: fullAddress[0].address_components.find((addressComponent) =>
          addressComponent.types.includes('street_number'),
        ).short_name,
      });
    }
  }, [fullAddress]);

  useEffect(() => {
    if (!!restaurant) {
      setRestaurantName(restaurant.name || '');
      setRestaurantEmail(restaurant.email || '');
      setPhoneNumber(restaurant.phone || '');
      setWebsite(restaurant.website || '');
      setInstagram(restaurant.instagram || '');
      setFacebook(restaurant.facebook || '');
    }
  }, [restaurant]);

  useEffect(() => {
    if (!!restaurantName) console.log(restaurantName);
  }, [restaurantName]);

  useEffect(() => {
    if (!!restaurantEmail) console.log(restaurantEmail);
  }, [restaurantEmail]);

  const avatar = useBreakpointValue({
    base: (
      <Avatar src={profileAvatar} size="xl" position="relative">
        <Box
          position="absolute"
          top="80%"
          right="6%"
          width="2rem"
          height="2rem"
          bg="#3182ce"
          borderRadius="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer">
          <ImageLoader
            onLoadEnd={(newImage, previews) => {
              if (newImage && previews) {
                setProfileAvatar(previews);
                setProfileAvatarData(newImage);
              }
            }}>
            {(imgs, previews, styles, controls) => (
              <>
                <Flex
                  as="label"
                  htmlFor="profile-pic-img-picker"
                  justifyContent="center"
                  alignitems="center"
                  cursor="pointer"
                  p={2}>
                  <Icon as={FaPen} h={3} w={3} color="white" />
                </Flex>
                <input
                  id="profile-pic-img-picker"
                  type="file"
                  accept="image/png, image/jpeg, image/webp"
                  onChange={controls}
                  style={styles}
                />
              </>
            )}
          </ImageLoader>
        </Box>
      </Avatar>
    ),
    lg: (
      <Avatar src={profileAvatar} size="2xl" position="relative">
        <Box
          position="absolute"
          top="80%"
          right="6%"
          width="2rem"
          height="2rem"
          bg="#3182ce"
          borderRadius="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer">
          <ImageLoader
            onLoadEnd={(previews, image) => {
              if (previews && image) {
                setProfileAvatar(previews);
                setProfileAvatarData(image);
              }
            }}>
            {(imgs, previews, styles, controls) => (
              <>
                <Flex
                  as="label"
                  htmlFor="profile-pic-img-picker"
                  justifyContent="center"
                  alignitems="center"
                  cursor="pointer"
                  p={2}>
                  <Icon as={FaPen} h={3} w={3} color="white" />
                </Flex>
                <input
                  id="profile-pic-img-picker"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={controls}
                  style={styles}
                />
              </>
            )}
          </ImageLoader>
        </Box>
      </Avatar>
    ),
  });

  return (
    <Flex direction="column" alignItems="center" width={{ base: '100%', lg: '60%' }} pt={6} pl={6}>
      <Heading as="h4" size="md" color="blackAlpha.700" width="100%">
        Edit Profile
      </Heading>
      <Box border="6px solid" borderColor="white" borderRadius="100%" boxShadow="0 0 0 1px rgba(0,0,0,.2)" mb={8}>
        {avatar}
      </Box>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%',
          marginRight: '1.125rem',
        }}
        method="post">
        <ButtonGroup
          width="100%"
          border="1px solid rgba(0,0,0,0.1)"
          borderRadius="md"
          p={2}
          pb={5}
          position="relative"
          display="flex"
          flexDirection="column">
          <Text
            backgroundColor="white"
            fontSize="sm"
            color="blackAlpha.800"
            position="absolute"
            top="0"
            right="0"
            transform="translate(-50%, -50%)"
            pl={2}
            pr={2}
            fontWeight="medium">
            Basic info
          </Text>
          <FloatingLabelInput
            labelFor="restaurantName"
            label="Restaurant Name"
            value={restaurantName || ''}
            width={{ base: '98%', lg: '70%' }}
            mt={3}
            onChange={(e) => setRestaurantName(e.target.value)}
          />
          <FloatingLabelInput
            labelFor="email"
            label="Restaurant Email"
            width={{ base: '98%', lg: '70%' }}
            mt={5}
            value={restaurantEmail || ''}
            onChange={(e) => setRestaurantEmail(e.target.value)}
          />
          <FLIMapsAutocomplete
            address={address}
            label={
              !!restaurant.address
                ? truncate(`Address: ${Object.values(restaurant.address).join(', ')}`, 36)
                : 'Address'
            }
            setAddress={setAddress}
            setFullAddress={setFullAddress}
            mt={5}
            width={{ base: '98%', lg: '70%' }}
          />
        </ButtonGroup>

        <ButtonGroup
          width="100%"
          border="1px solid rgba(0,0,0,0.1)"
          borderRadius="md"
          p={2}
          position="relative"
          display="flex"
          flexDirection="column"
          mt={10}
          zIndex="1">
          <Text
            backgroundColor="white"
            fontSize="sm"
            color="blackAlpha.800"
            position="absolute"
            top="0"
            right="0"
            transform="translate(-50%, -50%)"
            pl={2}
            pr={2}
            fontWeight="medium">
            Contacts
          </Text>

          <Flex width={{ base: '95%', lg: '80%' }} alignItems="center">
            <FloatingLabelInput
              labelFor="Phone"
              label="Phone Number"
              width="100%"
              mt={3}
              value={phoneNumber || ''}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onFocus={() => setPhoneIconActive(true)}
              onBlur={() => setPhoneIconActive(false)}
            />
            {validator && validator.message('Phone', phoneNumber, 'phone')}

            <Icon
              as={FaPhone}
              color={isPhoneIconActive ? '#3182ce' : 'blackAlpha.400'}
              h={4}
              w={4}
              ml={2}
              alignSelf="flex-end"
              transition=".2s ease all"
            />
          </Flex>

          <Flex width={{ base: '95%', lg: '80%' }} alignItems="center">
            <FloatingLabelInput
              labelFor="Facebook"
              label="Facebook"
              width="100%"
              mt={3}
              value={facebook || ''}
              onChange={(e) => setFacebook(e.target.value)}
              onFocus={() => setFacebookIconActive(true)}
              onBlur={() => setFacebookIconActive(false)}
            />
            {validator && validator.message('Facebook', facebook, 'url')}

            <Icon
              as={FaFacebookF}
              color={isFacebookIconActive ? '#3182ce' : 'blackAlpha.400'}
              h={6}
              w={6}
              ml={2}
              alignSelf="flex-end"
              transition=".2s ease all"
            />
          </Flex>

          <Flex width={{ base: '95%', lg: '80%' }} alignItems="center">
            <FloatingLabelInput
              labelFor="Instagram"
              label="Instagram"
              width="100%"
              value={instagram || ''}
              mt={3}
              onChange={(e) => setInstagram(e.target.value)}
              onFocus={() => setInstagramIconActive(true)}
              onBlur={() => setInstagramIconActive(false)}
            />
            <Icon
              as={FaInstagram}
              color={isInstagramIconActive ? '#3182ce' : 'blackAlpha.400'}
              h={6}
              w={6}
              ml={2}
              alignSelf="flex-end"
              transition=".2s ease all"
            />
          </Flex>

          <Flex width={{ base: '95%', lg: '80%' }} alignItems="center" mb={3}>
            <FloatingLabelInput
              labelFor="Website"
              label="Website"
              width="100%"
              value={website || ''}
              mt={3}
              onChange={(e) => setWebsite(e.target.value)}
              onFocus={() => setWebsiteIconActive(true)}
              onBlur={() => setWebsiteIconActive(false)}
            />
            <Icon
              as={FaGlobe}
              color={isWebsiteIconActive ? '#3182ce' : 'blackAlpha.400'}
              h={6}
              w={6}
              ml={2}
              alignSelf="flex-end"
              transition=".2s ease all"
            />
          </Flex>
        </ButtonGroup>

        <Button
          colorScheme="green"
          size="md"
          mt={6}
          mb={6}
          onClick={() =>
            saveEdits(
              updatedAddress,
              restaurantName,
              restaurantEmail,
              phoneNumber,
              facebook,
              instagram,
              website,
              profileAvatarData,
            )
          }>
          <Text pl={3} pr={3}>
            Save
          </Text>
        </Button>
      </form>
    </Flex>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
