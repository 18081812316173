import * as React from 'react';

import {
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  TabList,
  Flex,
  Text,
  Textarea,
  Divider,
  Button,
  Avatar,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Input,
  Tooltip,
  Image,
  CloseButton,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';

import { FaPencilAlt, FaCameraRetro, FaPlus } from 'react-icons/fa';
import { RiImageAddFill } from 'react-icons/ri';

import { connect } from 'react-redux';
import { makeTextOnlyPost } from '../../../API/makePost';
import { makeRichPost } from '../../../API/makeRichPost';

import ImageCropper from './Cropper/ImageCropper';

import AvatarTemplate from '../../../assets/avatar_placeholder.png';
import './Styles/MakePostForm.css';
import LoadingToast from '../Restaurant/utils/LoadingToast';

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) => ({
  makeTextOnlyPost: (resKey, content, labels) => dispatch(makeTextOnlyPost(resKey, content, labels)),
  makeRichPost: (text, labels, images) => dispatch(makeRichPost(text, labels, images)),
});

/**
 *
 * @TODO: Actually apply the crop to the image. Right now the crop is only applied
 * to the selectedPreview, it needs to be actually stored in the corresponding image
 */
function MakePostForm({ currentUser, restaurant, makeTextOnlyPost, makeRichPost }) {
  console.log(currentUser);
  const [post, setPost] = React.useState('');
  const [images, setImages] = React.useState([]);
  const [buttonEnabled, setButtonEnabled] = React.useState(true);
  const [caption, setCaption] = React.useState('');
  const [previewURLs, setPreviewURLs] = React.useState([]);
  const [selectedPreview, setSelectedPreview] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState(0);
  const [activePreview, setActivePreview] = React.useState(-1);
  const [selectedLabels, setSelectedLabels] = React.useState(null);
  const toast = useToast();
  const thisToast = React.useRef();
  const toastID = 'delete-toast';

  const onLoadingToastClose = () => {
    if (thisToast.current) toast.close(thisToast.current);
  };

  React.useEffect(() => {
    if (!!images) console.log(images);
  }, [images]);

  const addLoadingToast = () => {
    if (!toast.isActive(toastID))
      thisToast.current = toast({
        id: toastID,
        position: 'bottom-left',
        render: () => (
          <LoadingToast
            title="Caricamento post in corso"
            content="Attendi il completamento dell'operazione, sarai notificato appena conclusa."
            accent
            onClose={() => onLoadingToastClose()}
          />
        ),
      });
  };

  const uploadImage = useBreakpointValue({ base: '75px', md: '120px' });

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setPost(inputValue);
  };

  const handleCaptionChange = (e) => {
    const inputValue = e.target.value;
    setCaption(inputValue);
  };

  const handleImagesSelected = (e) => {
    e.preventDefault();

    const reader = new Array(3);
    const files = Array.from(e.target.files).filter((file, idx) => idx < 3 - Object.values(previewURLs).length);

    files.forEach((image, idx) => {
      reader[idx] = new FileReader();

      reader[idx].onloadend = () => {
        setImages((images) => [...images, image]);
        setPreviewURLs((previewURLs) => [...previewURLs, reader[idx].result]);
      };

      reader[idx].readAsDataURL(image);
    });
  };

  const handleCropComplete = (newURL) => {
    const newPreviews = Array.from(previewURLs);
    newPreviews[activePreview] = newURL.url;

    setPreviewURLs(newPreviews);

    setImages((prevImages) => {
      const newImages = [...prevImages];
      const selectedImage = previewURLs.findIndex((preview) => preview === selectedPreview);
      if (selectedImage !== -1)
        newImages[selectedImage] = new File([newURL.file], newURL.url.substr(27, newURL.url.length - 1), {
          type: newURL.file.type,
        });

      return newImages;
    });
  };

  React.useEffect(() => {
    if (!!selectedLabels && selectedLabels.length > 0) console.log(selectedLabels);
  }, [selectedLabels]);

  return (
    <Flex width="100%" direction="column" alignItems="center" mt={4}>
      <Tabs
        isLazy
        isFitted
        padding={3}
        pb={0}
        width={{ base: '100%', md: '614px' }}
        border="1px solid"
        borderColor="blackAlpha.200"
        borderRadius="lg"
        boxShadow="sm"
        background="white"
        onChange={(idx) => setActiveTab(idx)}>
        <TabList>
          <Tab _focus={{ boxShadow: 'none' }}>
            <FaPencilAlt size="18px" color="#cb2431" />
            <Text
              color={activeTab === 0 ? '#1A202C' : 'blackAlpha.700'}
              fontWeight="medium"
              ml={3}
              transition=".4s ease all">
              Write Post
            </Text>
          </Tab>
          <Tab _focus={{ boxShadow: 'none' }}>
            <FaCameraRetro size="18px" color="#4bac61" />
            <Text
              ml={3}
              color={activeTab === 1 ? '#1A202C' : 'blackAlpha.700'}
              fontWeight="medium"
              transition=".4s ease all">
              Post Photo
            </Text>
          </Tab>
        </TabList>
        <TabPanels>
          {/* initially mounted */}
          <TabPanel>
            <Flex width="100%" direction="column">
              <Flex width="100%" justifyContent="center">
                <Avatar
                  src={restaurant && restaurant.icon ? restaurant.icon : AvatarTemplate}
                  size="md"
                  borderRadius="0"
                  mr={{ base: 0, md: 4 }}
                  display={{ base: 'none', md: 'block' }}
                />
                <Textarea
                  value={post}
                  onChange={handleInputChange}
                  placeholder="Share your next awesome event, a receipe or whatever you feel like!"
                  variant="unstyled"
                />
              </Flex>

              <Divider color="blackAlpha.200" width="100%" mt={6} />
              <Flex direction="row-reverse" width="100%" alignItems="center" mt={2}>
                <Button
                  disabled={!buttonEnabled || post === ''}
                  colorScheme="green"
                  size="sm"
                  onClick={() => {
                    setButtonEnabled(false);
                    addLoadingToast();
                    if (activeTab === 0)
                      makeTextOnlyPost(restaurant.restaurantKey, post, selectedLabels)
                        .then(() => {
                          onLoadingToastClose();
                          setTimeout(() => {
                            setButtonEnabled(true);
                            setPost('');
                            toast({
                              title: 'Post created.',
                              description: "We've created the post for you.",
                              status: 'success',
                              duration: 9000,
                              isClosable: true,
                            });
                          }, 1000);
                        })
                        .catch((err) => {
                          setButtonEnabled(true);
                          onLoadingToastClose();
                          console.log(err);
                          setTimeout(() => {
                            toast({
                              title: 'Could not create post.',
                              description: 'Check out console for further info.',
                              status: 'error',
                              duration: 9000,
                              isClosable: true,
                            });
                          }, 1000);
                        });
                  }}>
                  Post
                </Button>
                <Menu closeOnSelect={false}>
                  <MenuButton as={Button} mr={2} variant="ghost" colorScheme="green" size="sm">
                    Label
                  </MenuButton>
                  <MenuList minWidth="240px">
                    <MenuOptionGroup
                      title="Label"
                      type="checkbox"
                      onChange={(selected) => {
                        setSelectedLabels(Array.from(selected));
                      }}>
                      <MenuItemOption value="Event">Event</MenuItemOption>
                      <MenuItemOption value="Receipe">Receipe</MenuItemOption>
                      <MenuItemOption value="Warning">Warning</MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
          </TabPanel>

          {/* initially not mounted */}
          <TabPanel>
            <Flex width="100%" direction="column">
              <Flex
                width="100%"
                justifyContent="center"
                border="1px solid"
                borderColor="blackAlpha.200"
                p={2}
                borderRadius="md">
                <Avatar
                  src={restaurant && restaurant.icon ? restaurant.icon : AvatarTemplate}
                  size="md"
                  borderRadius="0"
                  mr={{ base: 0, md: 4 }}
                  display={{ base: 'none', md: 'block' }}
                />
                <Input
                  value={caption}
                  onChange={handleCaptionChange}
                  placeholder="Share your next awesome event, a receipe or whatever you feel like!"
                  variant="unstyled"
                />
              </Flex>

              <Flex
                width="100%"
                mt={3}
                mb={2}
                pt={2}
                pb={2}
                borderRadius="md"
                border="1px solid"
                borderColor="blackAlpha.300"
                borderStyle="dashed"
                direction="column">
                <Flex
                  width="100%"
                  justifyContent={Object.values(previewURLs).length <= 0 ? 'center' : 'flex-start'}
                  pl={Object.values(previewURLs).length <= 0 ? 0 : 2}>
                  {
                    /** Previews */

                    Object.values(previewURLs).map((URL, idx) => (
                      <React.Fragment key={Math.random(0, 1000)}>
                        <Flex
                          width="100px"
                          height="100px"
                          mt={{ base: 1, lg: 0 }}
                          mr={3}
                          p={activePreview === idx ? 1 : 0}
                          position="relative"
                          onClick={() => {
                            setActivePreview(idx);
                            setSelectedPreview(URL);
                          }}
                          border={activePreview === idx ? '2px solid #1a202c' : 'none'}
                          borderRadius="md"
                          overflow="hidden">
                          <Image src={URL} width="100%" height="100%" objectFit="cover" />
                          <CloseButton
                            size="sm"
                            borderRadius="100%"
                            position="absolute"
                            bottom="0"
                            right="0"
                            transform="translate(-10%, -10%)"
                            bg="#000"
                            color="white"
                            onClick={(event) => {
                              event.stopPropagation();

                              setSelectedPreview(null);

                              if (idx === 0) setActivePreview(1);
                              else setActivePreview(idx - 1);

                              setImages((images) => images.filter((image, i) => idx !== i));
                              setPreviewURLs((previewURLs) => previewURLs.filter((URL, i) => i !== idx));
                            }}
                          />
                        </Flex>
                      </React.Fragment>
                    ))
                  }
                  {
                    /** Input file upload */

                    Object.values(previewURLs).length <= 0 /** Upload button */ ? (
                      <>
                        <Tooltip placement="bottom" label="Upload an image">
                          <label htmlFor="file-upload" className="custom-file-upload">
                            <RiImageAddFill color="#666" size={uploadImage} />
                          </label>
                        </Tooltip>
                        <input
                          id="file-upload"
                          className="uploadImageBtn"
                          type="file"
                          accept="image/png, image/jpeg, image/webp"
                          onChange={handleImagesSelected}
                          multiple
                        />
                      </>
                    ) : (
                      Object.values(previewURLs).length < 3 /** Previews + Upload button */ && (
                        <Flex width="100px" height="100px">
                          <Tooltip placement="bottom" label="Upload an image">
                            <label htmlFor="file-upload" className="custom-file-upload dashed darken-on-hover">
                              <FaPlus size="48px" />
                            </label>
                          </Tooltip>
                          <input
                            id="file-upload"
                            className="uploadImageBtn"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={handleImagesSelected}
                            multiple
                          />
                        </Flex>
                      )
                    )
                  }
                </Flex>

                {selectedPreview && (
                  <Flex width="100%" mt={2} p={2}>
                    <ImageCropper imageUrl={selectedPreview} imageUpdater={handleCropComplete} />
                  </Flex>
                )}
              </Flex>
              <Divider color="blackAlpha.200" width="100%" mt={6} />
              <Flex direction="row-reverse" width="100%" alignItems="center" mt={2}>
                <Button
                  disabled={!buttonEnabled || caption === '' || images.length === 0}
                  colorScheme="green"
                  size="sm"
                  onClick={() => {
                    setButtonEnabled(false);
                    addLoadingToast();
                    makeRichPost(caption, selectedLabels, images)
                      .then(() => {
                        setCaption('');
                        setImages([]);
                        setPreviewURLs([]);
                        setSelectedPreview('');
                        setButtonEnabled(true);
                        onLoadingToastClose();
                        setTimeout(() => {
                          toast({
                            title: 'Post created.',
                            description: "We've created the post for you.",
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                          });
                        }, 1000);
                      })
                      .catch((err) => {
                        setCaption('');
                        setImages([]);
                        setPreviewURLs([]);
                        setSelectedPreview('');
                        setButtonEnabled(true);
                        onLoadingToastClose();
                        console.log(err);
                        setTimeout(() => {
                          toast({
                            title: 'Could not create post.',
                            description: 'Check out console for further info.',
                            status: 'error',
                            duration: 9000,
                            isClosable: true,
                          });
                        }, 1000);
                      });
                  }}>
                  Post
                </Button>
                <Menu closeOnSelect={false}>
                  <MenuButton as={Button} mr={2} variant="ghost" colorScheme="green" size="sm">
                    Label
                  </MenuButton>
                  <MenuList minWidth="240px">
                    <MenuOptionGroup
                      title="Label"
                      type="checkbox"
                      onChange={(selected) => {
                        setSelectedLabels(Array.from(selected));
                      }}>
                      <MenuItemOption value="Event">Event</MenuItemOption>
                      <MenuItemOption value="Receipe">Receipe</MenuItemOption>
                      <MenuItemOption value="Warning">Warning</MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MakePostForm);
