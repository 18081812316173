/* eslint-disable no-return-assign */
import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import {
  Box,
  Flex,
  Button,
  Text,
  useBreakpointValue,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import { connect } from 'react-redux';
import Checkbox from './Checkbox';

import './Styles/Aside.css';
import { savePdf, printQrCode } from '../../../utils/print';

import './Styles/DishPage.css';

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
  categories: state.categories,
  dishes: state.dishes,
});

function Aside(props) {
  const { restaurantId } = props || '';

  const [printUnavailable, setPrintUnavailable] = useState(false);
  const [printAllCategories, setPrintAllCategories] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState({});

  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });

  useEffect(() => {
    if (props.categories) {
      const sc = {};
      props.categories.forEach((category) => (sc[category.categoryKey] = false));
      setSelectedCategories(sc);
      console.log(sc);
    }
  }, [props.categories]);

  const scrollbar = useBreakpointValue({
    base: 'noScrollbar',
    md: 'decorated-vScrollbar',
  });

  const copySuccess = useToast();
  const displaySuccessToast = () => {
    copySuccess({
      title: 'Successo.',
      description: 'Il link al menù è stato copiato correttamente.',
      status: 'info',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column" width={{ base: '100%', md: 'auto' }}>
      {isVisible && props.restaurant && props.restaurant.subscriptionStatus === 'trialing' && (
        <Alert
          status="info"
          width={{ base: '100%', md: '300px' }}
          marginLeft={{ base: '0', md: '50px' }}
          borderRadius="md"
          mt={{ base: 0, md: 4 }}
          ml={{ base: '0', md: '60px' }}>
          <AlertIcon />
          <Box>
            <AlertTitle>Versione di prova</AlertTitle>
          </Box>
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            marginLeft="auto"
            top={-1}
            onClick={onClose}
          />
        </Alert>
      )}

      <Box display={props.display}>
        <aside>
          <header>Visualizza ora il tuo menù</header>
          <main>
            {restaurantId ? (
              <QRCode value={`https://feedinapp.com/web/${restaurantId}`} renderAs="image" size={160} />
            ) : (
              <Spinner size="xl" />
            )}
            <Flex mt={6}>
              <Box
                padding={2}
                paddingLeft={4}
                paddingRight={4}
                border="1px solid rgba(0,0,0, 0.8)"
                borderColor="gray.500"
                borderRadius="3xl"
                backgroundColor="gray.100"
                cursor="pointer"
                fontSize="xs"
                ml={{ base: '0', sm: '2' }}
                onClick={() => {
                  displaySuccessToast();
                  navigator.clipboard.writeText(`https://feedinapp.com/web/${props.restaurant.restaurantKey}`);
                }}>
                Copia link
              </Box>
              <Box
                padding={2}
                paddingLeft={4}
                paddingRight={4}
                border="1px solid rgba(0,0,0, 0.8)"
                borderColor="gray.500"
                borderRadius="3xl"
                backgroundColor="gray.100"
                cursor="pointer"
                fontSize="xs"
                ml={{ base: '0', sm: '2' }}
                onClick={() => {
                  printQrCode(props.restaurant);
                }}>
                Stampa QR code
              </Box>
            </Flex>
          </main>
        </aside>
      </Box>
      <Box display={props.display}>
        <Accordion
          marginLeft="60px"
          boxShadow="0 1px 2px hsl(0deg 0% 0% / 5%), 0 1px 4px hsl(0deg 0% 0% / 5%), 0 2px 8px hsl(0deg 0% 0% / 5%)"
          allowMultiple>
          <AccordionItem as="main" width="300px" flexDirection="column">
            <h2>
              <AccordionButton as="header" width="300px">
                <Box flex="1" textAlign="left" fontSize="12px" fontWeight="bold" color="#3d4247" padding="6px 2px">
                  Stampa il menù
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4} border="1px solid #ffe79e" width="300px" display="flex" flexDirection="column">
              <Checkbox
                label="Stampa solo alcune categorie"
                mt={2}
                fontSize="14px"
                width="100%"
                fontWeight="500"
                onChange={() => setPrintAllCategories((old) => !old)}
                isChecked={printAllCategories}
              />
              <Checkbox
                label="Stampa anche piatti non disponibili"
                mt={2}
                fontSize="14px"
                width="100%"
                fontWeight="500"
                onChange={() => setPrintUnavailable((old) => !old)}
                isChecked={printUnavailable}
              />
              <Flex>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    colorScheme="linkedin"
                    _focus={{ outline: 'none' }}
                    onClick={() => {}}
                    alignSelf="flex-end"
                    mt={6}
                    mr={2}
                    type="submit"
                    isDisabled={!printAllCategories}>
                    Categorie
                  </MenuButton>
                  <MenuList minWidth="240px" maxHeight="250px" overflowY="scroll" className={scrollbar}>
                    <MenuOptionGroup
                      title="Seleziona categorie"
                      type="checkbox"
                      value={Object.keys(selectedCategories).filter((cat) => selectedCategories[cat] === true)}
                      onChange={(selectedCat) => {
                        const newSelectedCats = {};
                        Object.keys(selectedCategories).forEach((catKey) => (newSelectedCats[catKey] = false));
                        selectedCat.forEach((catKey) => (newSelectedCats[catKey] = true));
                        setSelectedCategories(newSelectedCats);
                      }}>
                      {props.categories.map((category) => (
                        <MenuItemOption
                          key={category.categoryKey}
                          value={category.categoryKey}
                          onClick={() => {
                            selectedCategories[category.categoryKey] = !selectedCategories[category.categoryKey];
                          }}>
                          {category.name}
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>

                <Button
                  colorScheme="green"
                  background="#4bac61"
                  _focus={{ outline: 'none' }}
                  onClick={() =>
                    savePdf(
                      props.restaurant,
                      props.categories,
                      props.dishes,
                      printUnavailable,
                      !printAllCategories,
                      Object.keys(selectedCategories).filter((cat) => selectedCategories[cat] === true),
                    )
                  }
                  alignSelf="flex-end"
                  mt={6}
                  type="submit">
                  <Text pl={1} pr={1}>
                    Stampa menù
                  </Text>
                </Button>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        {/* <aside style={{ marginTop: 0 }}>
          <header>Visualizza ora il tuo menù</header>
          <main>
            {restaurantId ? (
              <QRCode
                value={"https://feedinapp.com/web/" + restaurantId}
                renderAs="svg"
                size={160}
              />
            ) : (
              <Spinner size="xl" />
            )}
          </main>
        </aside> */}
      </Box>
    </Flex>
  );
}

export default connect(mapStateToProps)(Aside);
