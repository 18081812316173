/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Flex,
  Box,
  Image,
  Icon,
  Accordion,
  Divider,
  Skeleton,
  Button,
  Tooltip,
  IconButton,
  Input,
  Text,
  useToast,
  CloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useDisclosure,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import { useParams, withRouter } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { VscSettingsGear } from 'react-icons/vsc';
import { IoLanguage } from 'react-icons/io5';
import { FaEye, FaEyeSlash, FaTrash, FaCheck, FaImage } from 'react-icons/fa';
import InputNumber from "rc-input-number";

import uuid from 'react-uuid';
import { loadDishes } from '../../../Redux/Actions/Actions';
import firebase, { database } from '../../../utils/firebase';
import { blankLangSheet } from './utils/SupportedLangs';
import InternationalEditor from './InternationalEditor';

import Dish from './Dish';
import Editor from './Editor';
import ImageLoader from './ImageLoader';

import bgPlaceholder from '../../../assets/bg-placeholder.jpg';
import avatarPlaceholder from '../../../assets/avatar_placeholder.png';
import { putNewDish } from '../../../API/dishes';
import { deleteCategory, updateCategory } from '../../../API/categories';
import DeleteDialog from './DeleteDialog';
import LoadingToast from './utils/LoadingToast';

/**
 *
 * @param {*} props
 * @todo: Improve performance for inline editors. Understand why their performance is not
 * good. It has nothing to deal with the "Editor" component itself since it has been tested
 * in a sandbox and working properly.
 */

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
  categories: state.categories,
  dishes: state.dishes,
  user: state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  updateDishes: (dishes) => dispatch(loadDishes(dishes)),
  uploadNewDish: (dish, categoryID) => dispatch(putNewDish(dish, categoryID)),
  deleteCategory: (categoryID) => dispatch(deleteCategory(categoryID)),
  updateCategory: (newCategory, newImage) => dispatch(updateCategory(newCategory, newImage)),
});

function Category({
  categories,
  dishes,
  updateDishes,
  uploadNewDish,
  deleteCategory,
  updateCategory,
  user,
  restaurant,
  history,
}) {
  const [loading, setLoading] = useState(true);
  const [thisCategory, setThisCategory] = useState(null);
  const [isI18N, setI18n] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [categoryImage, setCategoryImage] = useState(null);
  const [categoryImagePreview, setCategoryImagePreview] = useState(null);
  const [loadingToastTxt, setLoadingToastTxt] = useState('');

  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [i18nCategoryName, setI18nCategoryName] = useState({
    ...blankLangSheet,
  });
  const [i18nCategoryDesc, setI18nCategoryDesc] = useState({
    ...blankLangSheet,
  });

  const [newDishError, setNewDishError] = useState(false);
  const [dishPrice, setDishPrice] = useState(0.0)

  const toggleVisibility = () => {
    setVisible((prevVisible) => !prevVisible);
  };

  const newDishNameRef = useRef();
  const newDishDescRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const thisToast = useRef();
  const toastID = 'delete-toast';

  const onLoadingToastClose = () => {
    if (thisToast.current) toast.close(thisToast.current);
  };

  const addLoadingToast = () => {
    if (!toast.isActive(toastID))
      thisToast.current = toast({
        id: toastID,
        position: 'bottom-left',
        render: () => (
          <LoadingToast
            title={loadingToastTxt}
            content="Attendi il completamento dell'operazione, sarai notificato appena conclusa."
            accent
            onClose={() => onLoadingToastClose()}
          />
        ),
      });
  };

  const { categoryID } = useParams();

  useEffect(() => {
    if (!!thisCategory) setVisible(thisCategory.visible);
  }, [thisCategory]);

  useEffect(() => {
    if (categories && categoryID) {
      const cat = categories.filter((category) => category.categoryKey === categoryID)[0];
      if (cat) {
        setName(cat.name || '');
        setCategoryImagePreview(cat.image || bgPlaceholder);
        setI18nCategoryName((prevI18n) => ({
          ...prevI18n,
          IT: cat.name || '',
          GB: cat.nameEN || '',
        }));
        setI18nCategoryDesc((prevI18n) => ({
          ...prevI18n,
          IT: cat.description || '',
          GB: cat.descriptionEN || '',
        }));
        setDescription(cat.description || '');
        setThisCategory(cat);
      }
    }
  }, [categories, categoryID]);

  useEffect(() => {
    if (categories && categories.length > 0 && dishes && Object.keys(dishes).length > 0) {
      setLoading(false);
    }
  }, [categories, dishes]);

  const onDescriptionChange = useCallback(
    (newDescription) => {
      setDescription(newDescription);
    },
    [setDescription],
  );

  const onNameChange = useCallback(
    (newName) => {
      setName(newName);
    },
    [setName],
  );

  const handleDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    const newDishesArray = Array.from(dishes[categoryID]);

    newDishesArray.splice(source.index, 1);
    newDishesArray.splice(destination.index, 0, ...dishes[categoryID].filter((dish) => dish.itemKey === draggableId));

    newDishesArray.forEach((dish, idx) => (dish.pos = idx));
    newDishesArray.forEach((dish, idx) => {
      if (dish.pos !== dishes[categoryID][idx].pos) {
        database.ref(`menu/${restaurant.restaurantKey}/items`).child(dish.itemKey).update({
          pos: dish.pos,
        });
      }
    });

    const updatedDishes = { ...dishes };
    updatedDishes[categoryID] = newDishesArray;

    updateDishes(updatedDishes);
  };

  const handleDeleteCategory = () => {
    setLoadingToastTxt('Elimino categoria e piatti');
    addLoadingToast();
    deleteCategory(thisCategory.categoryKey)
      .then(() => {
        onLoadingToastClose();
        toast({
          title: 'Categoria eliminata con successo.',
          status: 'success',
          duration: 4000,
          isClosable: true,
          position: 'bottom-left',
        });
        history.push('/');
      })
      .catch((err) => {
        console.log(err);
        onLoadingToastClose();
        toast({
          title: 'Impossibile eliminare categoria.',
          message: 'Consulta la console per ulteriori informazioni',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'bottom-left',
        });
      });
  };

  const handleImageDelete = () => {
    firebase
      .storage()
      .refFromURL(thisCategory.image)
      .delete()
      .then(() => {
        database
          .ref(`categories/${restaurant.restaurantKey}/categories`)
          .child(`${thisCategory.categoryKey}/image`)
          .remove()
          .then(() => {
            setThisCategory((prevCategory) => {
              const newCategory = { ...prevCategory };
              delete newCategory.image;
              return newCategory;
            });
            setCategoryImagePreview(null);
            setCategoryImage(null);
          });
      });
  };

  const update = () => {
    setLoadingToastTxt('Aggiorno la categoria');
    addLoadingToast();
    const newCategory = { ...thisCategory };
    Object.assign(newCategory, { nameEN: i18nCategoryName['GB'] });
    Object.assign(newCategory, { descriptionEN: i18nCategoryDesc['GB'] });
    if (name !== thisCategory.name) Object.assign(newCategory, { name });
    if (description !== thisCategory.description) Object.assign(newCategory, { description });
    if (isVisible !== thisCategory.visible) Object.assign(newCategory, { visible: isVisible });

    updateCategory(newCategory, categoryImage)
      .then(() => {
        onLoadingToastClose();
        setThisCategory(newCategory);
        toast({
          title: 'Categoria aggiornata con successo.',
          status: 'success',
          duration: 4000,
          isClosable: true,
          position: 'bottom-left',
        });
      })
      .catch((err) => {
        onLoadingToastClose();
        console.log(err);
        toast({
          title: 'Impossibile aggiornare la categoria.',
          message: 'Consulta la console per ulteriori informazioni',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'bottom-left',
        });
      });
  };

  /**
   * @TODO Update the itemKey when uploading to firebase
   * @TODO What if the user creates a new dish, changes its position and then refreshes the page?
   *       this would create a gap in the positions on the database, causing the new dish's pos to be skipped.
   *       Should be solved if the dish gets immediately created on the db.
   */
  const createNewDish = () => {
    if (newDishNameRef.current.value.length <= 0) setNewDishError(true);
    else {
      uploadNewDish(
        {
          itemKey: uuid(),
          categoryKey: categoryID,
          restaurantKey: restaurant.restaurantKey,
          name: newDishNameRef.current.value,
          description: newDishDescRef.current.value,
          price: dishPrice,
          available: true,
          pos: dishes[categoryID].length,
          allergeni: {
            a1: false,
            a2: false,
            a3: false,
            a4: false,
            a5: false,
            a6: false,
            a7: false,
            a8: false,
            a9: false,
            a10: false,
            a11: false,
            a12: false,
            a13: false,
            a14: false,
          },
        },
        categoryID,
      )
        .then(() => {
          setDishPrice(0)
          toast({
            title: 'Piatto creato con successo.',
            description: 'Clicca sul piatto per modificarlo.',
            status: 'success',
            duration: 4000,
            isClosable: true,
            position: 'bottom-left',
          });
        })
        .catch((err) => {
          console.log(err);
          toast({
            title: 'Impossibile creare o aggiornare piatto.',
            description: 'Apri la console per ulteriori dettagli.',
            status: 'error',
            duration: 4000,
            isClosable: true,
            position: 'bottom-left',
          });
        });

      setNewDishError(false);
    }
  };

  return (
    <>
      {loading && user && Object.keys(user).length > 0 ? (
        <Flex
          width="100%"
          justifyContent="center"
          height={{ base: 'calc(100vh - 84px)', md: 'calc(100vh - 102px)' }}
          mt={-4}>
          <Skeleton flex="1" />
        </Flex>
      ) : (
        <Flex
          width="100%"
          justifyContent="center"
          height={{ base: 'calc(100vh - 84px)', md: 'calc(100vh - 102px)' }}
          mt={-4}>
          <Flex
            width={{ base: '100%', md: '614px' }}
            border="1px solid"
            borderColor="blackAlpha.200"
            boxShadow="sm"
            background="white"
            direction="column"
            padding={0}
            margin={0}
            mt={8}
            position="relative"
            overflowY="scroll"
            className="noScrollbar">
            {thisCategory ? (
              <>
                <ImageLoader
                  key={categoryID}
                  onLoadEnd={(previews, image) => {
                    if (previews && image) {
                      setCategoryImagePreview(previews);
                      setCategoryImage(image);
                    }
                  }}>
                  {(imgs, previews, styles, controls) => (
                    <Box width="100%" position="relative" as="label" htmlFor="rest-home-file-upload">
                      <CloseButton
                        position="absolute"
                        right="0"
                        top="0"
                        size="lg"
                        stroke="white"
                        onClick={() => {
                          handleImageDelete();
                        }}
                      />
                      <Image
                        objectFit="cover"
                        src={categoryImagePreview || bgPlaceholder}
                        height={{ base: '150px', md: '250px' }}
                        width="100%"
                      />
                      <Box
                        border="1px solid"
                        borderColor="blackAlpha.300"
                        background="gray.100"
                        borderRadius="100%"
                        position="absolute"
                        p={4}
                        top="100%"
                        transform={{ base: 'translateY(-75%)', md: 'translateY(-50%)' }}
                        right="7%"
                        cursor="pointer"
                        transition="all 0.3s"
                        zIndex="2"
                        _hover={{ background: '#edf2f7' }}
                        _focus={{ background: '#e2e8f0' }}
                        as="label"
                        display="flex"
                        htmlFor={`${thisCategory.name}-file-upload`}>
                        <Icon as={FaImage} w={6} h={6} color="gray.700" />
                      </Box>
                      <input
                        id={`${thisCategory.name}-file-upload`}
                        type="file"
                        accept="image/png, image/jpeg, image/webp, image/pdf"
                        onChange={controls}
                        style={styles}
                      />
                    </Box>
                  )}
                </ImageLoader>
                <Flex>
                  <Flex
                    mt={6}
                    ml={12}
                    border="1px solid"
                    borderColor="gray.200"
                    position="relative"
                    height="40px"
                    borderRadius="lg"
                    width="75%"
                    pr={10}>
                    <Icon
                      as={VscSettingsGear}
                      w={10}
                      h={10}
                      border="1px solid"
                      borderColor="gray.300"
                      borderRadius="50%"
                      padding={3}
                      backgroundColor="whitesmoke"
                      position="absolute"
                      left="0"
                      transform="translateX(-50%)"
                    />

                    <Tooltip label="Traduci" position="bottom" hasArrow>
                      <IconButton
                        ml={9}
                        icon={<IoLanguage size="20px" />}
                        height="calc(100% + 2px)"
                        variant="outline"
                        borderLeft="0px"
                        borderRadius="0"
                        color={isI18N && '#1967d2'}
                        marginTop="-1px"
                        onClick={() => setI18n((i18n) => !i18n)}
                      />
                    </Tooltip>
                    <Tooltip label={isVisible ? 'Visibile' : 'Non visibile'} position="bottom" hasArrow>
                      <IconButton
                        icon={isVisible ? <FaEye /> : <FaEyeSlash />}
                        height="calc(100% + 2px)"
                        variant="outline"
                        borderLeft="0px"
                        borderRadius="0"
                        marginTop="-1px"
                        onClick={() => toggleVisibility()}
                      />
                    </Tooltip>
                    <Tooltip label="Elimina la categoria" position="bottom" hasArrow>
                      <IconButton
                        icon={<FaTrash />}
                        height="calc(100% + 2px)"
                        variant="outline"
                        borderLeft="0px"
                        borderRadius="0"
                        marginTop="-1px"
                        onClick={() => {
                          setDeleteDialogOpen(true);
                        }}
                      />
                    </Tooltip>
                  </Flex>
                </Flex>

                <Flex width="100%" direction="column" justifyContent="flex-start" p="1.125rem">
                  {isI18N ? (
                    <InternationalEditor
                      label="Nome"
                      value={i18nCategoryName}
                      onChange={(newNames) => {
                        setI18nCategoryName(newNames);
                        setName(newNames['IT'] || '');
                      }}
                      placeholder="Nome della categoria..."
                      mb={2}
                      color="#21588C"
                      toUpper={false}
                      spacingMode={false}
                      verboseLang={false}
                    />
                  ) : (
                    <Editor
                      label="Nome"
                      value={name}
                      onChange={(newName) => {
                        onNameChange(newName);
                        setI18nCategoryName((prev) => ({
                          ...prev,
                          IT: newName,
                        }));
                      }}
                      placeholder="Nome della categoria..."
                      mb={2}
                      color="#21588C"
                      toUpper={false}
                      spacingMode={false}
                    />
                  )}

                  {isI18N ? (
                    <InternationalEditor
                      label="Descrizione"
                      value={i18nCategoryDesc}
                      onChange={(newDesc) => {
                        setI18nCategoryDesc(newDesc);
                        setDescription(newDesc['IT'] || '');
                      }}
                      placeholder="Descrizione categoria..."
                      color="#21588C"
                      toUpper={false}
                      spacingMode={false}
                      verboseLang={false}
                    />
                  ) : (
                    <Editor
                      label="Descrizione"
                      value={description}
                      onChange={(newDescription) => {
                        onDescriptionChange(newDescription);
                        setI18nCategoryDesc((prev) => ({
                          ...prev,
                          IT: newDescription,
                        }));
                      }}
                      placeholder="Descrizione categoria..."
                      color="#21588C"
                      toUpper={false}
                      spacingMode={false}
                    />
                  )}
                </Flex>

                <Flex mr={4} mb={2}>
                  <Flex ml="auto" position="relative" height="40px" borderRadius="lg">
                    <Button
                      leftIcon={<FaCheck />}
                      fontSize={{ base: 'sm', md: 'initial' }}
                      size="xs"
                      backgroundColor="primary"
                      color="whitesmoke"
                      height="calc(100% + 2px)"
                      variant="solid"
                      borderRadius="md"
                      marginTop="-1px"
                      colorScheme="green"
                      _focus={{ outline: 'none' }}
                      flex={1}
                      onClick={() => update()}>
                      Salva
                    </Button>
                  </Flex>
                </Flex>

                <Flex>
                  <Divider alignSelf="stretch" mb={3} ml={4} mr={4} backgroundColor="gray.400" />
                </Flex>

                <Flex>
                  <Button
                    leftIcon={<AddIcon />}
                    isFullWidth
                    variant="outline"
                    mr={4}
                    ml={4}
                    borderRight="0px"
                    borderRadius={0}
                    colorScheme="gray"
                    borderBottom="0px"
                    p="4px"
                    zIndex="1"
                    onClick={onOpen}>
                    Aggiungi piatto
                  </Button>
                </Flex>

                {dishes[categoryID] && (
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="2">
                      {(provided) => (
                        <Accordion
                          pl={4}
                          pr={4}
                          allowToggle
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          pb={4}>
                          {dishes[categoryID].map((dish, idx) => (
                            <React.Fragment key={dish.itemKey}>
                              <Dish
                                key={dish.itemKey}
                                name={dish.name}
                                description={dish.description}
                                avatar={dish.image || avatarPlaceholder}
                                dishID={dish.itemKey}
                                index={idx}
                                categoryID={categoryID}
                                isVisible={dish.available}
                              />
                            </React.Fragment>
                          ))}
                          {provided.placeholder}
                        </Accordion>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
              </>
            ) : (
              <Flex
                width="100%"
                justifyContent="center"
                height={{ base: 'calc(100vh - 84px)', md: 'calc(100vh - 102px)' }}
                mt={-4}>
                <Skeleton flex="1" />
              </Flex>
            )}
          </Flex>
        </Flex>
      )}

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent as="div">
          <ModalHeader as="div">Aggiungi un piatto</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text fontSize="sm" color="#49576E" mb={4} lineHeight="normal">
              Potrai modificare queste informazioni ed altre ancora in un secondo momento.
            </Text>
            <FormControl isRequired>
              <FormLabel mb={newDishError ? 0 : 2}>Nome</FormLabel>
              {newDishError && (
                <Text color="#AD1A00" fontSize="xs" mb={2}>
                  Il nome del piatto non può essere vuoto!
                </Text>
              )}
              <Input ref={newDishNameRef} placeholder="Nome piatto" isRequired />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Descrizione</FormLabel>
              <Input ref={newDishDescRef} placeholder="Descrizione piatto" />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Prezzo</FormLabel>
              <Flex alignItems="center">
                <InputNumber
                  step="1"
                  defaultValue="0"
                  min="-1"
                  value={dishPrice}
                  onChange={setDishPrice}
                  required
                  style={{
                    height: '48px',
                    fontSize: '20px',
                    fontWeight: 'normal',
                  }}
                />
                <Text fontWeight="medium" color="#21588C" letterSpacing="0.1rem" ml={2}>
                  {'\u20AC'}
                </Text>
              </Flex>
            </FormControl>

          </ModalBody>

          <ModalFooter>
            <Button
              backgroundColor="#2B5F38"
              color="white"
              mr={3}
              onClick={() => {
                createNewDish();
                if (newDishNameRef.current.value.length > 0) onClose();
              }}
              _hover={{ background: '#21452A' }}
              _activeLink={{ background: '#21452A' }}
              _active={{ background: '#21452A' }}
              _focus={{ background: '#21452A' }}>
              Aggiungi
            </Button>
            <Button
              onClick={() => {
                setNewDishError(false);
                onClose();
              }}>
              Annulla
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DeleteDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
        onConfirm={() => handleDeleteCategory()}
        title="Elimina categoria"
        message="Questa azione non potrà essere annullata. Tutti i piatti
          presenti verranno anch'essi eliminati. Desideri procedere?"
      />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Category));
