import React from 'react';
import { useBreakpointValue, Avatar, Box, Flex, Heading, Text } from '@chakra-ui/react';
import { connect } from 'react-redux';
import AvatarPlaceholder from '../../../assets/avatar_placeholder.png';

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
});

function ProfileInfo({ restaurant }) {
  const avatar = useBreakpointValue({
    base: (
      <Avatar
        src={!!restaurant && !!restaurant.icon ? restaurant.icon : AvatarPlaceholder}
        size="xl"
        position="relative"
      />
    ),
    lg: (
      <Avatar
        src={!!restaurant && !!restaurant.icon ? restaurant.icon : AvatarPlaceholder}
        size="2xl"
        position="relative"
      />
    ),
  });

  /* const userClaim = "webmaster";
    const webmasterBadge = <Badge color="#800080" bg="#ffb3ff" alignSelf="center" mb={2}>Webmaster</Badge>;
    const freeUserBadge = <Badge colorScheme="linkedin" alignSelf="center" mb={2}>Free trial</Badge>;
    const premiumUserBadge = <Badge colorScheme="green" alignSelf="center" mb={2}>Premium</Badge>; */

  return (
    <Flex direction="column" alignItems="center" width={{ base: '100%', lg: '60%' }} pt={6} pl={6}>
      <Heading as="h4" size="md" color="blackAlpha.700" width="100%">
        Profile Info
      </Heading>
      <Box border="6px solid" borderColor="white" borderRadius="100%" boxShadow="0 0 0 1px rgba(0,0,0,.2)" mb={8}>
        {avatar}
      </Box>

      <Flex direction="column" width="100%">
        {/* { userClaim === "webmaster" ? webmasterBadge : userClaim === "premium" ? premiumUserBadge : freeUserBadge } */}
        <Flex
          width="100%"
          p={2}
          pb={{ base: 2, lg: 2 }}
          pt={{ base: 2, lg: 2 }}
          direction={{ base: 'column', lg: 'row' }}>
          <Flex width="100%" direction="column" mb={{ base: 1, lg: 0 }}>
            <Text color="#3182ce" fontSize="small">
              Restaurant Name:
            </Text>
            <Text color="gray.700" fontSize="sm">
              {restaurant.name}
            </Text>
          </Flex>

          <Flex width="100%" direction="column">
            <Text color="#3182ce" fontSize="small">
              Restaurant Email:
            </Text>
            <Text color="gray.700" fontSize="sm">
              {restaurant.email || '-'}
            </Text>
          </Flex>
        </Flex>

        <Flex
          width="100%"
          p={2}
          pb={{ base: 1, lg: 1 }}
          pt={{ base: 1, lg: 1 }}
          direction={{ base: 'column', lg: 'row' }}>
          <Flex width="100%" direction="column" mb={{ base: 1, lg: 0 }}>
            <Text color="#3182ce" fontSize="small">
              Place:
            </Text>
            <Text color="gray.700" fontSize="sm">
              {!!restaurant && !!restaurant.address && !!restaurant.address.city ? restaurant.address.city : '-'}
            </Text>
          </Flex>

          <Flex width="100%" direction="column" mb={{ base: 1, lg: 0 }}>
            <Text color="#3182ce" fontSize="small">
              District:
            </Text>
            <Text color="gray.700" fontSize="sm">
              {!!restaurant && !!restaurant.address && !!restaurant.address.province
                ? restaurant.address.province
                : '-'}
            </Text>
          </Flex>
        </Flex>

        <Flex
          width="100%"
          p={2}
          mb={2}
          pb={{ base: 2, lg: 2 }}
          pt={{ base: 2, lg: 2 }}
          direction={{ base: 'column', lg: 'row' }}>
          <Flex width="100%" direction="column">
            <Text color="#3182ce" fontSize="small">
              Street Name:
            </Text>
            <Text color="gray.700" fontSize="sm">
              {!!restaurant && !!restaurant.address && !!restaurant.address.street ? restaurant.address.street : '-'}
            </Text>
          </Flex>

          <Flex width="100%" direction="column">
            <Text color="#3182ce" fontSize="small">
              Street Number:
            </Text>
            <Text color="gray.700" fontSize="sm">
              {!!restaurant && !!restaurant.address && !!restaurant.address.streetNumber
                ? restaurant.address.streetNumber
                : '-'}
            </Text>
          </Flex>
        </Flex>

        <Flex direction="column" width="100%" p={2} mt={{ base: 0, lg: 2 }}>
          <Flex width="100%" direction="column" pt={1} pb={1}>
            <Text color="#3182ce" fontSize="small" mb={{ base: 1, lg: 0 }}>
              Website:
            </Text>
            <Text color="gray.700" fontSize="sm" maxWidth="98%">
              <a href={!!restaurant && !!restaurant.website ? restaurant.website : '#'}>
                {!!restaurant && !!restaurant.website ? restaurant.website : '-'}
              </a>
            </Text>
          </Flex>

          <Flex width="100%" direction="column" pt={1} pb={1}>
            <Text color="#3182ce" fontSize="small" mb={{ base: 1, lg: 0 }}>
              Facebook:
            </Text>
            <Text color="gray.700" fontSize="sm" maxWidth="98%">
              <a href={!!restaurant && !!restaurant.facebook ? restaurant.facebook : '#'}>
                {!!restaurant && !!restaurant.facebook ? restaurant.facebook : '-'}
              </a>
            </Text>
          </Flex>

          <Flex width="100%" direction="column" pt={1} pb={1}>
            <Text color="#3182ce" fontSize="small" mb={{ base: 1, lg: 0 }}>
              Instagram:
            </Text>
            <Text color="gray.700" fontSize="sm" maxWidth="98%">
              <a href={!!restaurant && !!restaurant.instagram ? restaurant.instagram : '#'}>
                {!!restaurant && !!restaurant.instagram ? restaurant.instagram : '-'}
              </a>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default connect(mapStateToProps)(ProfileInfo);

/** <Badge color="#800080" bg="#ffb3ff">Webmaster</Badge> */
