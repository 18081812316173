import 'firebase/database';
import 'firebase/storage';
import { loadDishes } from '../Redux/Actions/Actions';
import { database } from '../utils/firebase';

export const putNewDish = (dish, categoryID) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { dishes } = getState();
    const { restaurantKey } = getState().restaurant;

    database
      .ref(`menu/${restaurantKey}/items`)
      .push(dish)
      .then((res) => {
        database
          .ref(`menu/${restaurantKey}/items`)
          .child(res.key)
          .update({
            itemKey: res.key,
          })
          .then((updatedRes) => {
            const newDishes = {
              ...dishes,
              [categoryID]: [...dishes[categoryID], { ...dish, itemKey: res.key }],
            };

            dispatch(loadDishes(newDishes));
            return resolve(updatedRes);
          })
          .catch((errUpdate) => {
            reject(errUpdate);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
