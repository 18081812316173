/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import {
  Flex,
  Box,
  Image,
  Icon,
  IconButton,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Skeleton,
  Tooltip,
  Text,
  useBreakpointValue,
  CloseButton,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  MenuItemOption,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FaImage, FaTrash, FaLeaf, FaSnowflake, FaCheck } from 'react-icons/fa';
import { LuWheat, LuWheatOff, LuVegan } from "react-icons/lu";

import { IoLanguage } from 'react-icons/io5';
import { MdSwapHoriz } from 'react-icons/md';
import { VscSettingsGear } from 'react-icons/vsc';
import { connect } from 'react-redux';
import { RiVirusFill } from 'react-icons/ri';
import InputNumber from 'rc-input-number';
import { loadDishes } from '../../../Redux/Actions/Actions';
import ImageLoader from './ImageLoader';
import bgPlaceholder from '../../../assets/bg-placeholder.jpg';
import 'rc-input-number/assets/index.css';
import Editor from './Editor';
import InternationalEditor from './InternationalEditor';
import { blankLangSheet } from './utils/SupportedLangs';
import LoadingToast from './utils/LoadingToast';

import './Styles/DishPage.css';
import * as Model from './DishPageModel';

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
  dishes: state.dishes,
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  updateDishes: (dishes) => dispatch(loadDishes(dishes)),
  deleteDish: (dish) => dispatch(Model.handleDishDelete(dish)),
  deleteImage: (dish) => dispatch(Model.deleteImage(dish)),
  saveDish: (d, c, dnEn, ddEn, i) => dispatch(Model.saveDish(d, c, dnEn, ddEn, i)),
});

function DishPage(props) {
  const { categoryID, dishID } = useParams();
  const [thisDish, setThisDish] = useState(null);
  const [theCategory, setCategory] = useState(null);
  const [displayImage, setDisplayImage] = useState(null);
  const [dishName, setDishName] = useState(null);
  const [dishDescription, setDishDescription] = useState(null);
  const [dishPrice, setDishPrice] = useState(-1);
  const scrollbar = useBreakpointValue({
    base: 'noScrollbar',
    md: 'decorated-vScrollbar',
  });
  const [i18nDishName, setI18nDishName] = useState({ ...blankLangSheet });
  const [i18nDishDesc, setI18nDishDesc] = useState({ ...blankLangSheet });
  const [imageData, setImageData] = useState(undefined);
  const [loadingToastTxt, setLoadingToastTxt] = useState('');

  /**
   * 0 --> false
   * 1 --> true
   * 2 --> unset
   */
  const [isVegetarian, setVegetarian] = useState(-1);
  const [isGlutenFree, setGlutenFree] = useState(-1)
  const [isFrozen, setFrozen] = useState(-1);
  const [isVegan, setVegan] = useState(-1)
  const [isI18N, setI18n] = useState(false);

  const toast = useToast();

  const thisToast = useRef();
  const toastID = 'notificationToast';

  const onLoadingToastClose = () => {
    if (thisToast.current) toast.close(thisToast.current);
  };

  const addLoadingToast = () => {
    if (!toast.isActive(toastID))
      thisToast.current = toast({
        id: toastID,
        position: 'bottom-left',
        render: () => (
          <LoadingToast
            title={loadingToastTxt}
            content="Attendi il completamento dell'operazione, sarai notificato appena conclusa."
            accent
            onClose={() => onLoadingToastClose()}
          />
        ),
      });
  };

  /** Dialog stuff */
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();

  const toggle = (fieldName, quantity, updater) => {
    const newQuantity = (quantity + 1) % 3;
    updater(newQuantity);
    setThisDish((prevDish) => ({
      ...prevDish,
      [fieldName]: newQuantity === 0 ? false : newQuantity === 1 ? true : null,
    }));
  };

  useEffect(() => {
    if (thisDish) {
      if (isVegetarian < 0) setVegetarian(thisDish.vegetarian ? 1 : thisDish.vegetarian === null ? 2 : 0);
      if (isGlutenFree < 0) setGlutenFree(thisDish.glutenFree === null ? 2 : thisDish.glutenFree ? 1 : 0)
      if (isFrozen < 0) setFrozen(thisDish.frozen ? 1 : thisDish.frozen === null ? 2 : 0);
      if (isVegan < 0) setVegan(thisDish.vegan == null ? 2 : thisDish.vegan ? 1 : 0)
    }
  }, [thisDish]);

  useEffect(() => {
    if (Object.keys(props.dishes).length > 0 && categoryID && dishID) {
      const tmpDish = props.dishes[categoryID].filter((dish) => dish.itemKey === dishID)[0];

      if (!tmpDish) return;

      setThisDish(tmpDish);
      setDisplayImage(tmpDish.image || bgPlaceholder);
      setDishName(tmpDish.name || '');
      setI18nDishName((prevI18n) => ({
        ...prevI18n,
        IT: tmpDish.name || '',
        GB: tmpDish.nameEN || '',
      }));
      setI18nDishDesc((prevI18n) => ({
        ...prevI18n,
        IT: tmpDish.description || '',
        GB: tmpDish.descriptionEN || '',
      }));
      setDishDescription(tmpDish.description || '');
      setDishPrice(tmpDish.price || 0);
    }

    if (props.categories.length > 0 && categoryID) {
      const tmpCategory = props.categories.filter((category) => category.categoryKey === categoryID)[0];
      setCategory(tmpCategory);
    }
  }, [props.dishes, dishID, categoryID, props.categories]);

  useEffect(() => {
    if (dishName !== undefined && dishName !== null) setThisDish((prevDish) => ({ ...prevDish, name: dishName }));
  }, [dishName]);

  useEffect(() => {
    if (dishDescription !== undefined && dishDescription !== null)
      setThisDish((prevDish) => ({
        ...prevDish,
        description: dishDescription,
      }));
  }, [dishDescription]);

  useEffect(() => {
    if (dishPrice >= 0) setThisDish((prevDish) => ({ ...prevDish, price: dishPrice }));
  }, [dishPrice]);

  useEffect(() => {
    if (loadingToastTxt.length > 0) addLoadingToast();
  }, [loadingToastTxt]);

  const onDeleteImage = () => {
    onLoadingToastClose();
    setLoadingToastTxt("Attendi la cancellazione dell'immagine");
    props
      .deleteImage(thisDish)
      .then(() => {
        setImageData(undefined);
        onLoadingToastClose();
        setTimeout(() => {
          toast({
            title: 'Immagine cancellata con successo.',
            status: 'success',
            duration: 4000,
            isClosable: true,
            position: 'bottom-left',
          });
        }, [500]);
      })
      .catch(() => {
        if (!!imageData) {
          setImageData(undefined);
          setDisplayImage(bgPlaceholder);
          onLoadingToastClose();
          setTimeout(() => {
            toast({
              title: "Impossibile cancellare l'immagine.",
              message: 'Controlla la console per ulteriori informazioni.',
              status: 'error',
              duration: 4000,
              isClosable: true,
              position: 'bottom-left',
            });
          }, [500]);
        }
      });
  };

  const onDeleteDish = () => {
    onLoadingToastClose();
    setLoadingToastTxt('Attendi la cancellazione del piatto.');
    onClose();
    props
      .deleteDish(thisDish)
      .then(() => {
        onLoadingToastClose();
        setTimeout(() => {
          onLoadingToastClose();
          toast({
            title: 'Piatto cancellato con successo.',
            status: 'success',
            duration: 4000,
            isClosable: true,
            position: 'bottom-left',
          });
          setTimeout(() => {
            props.history.goBack();
          }, [200]);
        }, [500]);
      })
      .catch((err) => {
        onLoadingToastClose();
        console.log(err);
        setTimeout(() => {
          toast({
            title: 'Impossibile cancellare il piatto.',
            message: 'Controlla la console per ulteriori informazioni.',
            status: 'error',
            duration: 4000,
            isClosable: true,
            position: 'bottom-left',
          });
        }, [500]);
      });
  };

  const onSaveDish = () => {
    onLoadingToastClose();
    setLoadingToastTxt('Attendere il salvataggio delle modifiche');
    props
      .saveDish(thisDish, theCategory, i18nDishName['GB'], i18nDishDesc['GB'], imageData)
      .then((res) => {
        console.log(res);
        onLoadingToastClose();
        setTimeout(() => {
          toast({
            title: 'Piatto modificato con successo.',
            status: 'success',
            duration: 4000,
            isClosable: true,
            position: 'bottom-left',
          });
        }, [500]);
      })
      .catch((err) => {
        onLoadingToastClose();
        console.log(err);
        setTimeout(() => {
          toast({
            title: 'Impossibile modificare il piatto.',
            message: 'Controlla la console per ulteriori informazioni.',
            status: 'error',
            duration: 4000,
            isClosable: true,
            position: 'bottom-left',
          });
        }, [500]);
      });
  };
  return (
    <>
      <Flex width="100%" justifyContent="center" height="calc(100vh - 102px)" mt={-4}>
        <Flex
          width={{ base: '100%', md: '614px' }}
          border="1px solid"
          borderColor="blackAlpha.200"
          boxShadow="sm"
          background="white"
          direction="column"
          padding={0}
          margin={0}
          mt={8}
          position="relative"
          overflowY="scroll"
          className="noScrollbar">
          {categoryID && dishID ? (
            <>
              <ImageLoader
                key={categoryID}
                onLoadEnd={(previews, image) => {
                  if (!previews[0]) return;

                  if (image) {
                    setImageData(image);
                  }

                  const newDishes = props.dishes;
                  newDishes[categoryID].forEach((dish) => {
                    if (dish.itemKey === dishID) Object.assign(dish, { image: previews });
                  });
                  props.updateDishes(newDishes);
                  setDisplayImage(previews);
                }}>
                {(imgs, previews, styles, controls) => (
                  <Box width="100%" position="relative" as="label" htmlFor="rest-home-file-upload">
                    <CloseButton
                      position="absolute"
                      right="0"
                      top="0"
                      size="lg"
                      stroke="white"
                      onClick={() => onDeleteImage()}
                    />
                    <Skeleton isLoaded={!!displayImage}>
                      <Image objectFit="cover" src={displayImage} height="250px" width="100%" />
                    </Skeleton>
                    <Box
                      border="1px solid"
                      borderColor="blackAlpha.300"
                      background="gray.100"
                      borderRadius="100%"
                      position="absolute"
                      p={6}
                      top="100%"
                      display="flex"
                      transform="translateY(-75%)"
                      right="7%"
                      cursor="pointer"
                      transition="all 0.3s"
                      zIndex="2"
                      _hover={{ background: '#edf2f7' }}
                      _focus={{ background: '#e2e8f0' }}
                      as="label"
                      htmlFor={`${dishID}-file-upload`}>
                      <Icon as={FaImage} w={10} h={10} color="gray.700" />
                    </Box>
                    <input
                      id={`${dishID}-file-upload`}
                      type="file"
                      accept="image/png, image/jpeg, image/webp"
                      onChange={controls}
                      style={styles}
                    />
                  </Box>
                )}
              </ImageLoader>
              <Flex width="100%" direction="column" pl={{ md: 4 }} pr={6} height="100%">
                <Skeleton isLoaded={!!theCategory} mt={{ base: 2, md: 6 }} width="50%" ml={4} mb={4}>
                  <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />} ml={4} mt={2}>
                    <BreadcrumbItem>
                      <BreadcrumbLink href="/">Home</BreadcrumbLink>
                    </BreadcrumbItem>
                    {theCategory && (
                      <BreadcrumbItem>
                        <BreadcrumbLink
                          href="#"
                          onClick={() => {
                            props.history.goBack();
                          }}>
                          {theCategory && theCategory.name}
                        </BreadcrumbLink>
                      </BreadcrumbItem>
                    )}
                  </Breadcrumb>
                </Skeleton>

                <Flex
                  mt={3}
                  ml={12}
                  border="1px solid"
                  borderColor="gray.200"
                  position="relative"
                  height="48px"
                  borderRadius="lg"
                  width="75%"
                  pr={10}>
                  <Icon
                    as={VscSettingsGear}
                    w={12}
                    h={12}
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="50%"
                    padding={3}
                    backgroundColor="whitesmoke"
                    position="absolute"
                    left="0"
                    transform="translateX(-50%)"
                  />

                  <Tooltip
                    label={isVegetarian === 0 ? 'Non vegetariano' : isVegetarian === 1 ? 'Vegetariano' : 'Vegetariano?'}
                    position="bottom"
                    hasArrow>
                    <IconButton
                      icon={<FaLeaf />}
                      ml={9}
                      height="calc(100% + 2px)"
                      variant="outline"
                      borderLeft="0px"
                      borderRadius="0"
                      marginTop="-1px"
                      onClick={() => {
                        toggle('vegetarian', isVegetarian, setVegetarian);
                      }}
                      color={isVegetarian === 0 ? '#b2d8d8' : isVegetarian === 1 ? 'teal' : 'black'}
                      _focus={{ outline: 'none' }}
                      aria-label="Tell us if this dish is vegetarian"
                    />
                  </Tooltip>

                  <Tooltip
                    label={isVegan === 0 ? 'Non vegano' : isVegan === 1 ? 'Vegano' : 'Vegano?'}
                    position="bottom"
                    hasArrow>
                    <IconButton
                      icon={<LuVegan />}
                      height="calc(100% + 2px)"
                      variant="outline"
                      borderLeft="0px"
                      borderRadius="0"
                      marginTop="-1px"
                      onClick={() => {
                        toggle('vegan', isVegan, setVegan);
                      }}
                      color={isVegan === 0 ? '#FBCEB1' : isVegan === 1 ? '#E97451' : 'black'}
                      _focus={{ outline: 'none' }}
                      aria-label="Tell us if this dish is vegan"
                    />
                  </Tooltip>

                  <Tooltip
                    label={isGlutenFree === 0 ? 'Contiene glutine' : isGlutenFree === 1 ? 'Senza glutine' : 'Glutine?'}
                    position="bottom"
                    hasArrow>
                    <IconButton
                      icon={isGlutenFree === 0 ? <LuWheat /> : isGlutenFree === 1 ? <LuWheatOff /> : <LuWheat />}
                      height="calc(100% + 2px)"
                      variant="outline"
                      borderLeft="0px"
                      borderRadius="0"
                      marginTop="-1px"
                      onClick={() => {
                        toggle('glutenFree', isGlutenFree, setGlutenFree);
                      }}
                      color={isGlutenFree === 0 ? '#ffd8e2' : isGlutenFree === 1 ? '#ff628b' : 'black'}
                      _focus={{ outline: 'none' }}
                      aria-label="Tell us if this dish is gluten free"
                    />
                  </Tooltip>
                  
                  <Tooltip
                    label={isFrozen === 0 ? 'Non surgelato' : isFrozen === 1 ? 'Surgelato' : 'Surgelato?'}
                    position="bottom"
                    hasArrow>
                    <IconButton
                      icon={<FaSnowflake />}
                      height="calc(100% + 2px)"
                      variant="outline"
                      borderLeft="0px"
                      borderRadius="0"
                      marginTop="-1px"
                      color={isFrozen === 0 ? '#98c0e6' : isFrozen === 1 ? '#225b90' : 'black'}
                      onClick={() => {
                        toggle('frozen', isFrozen, setFrozen);
                      }}
                      _focus={{ outline: 'none' }}
                      aria-label="Tell us if this dish is frozen"
                    />
                  </Tooltip>
                  {
                    // @TODO: chevron down in basso a destra per segnalare che è un menu
                  }
                  <Box position="relative">
                    <Menu closeOnSelect={false}>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<RiVirusFill />}
                        color={
                          thisDish &&
                          Object.values(thisDish.allergeni).reduce((previous, next) => previous || next) &&
                          '#d8412c'
                        }
                        variant="outline"
                        height="46px"
                        position="relative"
                        borderTop="0px"
                        borderBottom="0px"
                        borderLeft="0px"
                        borderRadius="0"
                        marginTop="-1px"
                        zIndex={1}
                        /* rightIcon={<ChevronDownIcon />} */
                      />
                      <MenuList minWidth="240px" maxHeight="250px" overflowY="scroll" className={scrollbar}>
                        {thisDish && thisDish.allergeni && (
                          <MenuOptionGroup
                            title="Allergeni"
                            type="checkbox"
                            value={Object.keys(thisDish.allergeni).filter(
                              (allergen) => thisDish.allergeni[allergen] === true,
                            )}
                            onChange={(values) => {
                              const thisNewDish = { ...thisDish };
                              Object.keys(thisNewDish.allergeni).forEach(
                                (allergen) => (thisNewDish.allergeni[allergen] = false),
                              );
                              values.forEach((value) => (thisNewDish.allergeni[value] = true));
                              setThisDish(thisNewDish);
                            }}>
                            {[
                              'Glutine',
                              'Crostacei e derivati',
                              'Uova e derivati',
                              'Pesce e derivati',
                              'Arachidi e derivati',
                              'Soia e derivati',
                              'Latte e derivati',
                              'Frutta a guscio e derivati',
                              'Sedano',
                              'Senape e derivati',
                              'Semi di sesamo e derivati',
                              'Anidride solforosa e solfiti',
                              'Lupino e derivati',
                              'Molluschi e derivati',
                            ].map((allergene, i) => (
                              <MenuItemOption key={allergene} value={`a${i + 1}`} title={allergene}>
                                {allergene}
                              </MenuItemOption>
                            ))}
                          </MenuOptionGroup>
                        )}
                      </MenuList>
                    </Menu>
                    <Icon as={ChevronDownIcon} position="absolute" bottom="0" right="0" zIndex="0" />
                  </Box>

                  {/*  <Tooltip label="Visibile" position="bottom" hasArrow>
                    <IconButton
                      icon={<FaEye />}
                      height="calc(100% + 2px)"
                      variant="outline"
                      borderLeft="0px"
                      borderRadius="0"
                      marginTop="-1px"
                    />
                  </Tooltip> */}
                  <Tooltip label="Traduci" position="bottom" hasArrow>
                    <IconButton
                      icon={<IoLanguage size="20px" />}
                      height="calc(100% + 2px)"
                      variant="outline"
                      borderLeft="0px"
                      borderRadius="0"
                      color={isI18N && '#1967d2'}
                      marginTop="-1px"
                      onClick={() => setI18n((i18n) => !i18n)}
                    />
                  </Tooltip>
                </Flex>

                <Skeleton isLoaded={dishName !== null} ml={8} mt={3}>
                  {isI18N ? (
                    <InternationalEditor
                      label="Nome"
                      value={i18nDishName}
                      onChange={(newNames) => {
                        setI18nDishName(newNames);
                        setDishName(newNames['IT'] || '');
                      }}
                      placeholder="Il nome del tuo piatto"
                      mb={3}
                      color="#21588C"
                      toUpper={false}
                      spacingMode={false}
                      verboseLang={false}
                    />
                  ) : (
                    <Editor
                      label="Nome"
                      value={dishName}
                      onChange={(newName) => {
                        setDishName(newName);
                        setI18nDishName((prevName) => ({
                          ...prevName,
                          IT: newName,
                        }));
                      }}
                      placeholder="Il nome del tuo piatto"
                      mb={3}
                      color="#21588C"
                      toUpper={false}
                      spacingMode={false}
                    />
                  )}
                </Skeleton>
                <Skeleton isLoaded={dishDescription !== null} ml={8}>
                  {isI18N ? (
                    <InternationalEditor
                      label="Descrizione"
                      value={i18nDishDesc}
                      onChange={(newDesc) => {
                        setI18nDishDesc(newDesc);
                        setDishDescription(newDesc['IT'] || '');
                      }}
                      placeholder="La descrizione del tuo piatto"
                      mb={3}
                      color="#21588C"
                      toUpper={false}
                      spacingMode={false}
                      verboseLang={false}
                    />
                  ) : (
                    <Editor
                      label="Descrizione"
                      value={dishDescription}
                      onChange={(newDesc) => {
                        setDishDescription(newDesc);
                        setI18nDishDesc((prevDesc) => ({
                          ...prevDesc,
                          IT: newDesc,
                        }));
                      }}
                      placeholder="La descrizione del tuo piatto"
                      mb={3}
                      color="#21588C"
                      toUpper={false}
                      spacingMode={false}
                    />
                  )}
                </Skeleton>
                <Skeleton isLoaded={dishPrice >= 0} ml={8}>
                  <Text fontWeight="medium" color="#21588C" letterSpacing="0.1rem">
                    Prezzo
                  </Text>
                  <Flex alignItems="center">
                    <InputNumber
                      step="1"
                      defaultValue="0"
                      min="-1"
                      value={dishPrice}
                      onChange={setDishPrice}
                      required
                      style={{
                        height: '48px',
                        fontSize: '20px',
                        fontWeight: 'normal',
                      }}
                    />
                    <Text fontWeight="medium" color="#21588C" letterSpacing="0.1rem" ml={2}>
                      {'\u20AC'}
                    </Text>
                  </Flex>
                </Skeleton>

                <Skeleton isLoaded={!!props.categories && theCategory !== null} mt="auto" mb={3}>
                  <Flex
                    ml="auto"
                    border="1px solid"
                    borderColor="gray.200"
                    position="relative"
                    height="48px"
                    borderRadius="lg"
                    width={{ base: 'auto', md: '40%' }}>
                    <Tooltip label="Elimina" position="bottom" hasArrow>
                      <IconButton
                        icon={<FaTrash />}
                        height="calc(100% + 2px)"
                        variant="outline"
                        border="0px"
                        borderRadius="0"
                        marginTop="-1px"
                        flex={1}
                        borderTopLeftRadius="10px"
                        borderBottomLeftRadius="10px"
                        onClick={() => {
                          setIsOpen(true);
                        }}
                      />
                    </Tooltip>
                    <Menu preventOverflow boundary="HTMLElement">
                      <MenuButton
                        as={Button}
                        leftIcon={<MdSwapHoriz />}
                        height="48px"
                        top="-1px"
                        background="white"
                        border="1px solid rgba(127,127,127, 0.2)"
                        borderRadius="0"
                        fontWeight="semibold">
                        Categoria
                      </MenuButton>
                      <MenuList height="150px" overflowY="scroll" className={scrollbar}>
                        {props.categories.map((category) => (
                          <MenuItem
                            key={category.categoryKey}
                            onClick={() => {
                              setCategory(category);
                              /* setThisDish((dish) => {
                              return {
                                ...dish,
                                categoryKey: category.categoryKey,
                              };
                            }); */
                            }}
                            backgroundColor={
                              theCategory && category.categoryKey === theCategory.categoryKey && 'rgba(127,127,127,0.2)'
                            }>
                            {category.name}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                    <Tooltip label="Salva modifiche" position="bottom" hasArrow>
                      <IconButton
                        icon={<FaCheck />}
                        height="calc(100% + 2px)"
                        variant="outline"
                        borderLeft="0px"
                        borderRadius="0"
                        marginTop="-1px"
                        flex={1}
                        borderTopRightRadius="10px"
                        borderBottomRightRadius="10px"
                        onClick={() => onSaveDish()}
                      />
                    </Tooltip>
                  </Flex>
                </Skeleton>
              </Flex>
            </>
          ) : (
            <Flex width="100%" height="100%" alignItems="center" justifyContent="center">
              <Spinner size="xl" />
            </Flex>
          )}
        </Flex>
      </Flex>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} flex={0} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent as="div">
            <AlertDialogHeader fontSize="lg" fontWeight="bold" as="div">
              Elimina Piatto
            </AlertDialogHeader>

            <AlertDialogBody>Sei sicuro? Questa azione non può essere annullata!</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Annulla
              </Button>
              <Button colorScheme="red" onClick={() => onDeleteDish()} ml={3}>
                Elimina
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DishPage));
