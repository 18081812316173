import React, { useState, useEffect } from 'react';
import {
  Flex,
  Icon,
  Text,
  Avatar,
  useBreakpointValue,
  AccordionItem,
  AccordionButton,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { FaUtensils } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { fetchMenu, loadDishes } from '../../../Redux/Actions/Actions';
import 'rc-input-number/assets/index.css';
import './Styles/Dish.css';
import { database } from '../../../utils/firebase';

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
  menu: state.menu,
  dishes: state.dishes,
});

const mapDispatchToProps = (dispatch) => ({
  updateMenu: (menu) => dispatch(fetchMenu(menu)),
  updateDishes: (dishes) => dispatch(loadDishes(dishes)),
});

function Dish(props) {
  const avatar = useBreakpointValue({
    base: (
      <Avatar
        src={props.avatar}
        size="sm"
        alt={props.avatar ? 'Default FeedInApp avatar' : props.description || props.name}
      />
    ),
    lg: <Avatar src={props.avatar} />,
  });

  // const [isVisible, setVisible] = useState(true);
  const [isOrderable, setOrderable] = useState(true);
  const { t } = useTranslation()

  useEffect(() => {
    if (!!props.dishes)
      setOrderable(props.dishes[props.categoryID].find((dish) => dish.itemKey === props.dishID).available);
  }, [props.dishes]);

  /*
    const tableRef = createRef();

     const table = useBreakpointValue(
        {
            base: <Table variant="simple" size="sm">
                <TableCaption>
                    Allergeni
                </TableCaption>
                <Tbody>
                    <Tr>
                        <Td className="standard-initial-padding">
                            <Checkbox label="Cereali" fontSize="small" fontWeight="light"/>
                        </Td>
                        <Td className="standard-initial-padding">
                            <Checkbox label="Crostacei" fontSize="small" fontWeight="light"/>
                        </Td>
                        <Td className="standard-initial-padding">
                            <Checkbox label="Uova" fontSize="small" fontWeight="light"/>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className="standard-initial-padding">
                                <Checkbox label="Pesce" fontSize="small" fontWeight="light"/>
                        </Td>
                        <Td className="standard-initial-padding">
                                <Checkbox label="Arachidi" fontSize="small" fontWeight="light"/>
                        </Td>
                        <Td className="standard-initial-padding">
                                <Checkbox label="Soia" fontSize="small" fontWeight="light"/>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className="standard-initial-padding">
                                <Checkbox label="Latte" fontSize="small" fontWeight="light"/>
                        </Td>
                        <Td className="standard-initial-padding">
                                <Checkbox label="Frutta" fontSize="small" fontWeight="light"/>
                        </Td>
                        <Td className="standard-initial-padding">
                                <Checkbox label="Sedano" fontSize="small" fontWeight="light"/>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className="standard-initial-padding">
                                <Checkbox label="Senape" fontSize="small" fontWeight="light"/>
                        </Td>
                        <Td className="standard-initial-padding">
                                <Checkbox label="Semi di sesamo" fontSize="small" fontWeight="light"/>
                        </Td>
                        <Td className="standard-initial-padding">
                                <Checkbox label="Solfiti" fontSize="small" fontWeight="light"/>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className="standard-initial-padding">
                                <Checkbox label="Molluschi" fontSize="small" fontWeight="light"/>
                        </Td>
                        <Td className="standard-initial-padding">
                                <Checkbox label="Lupini" fontSize="small" fontWeight="light"/>
                        </Td>
                        <Td className="standard-initial-padding"/>
                    </Tr>
                </Tbody>
            </Table>, 

            lg:  <Table variant="simple" size="sm" height="0px">
                    <TableCaption>
                        Allergeni
                    </TableCaption>
                    <Tbody>
                        <Tr>
                            <Td>
                                <Checkbox label="Cereali" fontSize="small" fontWeight="light"/>
                            </Td>
                            <Td>
                                <Checkbox label="Crostacei" fontSize="small" fontWeight="light"/>
                            </Td>
                            <Td>
                                <Checkbox label="Uova" fontSize="small" fontWeight="light"/>
                            </Td>
                            <Td>
                                <Checkbox label="Pesce" fontSize="small" fontWeight="light"/>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Checkbox label="Arachidi" fontSize="small" fontWeight="light"/>
                            </Td>
                            <Td>
                                <Checkbox label="Soia" fontSize="small" fontWeight="light"/>
                            </Td>
                            <Td>
                                <Checkbox label="Latte" fontSize="small" fontWeight="light"/>
                            </Td>
                            <Td>
                                <Checkbox label="Frutta" fontSize="small" fontWeight="light"/>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Checkbox label="Sedano" fontSize="small" fontWeight="light"/>
                            </Td>
                            <Td>
                                <Checkbox label="Senape" fontSize="small" fontWeight="light"/>
                            </Td>
                            <Td>
                                <Checkbox label="Semi di Sesamo" fontSize="small" fontWeight="light"/>
                            </Td>
                            <Td>
                                <Checkbox label="Solfiti" fontSize="small" fontWeight="light"/>
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Checkbox label="Lupini" fontSize="small" fontWeight="light"/>
                            </Td>
                            <Td>
                                <Checkbox label="Molluschi" fontSize="small" fontWeight="light"/>
                            </Td>
                            <Td/>
                            <Td/>
                        </Tr>
                    </Tbody>
                </Table>
        }
    )

    const toggleAllergens = () => {

        if( tableRef.current ) 
            tableRef.current.classList.toggle('show');

        setAllergens( !hasAllergens );
    } */

  const toggleOrderable = () => {
    setOrderable(!isOrderable);
    if (!!props.menu[props.dishID])
      database
        .ref(`menu/${props.restaurant.restaurantKey}/items`)
        .child(props.dishID)
        .update({
          available: !isOrderable,
        })
        .then(() => {
          props.updateMenu({ ...props.menu, [props.dishID]: { ...props.menu[props.dishID], available: !isOrderable } });
        })
        .catch((err) => console.log(err));
    else {
      const newDishes = { ...props.dishes };
      newDishes[props.categoryID].find((dish) => dish.itemKey === props.dishID).available = !isOrderable;
      props.updateDishes(newDishes);
    }
  };

  return (
    <Draggable draggableId={props.dishID} index={props.index}>
      {(provided) => (
        <AccordionItem
          opacity={props.isVisible ? 1 :0.6}
          {...provided.draggableProps}
          ref={provided.innerRef}
          position="relative"
          pl={{ base: 8, lg: 7 }}>
          <Flex
            p={2}
            borderRight="1px solid"
            borderRightColor="blackAlpha.200"
            height="100%"
            alignItems="center"
            justifyContent="center"
            background="gray.100"
            {...provided.dragHandleProps}
            position="absolute"
            left="0">
            <Icon as={HamburgerIcon} w={3} h={3} />
          </Flex>

          <Link to={`./${props.categoryID}/dish/${props.dishID}`}>
            <AccordionButton display="flex" justifyContent="stretch" p={1} _focus={{ outline: 'none' }}>
              <Flex width="100%" alignItems="center" position="relative">
                {avatar}
                <Flex direction="column" width="100%" pl={4} fontSize={{ base: 'small', lg: 'normal' }}>
                  <Text fontWeight="medium" maxWidth={{ base: '100%', lg: '85%' }} textAlign="left">
                    {props.name}
                  </Text>
                  <Text color="#595959" textAlign="left" fontWeight="normal">
                    {props.description}
                  </Text>
                </Flex>
                <Tooltip placement="top" hasArrow label={isOrderable ? t('category_make_dish_available') : t('category_make_dish_unavailable')}>
                  <IconButton
                    aria-label="Orderable"
                    size="sm"
                    background="white"
                    border="1px solid"
                    borderColor="blackAlpha.200"
                    position="relative"
                    color={isOrderable ? 'teal' : 'blackAlpha.700'}
                    boxShadow={isOrderable ? "0 0 0 2px rgba(0, 128, 128, 0.4)" : "0 0 0 3px rgba(66, 153, 225, 0.0)"}
                    isRound
                    _focus={{ outline: 'none' }}
                    mr={2}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleOrderable();
                    }}
                    icon={<FaUtensils size="15px" />}
                    as="div"
                  />
                </Tooltip>
              </Flex>
            </AccordionButton>
          </Link>
        </AccordionItem>
      )}
    </Draggable>
  );
}

Dish.propTypes = {
  avatar: PropTypes.string,
  categoryID: PropTypes.string.isRequired,
  dishID: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  isOrderable: PropTypes.bool,
};

Dish.defaultProps = {
  isOrderable: true,
  description: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dish);
