/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Flex, Text, Avatar, Menu, MenuButton, MenuList, MenuItem, IconButton, Badge } from '@chakra-ui/react';

import { connect } from 'react-redux';

import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { FaEye, FaEyeSlash, FaTrashAlt } from 'react-icons/fa';
import { GiBookmarklet } from 'react-icons/gi';

import propTypes from 'prop-types';
import TimeAgo from 'javascript-time-ago';
import it from 'javascript-time-ago/locale/it.json';
import Carousel from '../../MyCarousel';

import { deletePost } from '../../../Redux/Actions/Actions';
import { changePostVisibility } from '../../../API/changePostVisibility';

import bgPlaceholder from '../../../assets/bg-placeholder.jpg';

import './Styles/Post.css';

const mapStateToProps = (state) => ({
  restaurant: state.restaurant,
  posts: state.posts,
});

const mapDispatchToProps = (dispatch) => ({
  deletePost: (postKey) => dispatch(deletePost(postKey)),
  changeVisibility: (postID, visibility) => dispatch(changePostVisibility(postID, visibility)),
});

function Post({ changeVisibility, restaurant, posts, id, deletePost }) {
  const [isVisible, setVisible] = useState(undefined);
  const [dateFormatter, setDateFormatter] = useState(null);

  useEffect(() => {
    if (!!posts && !!id) {
      setVisible(posts[id].visibile);
    }
  }, [posts, id]);

  const toggleVisible = () => {
    changeVisibility(id, !isVisible)
      .then((res) => {
        console.log(res);
        setVisible(!isVisible);
      })
      .catch((err) => {
        console.log('There was an error');
        console.log(err);
      });
  };

  const visibilityOption = isVisible ? (
    <MenuItem icon={<FaEye color="#666" />} onClick={() => toggleVisible()}>
      Hide
    </MenuItem>
  ) : (
    <MenuItem icon={<FaEyeSlash color="#666" />} onClick={() => toggleVisible()}>
      Show
    </MenuItem>
  );

  const imagesCount = 1;

  useEffect(() => {
    TimeAgo.locale(it);
    setDateFormatter(new TimeAgo(it.locale));
  }, []);

  useEffect(() => {
    if (!!posts[id]) setVisible(posts[id].visible);
  }, [posts]);

  return (
    <Flex width="100%" justifyContent="center" opacity={isVisible ? 1 : 0.6} mb={6}>
      {' '}
      {/* The container */}
      <Flex
        width={{ base: '100%', md: '614px' }}
        border="1px solid"
        borderColor="blackAlpha.200"
        boxShadow="rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;"
        background="white"
        direction="column"
        padding={0}
        margin={0}
        mt={4}>
        {' '}
        {/* The actual post */}
        <Flex alignItems="center" width="100%" p={4}>
          {' '}
          {/** The post Header */}
          <Avatar size="sm" src={restaurant && restaurant.icon ? restaurant.icon : bgPlaceholder} boxShadow="sm" />
          <Flex direction="column">
            {' '}
            {/* Raggruppamento testi accanto ad avatar */}
            <Text fontSize="sm" fontWeight="medium" color="blackAlpha.900" ml={3}>
              {restaurant.name || ''}
            </Text>
            <Text color="blackAlpha.500" fontSize="small" fontWeight="medium" ml={3}>
              {!!dateFormatter && dateFormatter.format(posts[id].data)}
            </Text>
          </Flex>
          <Menu>
            <MenuButton
              as={IconButton}
              color="blackAlpha.500"
              icon={<HiOutlineDotsHorizontal size="24px" />}
              ml="auto"
              isRound
              background="white"
              cursor="pointer"
            />
            <MenuList>
              {visibilityOption}
              <MenuItem
                icon={<FaTrashAlt color="#666" />}
                onClick={() =>
                  deletePost(id)
                    .then((res) => console.log('result: ', res))
                    .catch((err) => console.log('error:', err))
                }>
                Delete post
              </MenuItem>
              <MenuItem icon={<GiBookmarklet color="#666" />}>Schedule</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        {!!posts[id].label && (
          <Flex pr={3} pl={3} width="100%">
            {posts[id].label.map((label) => (
              <Badge
                colorScheme={
                  label === 'Event'
                    ? 'orange'
                    : label === 'Receipe'
                    ? 'linkedin'
                    : label === 'Warning'
                    ? 'red'
                    : 'whiteAlpha'
                }
                mr={3}
                key={label}>
                {label}
              </Badge>
            ))}
          </Flex>
        )}
        <Text size="sm" lineHeight="5" p={3}>
          {posts[id].text}
        </Text>
        {posts[id].image && (
          <Flex
            width="100%"
            p={imagesCount <= 1 ? 0 : 2}
            justifyContent="space-between"
            borderRadius={imagesCount <= 1 ? 'none' : 'md'}
            overflow="hidden">
            <Carousel images={posts[id].image} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

Post.propTypes = {
  id: propTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Post);
