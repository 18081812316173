import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from "@chakra-ui/theme-tools";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";

const breakpoints = createBreakpoints({
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  "2xl":"1400px",
})

export const FeedInAppTheme = extendTheme(
    {
        breakpoints, 
        colors: {
            primary: "#4BAC61",
            secondary: "#00A282",
            error: "#9c3085",
            contrast: "#9c3085",
            highlight: "#065e54",
            stronghighlight: "#012e29",
            lightblack: "#1A1B1A",
            disabled: "#969696",
            descriptionGrey: "#808080",
            darkerGrey: "#505050",
            light: "#81C490",
            frozen: "#00CCFF",
            separator: "#e4e6e8",
            catdescriptiongrey: "#686868",
            closed: "#68000",
            formSep: "#B8B8B8",
            danger: "#F6D6D9",
        }, 

        fonts: {
          heading: "Roboto",
          body: "Roboto",
        },    

        components: {
          Steps,
        },
    }
);