import { database } from '../utils/firebase';

export const changePostVisibility = (postID, newVisibility) => (_, getState) =>
  new Promise((resolve, reject) => {
    const { restaurant } = getState();

    if (restaurant.restaurantKey.length > 0) {
      database
        .ref(`post/${restaurant.restaurantKey}/posts`)
        .child(postID)
        .update({ visible: newVisibility })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    } else reject(new Error('Restaurant key non esiste in Restaurant'));
  });
