import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

export const goToCheckout = (user) => {
  firebase.default
    .firestore()
    .collection('customers')
    .doc(user.uid)
    .collection('checkout_sessions')
    .add({
      price: 'price_1L2H4wCteg4IFPA3Q4tFHG9P',
      tax_rates: ['txr_1JlfpdCteg4IFPA3zlyj4pd9'],
      success_url: window.location.origin,
      cancel_url: 'https://feedinapp.com',
      billing_address_collection: 'required',
      tax_id_collection: {
        enabled: true,
      },
    })
    .then((docRef) => {
      docRef.onSnapshot(async (snap) => {
        const { error, url } = snap.data();
        if (error) {
          alert(`An error occurred: ${error.message}`);
        }
        if (url) {
          window.location.assign(url);
        }
      });
    });
};

export const sendToCustomerPortal = async () => {
  const functionRef = firebase.default.functions().httpsCallable('ext-firestore-stripe-payments-createPortalLink');
  const { data } = await functionRef({ returnUrl: `${window.location.origin}/dashboard/plan` });
  window.location.assign(data.url);
};
