import React from 'react';
import { Flex, Heading, Button, ButtonGroup, Text } from '@chakra-ui/react';
import { sendToCustomerPortal } from '../../../utils/stripe-utils';

function EditPlan() {
  return (
    <Flex
      direction="column"
      alignItems="center"
      width={{ base: '100%', lg: '60%' }}
      height="100%"
      justifyContent="center"
      pt={6}
      pl={6}>
      <Heading as="h3" size="md" color="blackAlpha.700" width="100%" alignSelf="flex-start">
        Il tuo piano
      </Heading>

      {/* <Button width="100%" color="white" borderRadius="5px" border="1px solid" borderColor="gray.400" p={3}
                onClick={() => sendToCustomerPortal()} colorScheme="facebook">
                Modifica il tuo piano di abbonamento
            </Button> */}
      <ButtonGroup
        width="100%"
        border="1px solid rgba(0,0,0,0.1)"
        borderRadius="md"
        p={2}
        position="relative"
        display="flex"
        flexDirection="column">
        <Text
          backgroundColor="white"
          fontSize="sm"
          color="blackAlpha.800"
          position="absolute"
          top="0"
          right="0"
          transform="translate(-50%, -50%)"
          pl={2}
          pr={2}
          fontWeight="medium">
          Abbonamento
        </Text>

        <Button
          colorScheme="green"
          size="md"
          type="submit"
          mt={6}
          alignSelf="flex-start"
          onClick={() => {
            sendToCustomerPortal();
          }}>
          <Text pl={3} pr={3}>
            Modifica piano di abbonamento
          </Text>
        </Button>
      </ButtonGroup>
    </Flex>
  );
}

export default EditPlan;
