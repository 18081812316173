import React from 'react';
import { Box, Flex, Text, Spinner } from '@chakra-ui/react';

import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import FloatingLabelInput from './FloatingLabelInput';

function FLIMapsAutocomplete(props) {
  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);

    props.setAddress(value);

    if (props.setLatLng) {
      const LatLng = await getLatLng(result[0]);
      props.setLatLng(LatLng);
    }

    if (props.setFullAddress) await props.setFullAddress(result);
  };

  return (
    <PlacesAutocomplete value={props.address} onChange={props.setAddress} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Flex
          width={props.width || '100%'}
          direction="column"
          ml={props.ml}
          mr={props.mr}
          mt={props.mt}
          mb={props.mb}
          pl={props.pl}
          pr={props.pr}
          pt={props.pt}
          pb={props.pb}
          border={props.border}>
          <Box>
            <FloatingLabelInput
              inputProps={getInputProps()}
              onFocus={props.onFocus}
              size={props.size}
              value={props.address}
              onBlur={props.onBlur}
              label={props.label}
              labelFor="address"
            />
          </Box>

          <Box width="100%" position="relative">
            <Flex
              width="100%"
              overflowX="hidden"
              top="0"
              direction="column"
              overflow="hidden"
              borderRadius="0 0 .5rem .5rem"
              border="1px solid"
              borderStyle={suggestions.length > 0 ? 'solid' : 'none'}
              borderColor="blackAlpha.400"
              position="absolute"
              zIndex="2">
              {!loading ? (
                suggestions.map((suggestion) => {
                  const styles = {
                    backgroundColor: suggestion.active ? '#3182ce' : '#FFF',
                    color: suggestion.active ? '#FFF' : 'rgba(0,0,0,.7)',
                    fontWeight: suggestion.active ? '400' : 'none',
                    transition: '.2s ease all',
                    cursor: 'pointer',
                  };

                  return (
                    <Text
                      width="100%"
                      maxWidth="100%"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      size="sm"
                      p={1}
                      border="1px"
                      borderColor="blackAlpha.200"
                      borderStyle="solid none none none"
                      {...getSuggestionItemProps(suggestion, styles)}
                      key={suggestion.placeId}>
                      {suggestion.description}
                    </Text>
                  );
                })
              ) : (
                <Flex
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                  border="1px solid"
                  borderColor="blackAlpha.400"
                  height="150px"
                  backgroundColor="#FFF">
                  <Spinner size="xl" />
                </Flex>
              )}
            </Flex>
          </Box>
        </Flex>
      )}
    </PlacesAutocomplete>
  );
}

export default FLIMapsAutocomplete;
