import React, { useRef, useState } from 'react';
import { Flex, Image, Text, InputGroup, InputLeftElement, Input, Button, Alert, AlertIcon } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

import Fork from '../assets/Fork-green.png';
import './Styles/Signup.css';

function ResetPassword() {
  const emailRef = useRef();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const { t } = useTranslation();

  const { reimpostaPassword } = useAuth();

  async function handleSubmit(e) {
    e.preventDefault();

    console.log(emailRef.current.email);

    try {
      setError('');
      setMessage('');
      setLoading(true);
      await reimpostaPassword(emailRef.current.value);
      setMessage('Check your inbox to reset your password.');
    } catch (error) {
      setError('Failed to send password recovery email');
    } finally {
      setLoading(false);
    }
  }
  return (
    <Flex direction="column" justifyContent="center" alignItems="center">
      <Image boxSize="16%" src={Fork} alt="Logo FeedInApp" />
      <Text fontSize="2xl" fontWeight="light" mt={2}>
        {t('forn_reset_password')}
      </Text>
      <Flex
        direction="column"
        alignItems="center"
        width={{ base: '350px', sm: '450px' }}
        border="1px solid"
        borderColor="separator"
        borderRadius="xl"
        mt={3}
        background="#F6F8FA">
        <Text p={5} fontWeight="medium" fontSize="small">
          {t('form_reset_message')}
        </Text>
        {error && (
          <Alert status="error" borderRadius="xl" maxWidth="80%" mb={4}>
            <AlertIcon />
            {error}
          </Alert>
        )}
        {message && (
          <Alert status="success" borderRadius="xl" width="80%" mb={4}>
            <AlertIcon />
            {message}
          </Alert>
        )}
        <form onSubmit={handleSubmit} style={{ width: '90%' }}>
          <InputGroup width="100%" backgroundColor="#FFF">
            <InputLeftElement pointerEvents="none">
              <EmailIcon color="descriptionGrey" />
            </InputLeftElement>
            <Input type="text" placeholder="Email" borderRadius="2xl" ref={emailRef} isRequired />
          </InputGroup>
          <Flex width="100%" direction="row-reverse" mt={1} mr={4}>
            <Link to="/login">
              <Text fontSize="small" color="#0366D6" _hover={{ textDecoration: 'underline' }}>
                Login
              </Text>
            </Link>
          </Flex>
          <Button
            variant="undecorated"
            color="#FFF"
            background="primary"
            width="100%"
            mt={6}
            mb={5}
            _hover={{ background: 'rgba(6,94,43,1)' }}
            _activeLink={{ background: 'rgba(6,94,43,1)' }}
            type="submit"
            isLoading={loading}>
            {t('form_reset_submit')}
          </Button>
        </form>
      </Flex>
    </Flex>
  );
}

export default ResetPassword;
