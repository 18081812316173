import React, { useEffect, useState } from 'react';
import {
  Flex,
  Text,
  Icon,
  useDisclosure,
  Modal,
  IconButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  Button,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { IoMdClock } from 'react-icons/io';
import { RiCloseFill } from 'react-icons/ri';
import { FaCheck, FaPenNib, FaArrowRight } from 'react-icons/fa';
import Timekeeper from 'react-timekeeper';
import UUID from 'react-uuid';

import './Styles/OpenHour.css';

function OpenHourAccordionItem(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const [openHours, setOpenHours] = useState([]);

  useEffect(() => {
    setOpenHours(props.openHours);
  }, [props.openHours]);

  const updateOpenHours = (id, newValue) => {
    const index = openHours.findIndex((x) => x.id === id);

    if (index >= 0)
      return [
        ...openHours.slice(0, index),
        {
          ...openHours[index],
          id: openHours[index].id,
          open: openHours[index].open,
          close: openHours[index].close,
          ...newValue,
        },
        ...openHours.slice(index + 1),
      ];
    return { ...openHours };
  };

  return (
    <>
      {openHours &&
        openHours.map((interval) => (
          <Flex
            key={interval.id}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
            p={1}
            border="1px solid"
            borderColor="blackAlpha.200"
            borderStyle="none none solid none">
            <IconButton
              aria-label="Delete"
              size="xs"
              background="white"
              border="1px solid"
              borderColor="blackAlpha.200"
              color="blackAlpha.700"
              icon={<RiCloseFill size="16px" />}
              isRound
              _focus={{ outline: 'none' }}
              mr={2}
              onClick={() => {
                if (selected && selected.id === interval.id) setSelected(null);
                setOpenHours((openHours) => openHours.filter((item) => item.id !== interval.id));
                props.onDelete(props.day, interval.id);
              }}
            />

            <IconButton
              aria-label="Edit"
              size="xs"
              icon={<FaPenNib size="12px" />}
              background="white"
              border="1px solid"
              borderColor="blackAlpha.200"
              color="blackAlpha.700"
              isRound
              _focus={{ outline: 'none' }}
              onClick={() => {
                setSelectedTab(0);
                setSelected(openHours.find((itm) => itm.id === interval.id));
                onOpen();
                console.log(openHours);
              }}
            />

            <Text ml="auto">{`${interval.open} \u2192 ${interval.close}`}</Text>
          </Flex>
        ))}

      <Button
        variant="ghost"
        isFullWidth
        colorScheme="green"
        p={1}
        _focus={{ outline: 'none' }}
        mt={2}
        onClick={() => {
          const newID = UUID();
          const newInterval = {
            id: newID,
            open: '00:00',
            close: '00:00',
          };
          setOpenHours((openHours) => [...openHours, newInterval]);
          setSelected(newInterval);
          setSelectedTab(0);
          onOpen();
        }}>
        +
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent minWidth="0" minHeight="0">
          <ModalHeader display="flex" width="100%">
            <Flex cursor="pointer" width="100%" border="1px solid #4bac61" borderRadius="md">
              <Flex
                width="50%"
                alignItems="center"
                justifyContent="center"
                p={2}
                background={selectedTab === 0 ? '#4bac61' : 'white'}
                onClick={() => setSelectedTab(0)}>
                <Icon as={IoMdClock} h={6} w={6} mr={1} color={selectedTab === 1 ? '#4bac61' : 'white'} />
                <Text fontSize="smaller" fontWeight="normal" color={selectedTab === 1 ? '#4bac61' : 'white'}>
                  Apertura
                </Text>
              </Flex>

              <Flex
                width="50%"
                alignItems="center"
                justifyContent="center"
                p={2}
                onClick={() => setSelectedTab(1)}
                background={selectedTab === 1 ? '#4bac61' : 'white'}>
                <Icon as={IoMdClock} h={6} w={6} mr={1} color={selectedTab === 0 ? '#4bac61' : 'white'} />
                <Text fontSize="smaller" fontWeight="normal" color={selectedTab === 0 ? '#4bac61' : 'white'}>
                  Chiusura
                </Text>
              </Flex>
            </Flex>
          </ModalHeader>

          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            background="#f4f4f4"
            position="relative">
            <Flex
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              zIndex={selectedTab === 0 ? '2' : '1'}>
              <Timekeeper
                switchToMinuteOnHourSelect
                switchToMinuteOnHourDropdownSelect
                time={selected ? selected.open : '12:00'}
                hour24Mode="true"
                onChange={(newTime) => {
                  setOpenHours(
                    updateOpenHours(selected.id, {
                      open: `${newTime.hour}:${newTime.minute < 10 ? '0' : ''}${newTime.minute}`,
                    }),
                  );
                }}
              />
            </Flex>

            <Flex
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              top="0"
              left="0"
              zIndex={selectedTab === 0 ? '1' : '2'}>
              <Timekeeper
                switchToMinuteOnHourSelect
                switchToMinuteOnHourDropdownSelect
                time={selected ? selected.close : '12:00'}
                hour24Mode="true"
                onChange={(newTime) => {
                  setOpenHours(
                    updateOpenHours(selected.id, {
                      close: `${newTime.hour}:${newTime.minute < 10 ? '0' : ''}${newTime.minute}`,
                    }),
                  );
                }}
              />
            </Flex>
          </ModalBody>

          <ModalFooter display="flex" width="100%">
            <Button
              variant="solid"
              colorScheme="green"
              background="#4bac61"
              width="100%"
              leftIcon={selectedTab === 1 ? <FaCheck color="white" size="16px" /> : null}
              rightIcon={selectedTab === 0 ? <FaArrowRight color="white" size="16px" /> : null}
              onClick={() => {
                if (selectedTab === 0) {
                  setSelectedTab(1);
                } else {
                  props.onChange(openHours);
                  setOpenHours((openHours) => openHours.filter((o) => o.open !== o.close));
                  onClose();
                }
              }}>
              {selectedTab === 0 ? 'Avanti' : 'Fatto'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function OpenHourItem(props) {
  /*   useEffect(
    ()=>{
        console.log("OPEN HOURS:", props.openHours);
    }, [props.openHours] 
  ) */

  const dayName = {
    mon: 'Lunedì',
    tue: 'Martedì',
    wed: 'Mercoledì',
    thu: 'Giovedì',
    fri: 'Venerdì',
    sat: 'Sabato',
    sun: 'Domenica',
  };

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            {dayName[props.day]}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <OpenHourAccordionItem
          day={props.day}
          onChange={props.onChange}
          openHours={props.openHours}
          onDelete={props.onDelete}
        />
      </AccordionPanel>
    </AccordionItem>
  );
}

export default OpenHourItem;
