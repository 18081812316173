import React from 'react';
import { Box } from '@chakra-ui/react';
import { connect } from 'react-redux';

import Topbar from './Overlay/Topbar';

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

function Overlay({ currentUser, children }) {
  return (
    <Box width="100%" bg="#fafafa">
      <Topbar avatar={currentUser ? currentUser.photoURL : null} />
      {children}
    </Box>
  );
}

export default connect(mapStateToProps)(Overlay);
