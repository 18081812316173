/**
 * NEW APPLICATION'S ACTIONS
 */

import {
  DATA_FETCHED,
  DELETE_CATEGORY,
  RESTORE_CATEGORY,
  EDIT_CATEGORY,
  ADD_CATEGORY,
  DELETE_DISH,
  RESTORE_DISH,
  EDIT_DISH,
  ADD_DISH,
  EDIT_RESTAURANT,
  ADD_RESTAURANT,
  LOAD_CATEGORIES,
  LOAD_MENU,
  UPDATE_USER_CREDENTIALS,
  REPOSITION_CATEGORIES,
  LOAD_DISHES,
  SAVE_DISH,
  FETCH_POSTS,
  ADD_POST,
  DELETE_POST,
  UPDATE_LOCAL_POST_IMAGES,
  APPEND_SUBSCRIPTION_STATUS,
} from './ActionTypes';

import firebase, { database } from '../../utils/firebase';

export const setDataFetched = (restaurant, categories, dishesByCategory) => ({
  type: DATA_FETCHED,

  payload: {
    restaurant,
    categories,
    dishesByCategory,
  },
});

export const editRestaurant = (restaurant) => ({
  type: EDIT_RESTAURANT,

  payload: {
    restaurant,
  },
});

export const deleteCategory = (category, previousCategory) => ({
  type: DELETE_CATEGORY,

  payload: {
    category,
    previousCategory,
  },
});

export const restoreCategory = (category, previousCategory) => ({
  type: RESTORE_CATEGORY,

  payload: {
    category,
    previousCategory,
  },
});

export const editCategory = (category, previousCategory) => ({
  type: EDIT_CATEGORY,

  payload: {
    category,
    previousCategory,
  },
});

export const addCategory = (category, previousCategory) => ({
  type: ADD_CATEGORY,

  payload: {
    category,
    previousCategory,
  },
});

export const deleteDish = (dish, categoryID, previousLocation) => ({
  type: DELETE_DISH,

  payload: {
    dish,
    categoryID,
    previousLocation,
  },
});

export const restorDish = (dish, categoryID, previousLocation) => ({
  type: RESTORE_DISH,

  payload: {
    dish,
    categoryID,
    previousLocation,
  },
});

export const editDish = (dish, categoryID, previousLocation) => ({
  type: EDIT_DISH,

  payload: {
    dish,
    categoryID,
    previousLocation,
  },
});

export const addDish = (dish, categoryID, previousLocation) => ({
  type: ADD_DISH,

  payload: {
    dish,
    categoryID,
    previousLocation,
  },
});

export const addRestaurant = (restaurant) => ({
  type: ADD_RESTAURANT,

  payload: {
    restaurant,
  },
});

export const loadCategories = (categories) => ({
  type: LOAD_CATEGORIES,

  payload: {
    categories: Object.values(categories).sort((catA, catB) => catA.pos - catB.pos),
  },
});

export const repositionCategories = (categories) => ({
  type: REPOSITION_CATEGORIES,

  payload: {
    categories,
  },
});

export const loadMenu = (menu) => ({
  type: LOAD_MENU,

  payload: {
    menu,
  },
});

export const updateUserCredentials = (userCredentials) => ({
  type: UPDATE_USER_CREDENTIALS,

  payload: {
    userCredentials,
  },
});

export const loadDishes = (dishes) => ({
  type: LOAD_DISHES,

  payload: {
    dishes,
  },
});

export const saveDish = (theDish) => (dispatch, getState) => {
  const { restaurantKey } = getState().restaurant;
  if (!restaurantKey) {
    console.log('No restaurant key');
    return;
  }

  database
    .ref(`menu/${restaurantKey}/items`)
    .child(theDish.itemKey)
    .update({ ...theDish })
    .then((res) => {
      console.log(res);
      dispatch({
        type: SAVE_DISH,

        payload: {
          categoryKey: theDish.categoryKey,
          dishID: theDish.itemKey,
          dish: theDish,
        },
      });
    })
    .catch((err) => console.log(err));
};

export const fetchMenu = (menu) => (dispatch, getState) =>
  new Promise((resolve) => {
    dispatch(loadMenu(menu));

    const dishes = {};
    const { categories } = getState();

    categories.forEach((cat) => {
      if ('categoryKey' in cat) dishes[cat.categoryKey] = [];
    });

    Object.keys(menu).forEach((dish) => {
      if (menu[dish].categoryKey in dishes && dish in menu) dishes[menu[dish].categoryKey].push(menu[dish]);
    });

    Object.keys(dishes).forEach((category) => {
      dishes[category] = dishes[category].sort((dishA, dishB) => dishA.pos - dishB.pos);
    });

    dispatch(loadDishes(dishes));
    resolve(true);
  });

export const updateRestaurantImage = (imageURL) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const theRestaurant = getState().restaurant;

    const newRestaurant = { ...theRestaurant };
    Object.assign(newRestaurant, { cover: imageURL });

    database
      .ref('restaurants')
      .child(theRestaurant.restaurantKey)
      .update({ cover: imageURL })
      .then((res) => {
        dispatch(addRestaurant(newRestaurant));
        resolve(res);
      })
      .catch((err) => reject(err));
  });

export const deletePreviousRestaurantImage = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const theRestaurant = getState().restaurant;
    const newRestaurant = { ...theRestaurant };

    delete newRestaurant['cover'];
    console.log('Deleting ', theRestaurant.cover);

    firebase
      .storage()
      .refFromURL(theRestaurant.cover)
      .delete()
      .then((res) => {
        database
          .ref('restaurants')
          .child(`${theRestaurant.restaurantKey}/cover`)
          .remove()
          .then(() => {
            dispatch(addRestaurant(newRestaurant));
            resolve(res);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });

const fetchPostsType = (posts) => ({
  type: FETCH_POSTS,

  payload: {
    posts,
  },
});

export const fetchPosts = (restaurantKey) => (dispatch) =>
  new Promise((resolve, reject) => {
    database
      .ref(`post/${restaurantKey}/posts`)
      .once('value', (posts) => {
        if (posts.exists()) {
          dispatch(fetchPostsType(posts.val()));
          resolve(posts.val());
        }
      })
      .catch((err) => reject(err));
  });

export const deletePost = (postKey) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const post = getState().posts[postKey];

    let responses;
    if (post !== undefined) {
      if (post.image !== undefined) {
        responses = post.image.map((image) => firebase.storage().refFromURL(image).delete());
      }
      if (responses === undefined) {
        console.log('Responses are undefined');
        removePostFromDb(post, resolve, reject, dispatch);
      } else {
        console.log('Responses');
        Promise.all(responses)
          .then(() => {
            removePostFromDb(post, resolve, reject, dispatch);
          })
          .catch((err) => reject(err));
      }
    } else
      reject(
        new Error({
          message: 'Post not found',
        }),
      );
  });

export const deletePostType = (postKey) => ({
  type: DELETE_POST,

  payload: {
    postKey,
  },
});
const removePostFromDb = (post, resolve, reject, dispatch) => {
  database
    .ref(`post/${post.restaurantKey}/posts`)
    .child(post.postKey)
    .remove()
    .then((res) => {
      dispatch(deletePostType(post.postKey));
      resolve(res);
    })
    .catch((err) => reject(err));
};

export const addPost = (post) => ({
  type: ADD_POST,

  payload: {
    post,
  },
});

export const updateLocalPostImage = (postID, images) => ({
  type: UPDATE_LOCAL_POST_IMAGES,

  payload: {
    postID,
    images,
  },
});

export const appendSubscriptionStatus = (status) => ({
  type: APPEND_SUBSCRIPTION_STATUS,

  payload: {
    status,
  },
});
