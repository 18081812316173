import React from 'react';
import { Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import '../Styles/Topbar.css';

function MenuIcon({ active, icon, link, label }) {
  return (
    <Tooltip placement="bottom" label={label} hasArrow>
      <Link
        to={`/${link}`}
        style={{
          height: '100%',
          display: 'flex',
          boxSizing: 'border-box',
          alignItems: 'center',
          padding: '1.125rem',
          background: 'inherit' /** active ? "#dbffcc":"inherit",* */,
          borderBottom: active ? '2px solid #4bac61' : 'transparent',
        }}
        className={active ? 'blackhover' : 'blackhover expandBorderFromCenter'}>
        {icon}
      </Link>
    </Tooltip>
  );
}

export default MenuIcon;

/**
 * <FaUtensils size="16px"/>
 */
