import { makeTextOnlyPost } from './makePost';
import { database } from '../utils/firebase';
import { updateLocalPostImage } from '../Redux/Actions/Actions';
import { uploadPostImages } from './uploadPostImages';

export const makeRichPost = (text, labels, images) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { restaurant } = getState();

    dispatch(makeTextOnlyPost(restaurant.restaurantKey, text, labels))
      .then((result) => {
        uploadPostImages(result.postKey, restaurant.restaurantKey, images)
          .then((uploadURLS) => {
            database
              .ref(`post/${restaurant.restaurantKey}/posts/${result.postKey}`)
              .update({ image: uploadURLS })
              .then((res) => {
                dispatch(updateLocalPostImage(result.postKey, uploadURLS));
                resolve(res);
              })
              .catch((err) => reject(err));
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
