import React from 'react';
import { Flex, Spinner, Text, CloseButton, Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

/**
 *
 * @disclaimer The following component is simply the body of a custom Toast.
 *              using this component alone will NOT spawn a new toast. For this
 *              to happen you have to enclose this component within the render method
 *              of a toast({}). Both ensuring that no duplicate Toast of this type and
 *              defining the onClose action are to be done externally. Again, this is only
 *              the visual part and doesn't hold any logic within.
 */
function LoadingToast(props) {
  return (
    <Flex
      bg="white"
      boxShadow="rgb(0 0 0 / 5%) 0px 1px 3px, rgb(0 0 0 / 5%) 0px 28px 23px -7px, rgb(0 0 0 / 4%) 0px 12px 12px -7px"
      alignItems="stretch"
      border="1px solid rgb(233, 236, 239)">
      {props.accent && <Box width={props.accentThickness} backgroundColor={props.accentColor} mr={3} flex={1} />}
      <Spinner
        thickness="3px"
        color="rgb(34, 139, 230)"
        emptyColor="rgba(34, 139, 230, 0.5)"
        size="md"
        alignSelf="center"
      />
      <Flex direction="column" ml="5" maxWidth="300px" m={3}>
        <Text color="black" fontWeight="500" fontSize="14px">
          {props.title}
        </Text>
        <Text color="rgb(134, 142, 150)" fontSize="14px">
          {' '}
          {props.content}
        </Text>
      </Flex>
      <CloseButton alignSelf="flex-start" onClick={() => props.onClose()} />
    </Flex>
  );
}

LoadingToast.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  accent: PropTypes.bool,
  accentThickness: PropTypes.string,
  accentColor: PropTypes.string,
  onClose: PropTypes.func,
};

LoadingToast.defaultProps = {
  accent: false,
  accentThickness: '4px',
  accentColor: 'rgb(34, 139, 230)',
  onClose: () => {},
};

export default LoadingToast;
