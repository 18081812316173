import { loadDishes, saveDish as _saveDish, fetchMenu } from '../../../Redux/Actions/Actions';
import firebase, { database } from '../../../utils/firebase';
import { uploadImage } from '../../../API/uploadImage';

const makeNewDishes = (dish, prevDishes, restaurantKey, promises) =>
  new Promise((resolve) => {
    const newDishes = { ...prevDishes };

    prevDishes[dish.categoryKey].forEach((d) => {
      if (d.pos > dish.pos) {
        promises.push(
          database
            .ref(`menu/${restaurantKey}/items`)
            .child(d.itemKey)
            .update({
              pos: d.pos - 1,
            }),
        );

        newDishes[dish.categoryKey].find((dd) => dd.itemKey === dish.itemKey).pos -= 1;
      }
    });

    newDishes[dish.categoryKey] = newDishes[dish.categoryKey].filter((d) => d.itemKey !== dish.itemKey);

    resolve(newDishes);
  });

export const handleDishDelete = (dish) => (dispatch, state) =>
  new Promise((resolve, reject) => {
    const { dishes } = state();
    const { restaurant } = state();
    const allPromises = [];

    if (!!dish.image) {
      allPromises.push(firebase.storage().refFromURL(dish.image).delete());
    }

    allPromises.push(makeNewDishes(dish, dishes, restaurant.restaurantKey, allPromises));

    Promise.all(allPromises)
      .then((allRes) => {
        database
          .ref(`menu/${restaurant.restaurantKey}/items`)
          .child(dish.itemKey)
          .remove()
          .then((res) => {
            dispatch(loadDishes(allRes[allPromises.length - 1]));
            resolve(res);
            /* props.history.goBack(); */
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });

export const deleteImage = (thisDish) => (dispatch, state) =>
  new Promise((resolve, reject) => {
    const { restaurant } = state();
    const { dishes } = state();

    if (!!thisDish.image) {
      firebase
        .storage()
        .refFromURL(thisDish.image)
        .delete()
        .then(() => {
          database
            .ref(`menu/${restaurant.restaurantKey}/items`)
            .child(`${thisDish.itemKey}/image`)
            .remove()
            .then(() => {
              dispatch(
                loadDishes({
                  ...dishes,
                  [thisDish.categoryKey]: dishes[thisDish.categoryKey].map((dish) => {
                    if (dish.itemKey === thisDish.itemKey) return { ...thisDish, image: null };
                    return dish;
                  }),
                }),
              );

              resolve(true);
            });
        });
    } else reject(new Error(false));
  });

export const saveDish = (thisDish, theCategory, dishNameEn, dishDescEn, imageData) => (dispatch, state) =>
  new Promise((resolve, reject) => {
    const { menu } = state();
    const { dishes } = state();
    const { restaurant } = state();

    const newDish = { ...thisDish };
    const newMenu = { ...menu };
    newDish['nameEN'] = dishNameEn;
    newDish['descriptionEN'] = dishDescEn;

    if (thisDish.categoryKey !== theCategory.categoryKey) {
      newMenu[thisDish.itemKey].categoryKey = theCategory.categoryKey;
      newMenu[thisDish.itemKey].pos = dishes[theCategory.categoryKey].length;

      dishes[thisDish.categoryKey].forEach((dish) => {
        if (dish.pos > thisDish.pos) {
          database
            .ref(`menu/${restaurant.restaurantKey}/items`)
            .child(dish.itemKey)
            .update({
              pos: dish.pos - 1,
            })
            .catch((err) => reject(err));

          menu[dish.itemKey].pos -= 1;
        }
      });

      newDish['pos'] = dishes[theCategory.categoryKey].length;
      newDish['categoryKey'] = theCategory.categoryKey;

      dispatch(fetchMenu(menu));
    }

    if (!!imageData) {
      uploadImage(`images/${restaurant.restaurantKey}/dishes/${thisDish.itemKey}`, imageData)
        .then((downloadURL) => {
          newDish['image'] = downloadURL;
          dispatch(_saveDish(newDish));
          resolve(newDish);
        })
        .catch((error) => reject(error));
    } else {
      dispatch(_saveDish(newDish));
      resolve(newDish);
    }
  });
