/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useState } from 'react';

const MultistepSignupContext = createContext({ isFirstAccess: true, restaurant: '', category: '', dish: '' });

export const useMultistepSignup = () => useContext(MultistepSignupContext);

export function MultistepSignupProvider({ children }) {
  const [isFirstAccess, setFirstAccess] = useState(false);
  const [restaurant, setRestaurant] = useState(null);
  const [category, setCategory] = useState(null);
  const [dish, setDish] = useState(null);
  const [resKey, setResKey] = useState(null);

  const value = {
    isFirstAccess,
    setFirstAccess,
    restaurant,
    setRestaurant,
    category,
    setCategory,
    dish,
    setDish,
    resKey,
    setResKey,
  };

  return <MultistepSignupContext.Provider value={value}>{children}</MultistepSignupContext.Provider>;
}
