import React from 'react';
import { Flex, Text, Icon, Tooltip } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

function SidebarItem(props) {
  return (
    <Link to={`/${props.link}`}>
      <Tooltip placement="right" label={props.name} hasArrow>
        <Flex
          width="100%"
          alignItems="center"
          justifyContent={{ base: 'center', lg: 'flex-start' }}
          p={{ base: 4, lg: 0 }}
          borderBottom="1px solid"
          borderBottomColor={{ base: 'blackAlpha.200', lg: 'transparent' }}>
          <Icon color={props.active ? 'blackAlpha.800' : 'blackAlpha.600'} as={props.icon} mr={5} ml={5} />
          <Text
            p={3}
            display={{ base: 'none', lg: 'flex' }}
            color={props.active ? 'blackAlpha.800' : 'blackAlpha.600'}
            fontWeight="medium"
            width={{ base: 'auto', lg: '100%' }}
            borderBottom="1px solid"
            borderBottomColor="blackAlpha.200"
            alignItems="center">
            {props.name}
            {props.active && <Icon color="blackAlpha.600" as={ChevronRightIcon} w={6} h={6} ml="auto" />}
          </Text>
        </Flex>
      </Tooltip>
    </Link>
  );
}

export default SidebarItem;
