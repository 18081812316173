import { BrowserRouter, Switch, Route } from 'react-router-dom';
import React from 'react';
import AuthProvider from './Components/contexts/AuthContext';
import RestaurantProvider from './Components/contexts/RestaurantContext';
import { MultistepSignupProvider } from './Components/contexts/MultistepSignupContext';
import Signup from './Components/Signup';
import Login from './Components/Login';
import ResetPassword from './Components/ResetPassword';
import Overlay from './Components/Overlay';
import PrivateRoute from './Components/PrivateRoute';
import Home from './Components/Sections/Home';
import Restaurant from './Components/Sections/Restaurant';
import RestaurantHome from './Components/Sections/Restaurant/RestaurantHome';
import Category from './Components/Sections/Restaurant/Category';
import Settings from './Components/Sections/Settings';
import DashboardOverlay from './Components/Sections/Dashboard/DashboardOverlay';
import ProfileInfo from './Components/Sections/Dashboard/ProfileInfo';
import EditProfile from './Components/Sections/Dashboard/EditProfile';
import EditPlan from './Components/Sections/Dashboard/EditPlan';
import EditPrivacy from './Components/Sections/Dashboard/EditPrivacy';
import UnitTesting from './Components/UnitTesting/UnitTesting';
import ActivityName from './Components/Sections/Signup/ActivityName';
import FirstDishes from './Components/Sections/Signup/FirstDishes';
import Recap from './Components/Sections/Signup/Recap';
import DishPage from './Components/Sections/Restaurant/DishPage';

import './App.css';
import { NewSignup } from './Components/Sections/Signup/NewSignup/NewSignupView';

function App() {
  return (
    <BrowserRouter>
      <MultistepSignupProvider>
        <Route path="/signup">
          <section>
            <NewSignup />
          </section>
        </Route>
        <AuthProvider>
          <RestaurantProvider>
            <Switch>
              <Route path="/login">
                <section>
                  <Login />
                </section>
              </Route>
              <Route path="/forgot-password">
                <section>
                  <ResetPassword />
                </section>
              </Route>
              <Route path="/signup-1">
                <section>
                  <ActivityName />
                </section>
              </Route>
              <Route path="/signup-2">
                <section>
                  <FirstDishes />
                </section>
              </Route>
              <Route path="/signup-3">
                <section>
                  <Signup />
                </section>
              </Route>
              <Route path="/signup-4">
                <section>
                  <Recap />
                </section>
              </Route>
              <PrivateRoute exact path="/post">
                <Overlay>
                  <Home />
                </Overlay>
              </PrivateRoute>
              <PrivateRoute exact path="/">
                <Overlay>
                  <Restaurant>
                    <RestaurantHome />
                  </Restaurant>
                </Overlay>
              </PrivateRoute>
              <PrivateRoute exact path="/restaurant/:categoryID">
                <Overlay>
                  <Restaurant>
                    <Category />
                  </Restaurant>
                </Overlay>
              </PrivateRoute>
              <PrivateRoute exact path="/restaurant/:categoryID/dish/:dishID">
                <Overlay>
                  <Restaurant>
                    <DishPage />
                  </Restaurant>
                </Overlay>
              </PrivateRoute>
              <PrivateRoute exact path="/settings">
                <Overlay>
                  <Settings />
                </Overlay>
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard/">
                <Overlay>
                  <DashboardOverlay>
                    <ProfileInfo />
                  </DashboardOverlay>
                </Overlay>
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard/edit">
                <Overlay>
                  <DashboardOverlay>
                    <EditProfile />
                  </DashboardOverlay>
                </Overlay>
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard/plan">
                <Overlay>
                  <DashboardOverlay>
                    <EditPlan />
                  </DashboardOverlay>
                </Overlay>
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard/privacy">
                <Overlay>
                  <DashboardOverlay>
                    <EditPrivacy />
                  </DashboardOverlay>
                </Overlay>
              </PrivateRoute>
              <Route path="/testing">
                <section style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                  <UnitTesting />
                </section>
              </Route>
            </Switch>
          </RestaurantProvider>
        </AuthProvider>
      </MultistepSignupProvider>
    </BrowserRouter>
  );
}

export default App;
