import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

export const uploadImage = (path, file) =>
  new Promise((resolve, reject) => {
    const metadata = {
      contentType: file.type,
    };

    const uploadTask = firebase
      .storage()
      .ref()
      .child(path) // "images/" + restaurantKey + "/dishes/" + dish.itemKey
      .put(file, metadata);

    uploadTask.on(
      'state_changed',
      () => {},
      (error) => {
        reject(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          resolve(downloadURL);
        });
      },
    );
  });
