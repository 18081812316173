/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Input,
  Tooltip,
  Box,
  IconButton,
  HStack,
  Button,
  Icon,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { RiEdit2Line } from 'react-icons/ri';
import { CheckIcon, CloseIcon, ChevronDownIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';

import { flagComponentsNames } from './utils/Flags';
import { SupportedLangs } from './utils/SupportedLangs';

import './Styles/InternationalEditor.css';

function Editor(props) {
  const [innerValue, setInnerValue] = useState(props.value || {});
  const [editMode, setEditMode] = useState(false);
  const [currentLang, setCurrentLang] = useState(props.default || 'IT');

  const scrollbar = useBreakpointValue({
    base: 'noScrollbar',
    md: 'decorated-vScrollbar',
  });

  const langDropdown = (
    <Menu>
      <MenuButton
        as={Button}
        aria-label="Options"
        leftIcon={<Icon as={flagComponentsNames[currentLang]} w={6} h={6} />}
        rightIcon={<ChevronDownIcon />}
        variant="outline"
        border="1px solid"
        borderColor="gray.200"
        className="langSelect"
        height={{ base: '38px', md: '50px' }}
        paddingInlineEnd={{ base: '0', md: '4' }}
        borderRadius={0}
        _focus={{ boxShadow: 'none' }}
        _hover={{
          borderColor: 'rgba(127,127,127,0.5)',
        }}
        backgroundColor="#efe7db">
        {props.verboseLang && SupportedLangs[currentLang]}
      </MenuButton>
      <MenuList maxHeight="150px" overflowY="scroll" className={scrollbar} width={!props.verboseLang && '50px'}>
        {Object.keys(SupportedLangs).map((langCode) => (
          <MenuItem
            key={langCode}
            icon={<Icon as={flagComponentsNames[langCode]} w={6} h={6} />}
            onClick={() => setCurrentLang(langCode)}>
            {SupportedLangs[langCode]}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );

  const confirmEdit = () => {
    setEditMode(false);
    props.onChange(innerValue);
  };

  const discardEdit = () => {
    setEditMode(false);
    setInnerValue(props.value || {});
  };

  useEffect(() => {
    if (props.value) setInnerValue(props.value);
    else setInnerValue({});
  }, [props.value]);

  return (
    <Flex as="article" width={props.width} direction="column" alignItems="flex-start" mb={props.mb}>
      <Text
        lineHeight={props.spacingMode && '250%'}
        textTransform={props.toUpper && 'uppercase'}
        fontWeight="medium"
        fontSize={{ base: 'sm', md: 'initial' }}
        color={props.color}
        letterSpacing="0.1rem">
        {props.label}
      </Text>
      <HStack width="100%" spacing={0} as="fieldset" position="relative" className="lb-0">
        {!editMode ? (
          <Flex width="100%" className="IntlinputGroup">
            {langDropdown}
            <Box
              border="1px solid"
              borderColor="gray.200"
              padding={{ base: '6px 15px', md: '12px 15px' }}
              flex="1"
              className="inputView"
              textOverflow="ellipsis"
              borderLeft="0px"
              overflow="hidden"
              whiteSpace="nowrap"
              width="0">
              <Text color={innerValue[currentLang] === '' && 'gray.400'}>
                {innerValue[currentLang] || props.placeholder}
              </Text>
            </Box>
            <Tooltip label="Modifica" placement="top" hasArrow>
              <IconButton
                icon={<RiEdit2Line />}
                variant="ghost"
                height="100%"
                padding={{ base: '10px 15px', md: '16px 15px' }}
                borderRadius="none"
                border="1px solid"
                borderColor="gray.200"
                className="penBox"
                onClick={() => {
                  setEditMode(true);
                }}
                aria-label={`Edit ${props.label}`}
              />
            </Tooltip>
          </Flex>
        ) : (
          <Flex
            width="100%"
            className="IntlinputGroup"
            border="1px solid rgba(127,127,127, 0.5)"
            _focusWithin={{
              border: '1px solid',
              borderColor: '#1890ff',
              boxShadow: '0 0 0 2px rgba(24, 144, 255, 0.2)',
            }}>
            {langDropdown}
            <Input
              variant="outline"
              _focus={{
                border: '0px solid',
                borderColor: '#1890ff',
              }}
              padding={{ base: '8px 15px', md: '14px 15px' }}
              height="100%"
              borderRadius="none"
              flex="1"
              borderWidth="0"
              value={innerValue[currentLang]}
              onChange={(e) =>
                setInnerValue({
                  ...innerValue,
                  [currentLang]: e.target.value.length > 0 ? e.target.value : '',
                })
              }
              className="inputBox"
              placeholder={props.placeholder || 'Type something'}
              autoFocus
            />
            <Flex
              position="absolute"
              bottom="0"
              right="0"
              transform="translateY(100%)"
              backgroundColor="#fff"
              zIndex="3">
              <IconButton
                icon={<CheckIcon />}
                variant="ghost"
                height="100%"
                padding="16px 15px"
                borderRadius="none"
                border="1px solid"
                borderColor="gray.500"
                onClick={() => {
                  confirmEdit();
                }}
                aria-label="confirm edit"
                marginRight="1px"
              />
              <IconButton
                icon={<CloseIcon />}
                variant="ghost"
                height="100%"
                padding="16px 15px"
                borderRadius="none"
                border="1px solid"
                borderColor="gray.500"
                onClick={() => {
                  discardEdit();
                }}
                aria-label="discard edit"
              />
            </Flex>
          </Flex>
        )}
      </HStack>
    </Flex>
  );
}

Editor.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  // as: PropTypes.string,
  // mt: PropTypes.number,
  mb: PropTypes.number,
  // fontSize: PropTypes.string,
  // fontWeight: PropTypes.string,
  toUpper: PropTypes.bool,
  spacingMode: PropTypes.bool,
  default: PropTypes.string,
  verboseLang: PropTypes.bool,
};

Editor.defaultProps = {
  value: null,
  onChange: () => {},
  label: null,
  color: 'blackAlpha.700',
  width: '100%',
  placeholder: 'Type something!',
  // as: 'h1',
  // fontSize: 'lg',
  // mt: 0,
  mb: 0,
  // fontWeight: 'medium',
  toUpper: true,
  spacingMode: true,
  default: 'IT',
  verboseLang: true,
};

export default Editor;
