import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Text,
  Input,
  Button,
} from '@chakra-ui/react';

function CreationModal(props) {
  const nameRef = useRef();
  const descriptionRef = useRef();
  const [error, setError] = useState(false);

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent as="div">
        <ModalHeader as="div">{props.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text fontSize="sm" color="#49576E" mb={4} lineHeight="normal">
            Potrai modificare queste informazioni ed altre ancora in un secondo momento.
          </Text>
          <FormControl isRequired>
            <FormLabel mb={error ? 0 : 2}>Nome</FormLabel>
            {error && (
              <Text color="#AD1A00" fontSize="xs" mb={2}>
                {/** Il nome della categoria non può essere vuoto!* */ props.errorMessage}
              </Text>
            )}
            <Input ref={nameRef} placeholder={`Nome ${props.type}`} isRequired />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Descrizione</FormLabel>
            <Input ref={descriptionRef} placeholder={`Descrizione ${props.type}`} />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor="#2B5F38"
            color="white"
            mr={3}
            onClick={() => {
              // createNewCategory();
              /**
               *@TODO When creating a new category, react-beautiful-dnd complains
               *      immediately after creation.
               */
              if (nameRef.current.value.length > 0) {
                props.onConfirm(nameRef.current.value, descriptionRef.current.value);
                props.onClose();
              } else setError(true);
            }}
            _hover={{ background: '#21452A' }}
            _activeLink={{ background: '#21452A' }}
            _active={{ background: '#21452A' }}
            _focus={{ background: '#21452A' }}>
            Aggiungi
          </Button>
          <Button
            onClick={() => {
              setError(false);
              props.onClose();
            }}>
            Annulla
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

CreationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreationModal;
