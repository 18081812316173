import React, { createContext, useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LoadingScreen from '../LoadingScreen';

import { getUserInfo, getRestaurantInfo, getCategories, getMenu } from '../../API/restaurant';
import { fetchSubscriptionStatus } from '../../API/getSubscriptionStatus';
import { store } from '../../Redux/Store/store';
import { addRestaurant, loadCategories, fetchMenu, fetchPosts } from '../../Redux/Actions/Actions';
import { useAuth } from './AuthContext';

const RestaurantContext = createContext({ restaurant: null, categories: null });

export const useRestaurant = () => useContext(RestaurantContext);

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

function RestaurantProvider({ currentUser, children }) {
  // const [restaurant, setRestaurant] = useState({});
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { theUser } = useAuth();

  const value = {
    // restaurant,
    categories,
    setCategories,
  };

  /**
   * @FIXME --> Quando viene creato un nuovo utente, non viene creato il corrispondente ristorante.
   * @FIXME --> Quando il cliente non ha un ristorante associato mostra un messaggio di fallimento, adesso dà errore di accesso a .restaurantKey
   * @TODO --> Testa il comportamento di loadCategories quando qualcuna delle categorie ha pos <- null oppure undefined
   */
  useEffect(() => {
    if (theUser === undefined) return;

    if (theUser === null || (currentUser && !currentUser.uid)) {
      setLoading(false);
      return;
    }

    setLoading(true);

    getUserInfo(currentUser.uid)
      .then((userInfo) => {
        if (userInfo.exists) {
          getRestaurantInfo(userInfo.val().restaurantKey)
            .then((restaurantInfo) => {
              if (!restaurantInfo.val()) {
                setLoading(false);
                console.log('No db record for this user yet');
              }

              if (restaurantInfo.exists) {
                store.dispatch(addRestaurant(restaurantInfo.val()));
                store.dispatch(fetchSubscriptionStatus());
                getCategories(userInfo.val().restaurantKey)
                  .then((cat) => {
                    if (cat.exists) {
                      store.dispatch(loadCategories(cat.val()));
                      getMenu(userInfo.val().restaurantKey)
                        .then((menuSnapshot) => {
                          if (menuSnapshot.exists && menuSnapshot.val()) {
                            store.dispatch(fetchMenu(menuSnapshot.val())).then(() => setLoading(false));
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                          setLoading(false);
                        });
                    }

                    /* setLoading(false);  */
                  })
                  .catch((error) => {
                    console.log(error);
                    setLoading(false);
                  });

                store.dispatch(fetchPosts(userInfo.val().restaurantKey));
              }
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [currentUser, theUser]);

  /* useEffect( async ()=>{
        const isLoggedIn = await auth.currentUser;
        if( isLoggedIn )
            setUserInfoAcquired(true);
    }, []) */

  return (
    <RestaurantContext.Provider value={value}>{loading ? <LoadingScreen /> : children}</RestaurantContext.Provider>
  );
}

export default connect(mapStateToProps)(RestaurantProvider);
