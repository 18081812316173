import { uploadImage } from './uploadImage';

export const uploadPostImages = (postID, restaurantKey, images) =>
  new Promise((resolve, reject) => {
    Promise.all(
      images.map((image, i) => {
        const today = new Date(Date.now());
        const theDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        return uploadImage(`images/${restaurantKey}/posts/${theDate}/${postID}/${postID}_${i + 1}`, image);
      }),
    )
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
