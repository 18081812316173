import React from 'react';
import { Input, Flex } from '@chakra-ui/react';

import './Styles/FloatingLabel.css';

function FloatingLabelInput(props) {
  return (
    <Flex
      className="form-group"
      direction="column"
      justifyContent="flex-start"
      width={props.width || '100%'}
      ml={props.ml}
      mr={props.mr}
      mt={props.mt}
      mb={props.mb}
      pl={props.pl}
      pr={props.pr}
      pt={props.pt}
      pb={props.pb}
      border={props.border}>
      <Input
        onFocus={props.onFocus}
        onChange={props.onChange}
        size={props.size}
        onBlur={props.onBlur}
        width="100%"
        className="form-control"
        variant="flushed"
        placeholder={props.placeholder || props.label}
        zIndex="1"
        id={props.labelFor}
        {...props.inputProps}
        value={props.value}
      />
      <label htmlFor={props.labelFor} className="form-label">
        {props.label}
      </label>
    </Flex>
  );
}

export default FloatingLabelInput;
