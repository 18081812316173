/* eslint-disable no-return-assign */
const supportedLangs = {
  IT: 'Italiano',
  GB: 'Inglese',
  /* FR: "Francese",
  DE: "Tedesco",
  ES: "Spagnolo",
  PT: "Portoghese",
  RU: "Russo",
  PL: "Polacco",
  SE: "Svedese",
  BE: "Belga",
  NL: "Olandese", */
};

const blankLangObject = {};
Object.keys(supportedLangs).forEach((theLang) => (blankLangObject[theLang] = ''));

export const blankLangSheet = { ...blankLangObject };
export const SupportedLangs = { ...supportedLangs };
