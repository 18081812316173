import React, { useRef } from 'react';
import { Flex, Box, Icon, useBreakpointValue, useToast, useDisclosure } from '@chakra-ui/react';
import { FaHome, FaPlus } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { putNewCategory, repositionCategories } from '../../../API/categories';
import LoadingToast from './utils/LoadingToast';

import './Styles/Overlay.css';
import CreationModal from './CreationModal';

const mapStateToProps = (state) => ({
  categories: state.categories,
  restaurant: state.restaurant,
});

const mapDispatchToProps = (dispatch) => ({
  updateCategories: (newPosDict) => dispatch(repositionCategories(newPosDict)),
  addNewCategory: (category) => dispatch(putNewCategory(category)),
});

function RestaurantOverlay({ width, children, categories, updateCategories, addNewCategory, restaurant }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const thisToast = useRef();
  const toastID = 'upload-toast';

  const onLoadingToastClose = () => {
    if (thisToast.current) toast.close(thisToast.current);
  };

  const addLoadingToast = () => {
    if (!toast.isActive(toastID))
      thisToast.current = toast({
        id: toastID,
        position: 'bottom-left',
        render: () => (
          <LoadingToast
            title="Aggiorno le posizioni sul database"
            content="Attendi il completamento dell'operazione, sarai notificato appena conclusa."
            accent
            onClose={() => onLoadingToastClose()}
          />
        ),
      });
  };

  const scrollbar = useBreakpointValue({
    base: 'noScrollbar',
    md: 'decorated-scrollbar',
  });
  const location = useLocation();

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    addLoadingToast();
    const newCategories = categories.map((category, idx) => {
      if (category.categoryKey === categories[source.index].categoryKey)
        return { index: idx, pos: destination.index, updated: true };
      if (source.index < destination.index) {
        if (category.pos > source.index && category.pos <= destination.index)
          return { index: idx, pos: category.pos - 1, updated: true };
        return { index: idx, pos: category.pos, updated: false };
      }
      if (category.pos >= destination.index && category.pos < source.index)
        return { index: idx, pos: category.pos + 1, updated: true };
      return { index: idx, pos: category.pos, updated: false };
    });

    const posDictionary = Object.values(newCategories).filter((cat) => cat.updated === true);

    updateCategories(posDictionary)
      .then(() => {
        onLoadingToastClose();
        toast({
          title: 'Categorie spostate con successo.',
          status: 'success',
          duration: 4000,
          isClosable: true,
          position: 'bottom-left',
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: 'Impossibile spostare le categorie.',
          description: 'Apri la console per saperne di più.',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'bottom-left',
        });
      });
  };

  const handleCategoryCreation = (name, description) => {
    addNewCategory({
      name,
      description: description || null,
      pos: categories.length,
      visible: true,
      restaurantKey: restaurant.restaurantKey,
    })
      .then(() => {
        toast({
          title: 'Categoria creata con successo.',
          description: 'Scorri le categorie per modificarla.',
          status: 'success',
          duration: 4000,
          isClosable: true,
          position: 'bottom-left',
        });
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: 'Impossibile creare la categoria.',
          description: 'Apri la console per saperne di più.',
          status: 'error',
          duration: 4000,
          isClosable: true,
          position: 'bottom-left',
        });
      });
  };

  const selectedTabClass = 'tab ripple selected';
  const genericTabClass = 'tab ripple';

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Flex alignItems="center" direction="column" maxWidth="100%" width={{ base: '100%', md: 'auto' }}>
          {children}

          {/* The bottom tab bar */}
          <Box
            bg="primary"
            pl="3"
            color="white"
            fontWeight="semibold"
            fontSize="xl"
            width={width || 'auto'}
            minHeight="50px"
            overflowX="hidden"
            zIndex="2">
            <Flex width="100%" height="50px" alignItems="stretch" p={0}>
              <Flex alignItems="center" height="100%">
                <Box
                  height="100%"
                  className={
                    location.pathname === '/' || location.pathname === '/' ? selectedTabClass : genericTabClass
                  }>
                  <Link to="/">
                    <Icon as={FaHome} w={5} h={5} />
                  </Link>
                </Box>

                {/** The auto generated tabs */}
              </Flex>

              <Droppable droppableId="1" direction="horizontal">
                {(provided) => (
                  <Flex
                    overflow="auto"
                    className={scrollbar}
                    width={{ base: '70%', md: '85%' }}
                    height="100%"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    flexShrink={0}>
                    {categories &&
                      categories.map((item, idx) => (
                        <React.Fragment key={item.categoryKey}>
                          <Draggable draggableId={item.categoryKey} index={idx}>
                            {(provided) => (
                              <Box
                                fontSize="md"
                                fontWeight="normal"
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                background="#4bac61"
                                className={
                                  location.pathname === `/restaurant/${item.categoryKey}`
                                    ? selectedTabClass
                                    : genericTabClass
                                }
                                onClick={() => {}}>
                                <Link to={`/restaurant/${item.categoryKey}`}>{item.name}</Link>
                              </Box>
                            )}
                          </Draggable>
                        </React.Fragment>
                      ))}
                    {provided.placeholder}
                  </Flex>
                )}
              </Droppable>

              {/** End autoGenerated tabs */}

              <Box height="100%" className={genericTabClass} onClick={onOpen} marginLeft="auto">
                <Box as="a">
                  <Icon as={FaPlus} w={5} h={5} />
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </DragDropContext>
      {/** Questo modale può essere un Component. Usato qui ed in Category.js */}
      <CreationModal
        isOpen={isOpen}
        onClose={onClose}
        title="Aggiungi una categoria"
        type="categoria"
        errorMessage="Il nome della categoria non può essere vuoto!"
        onConfirm={(name, desc) => handleCategoryCreation(name, desc)}
      />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantOverlay);
