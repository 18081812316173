import firebase from 'firebase/app';
import 'firebase/database';
/**
 * data.email
 * data.firstName
 * data.lastName
 * data.picture
 * data.uid
 */
export const updateUserInfo = (data) => {
  if (!data.uid)
    return {
      status: 'failure/missing-uid',
    };

  return firebase
    .database()
    .ref(`users/${data.uid}`)
    .update({
      firstName: data.firstName,
      lastName: data.lastName,
      avatar: data.picture,
      email: data.email,
    })
    .then(() => ({
      status: 'success',
    }))
    .catch((err) => ({
      status: 'error-update-failed',
      err,
    }));
};
