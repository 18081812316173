import React, { useState, useEffect, createRef } from 'react';
import { Flex, Text, Heading, Input, Button } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

import { useHistory } from 'react-router';
import UnauthenticatedCard from '../../UnauthenticatedCard';
import { useMultistepSignup } from '../../contexts/MultistepSignupContext';
import { useAuth } from '../../contexts/AuthContext';
import { createRestaurant } from '../../../utils/firebase';

function ActivityName() {
  const { restaurant, setRestaurant, setResKey, resKey } = useMultistepSignup();
  const { anonymousLogin, tempUser } = useAuth();
  const cronologia = useHistory();
  const formRef = createRef();
  const [name, setName] = useState(restaurant || '');

  useEffect(() => {
    anonymousLogin();
  }, []);

  useEffect(() => {
    if (tempUser) console.log(tempUser);
  }, [tempUser]);

  const advance = (e) => {
    e.preventDefault();
    setRestaurant(name);
    console.log(name);
    console.log(tempUser.user.uid || tempUser.uid);
    createRestaurant({
      restaurantName: name,
      uid: tempUser.user.uid,
    }).then((result) => {
      console.log(result);
      setResKey(result.data.data);
      cronologia.push('/signup-2/');
    });
  };

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    if (resKey) console.log('Restaurant key: ', resKey);
  }, [resKey]);

  return (
    <UnauthenticatedCard
      onSubmit={(e) => {
        e.preventDefault();
      }}
      formRef={formRef}>
      <Flex height="100%" width="100%" p={3} direction="column" justifyContent="center">
        <Heading as="h1" size="2xl" fontWeight="bold" color="blackAlpha.800" alignSelf="flex-start">
          Iniziamo!
        </Heading>

        <Text color="blackAlpha.800" size="normal" mt={10} fontWeight="medium">
          Inserisci il nome della tua attività
        </Text>

        <Input
          width="100%"
          variant="flushed"
          placeholder="Come si chiama la tua attività?"
          value={name}
          onChange={onNameChange}
          isRequired
        />

        <Button
          variant="undecorated"
          color="#FFF"
          background="linear-gradient(90deg, rgba(75,172,97,1) 0%, rgba(6,94,43,1) 100%)"
          _hover={{ background: 'rgba(6,94,43,1)' }}
          _activeLink={{ background: 'rgba(6,94,43,1)' }}
          width="100%"
          type="submit"
          mt={10}
          isLoading={false}
          rightIcon={<ChevronRightIcon />}
          onClick={(e) => advance(e)}>
          Avanti
        </Button>
      </Flex>
    </UnauthenticatedCard>
  );
}

export default ActivityName;
