import React from 'react';
import countryFlags from 'country-flag-icons/react/3x2';

export const flagComponents = (props) => ({
  AF: <countryFlags.AF {...props} />,
  AX: <countryFlags.AX {...props} />,
  AL: <countryFlags.AL {...props} />,
  DZ: <countryFlags.DZ {...props} />,
  AS: <countryFlags.AS {...props} />,
  AD: <countryFlags.AD {...props} />,
  AO: <countryFlags.AO {...props} />,
  AI: <countryFlags.AI {...props} />,
  AQ: <countryFlags.AQ {...props} />,
  AG: <countryFlags.AG {...props} />,
  AR: <countryFlags.AR {...props} />,
  AM: <countryFlags.AM {...props} />,
  AW: <countryFlags.AW {...props} />,
  AU: <countryFlags.AU {...props} />,
  AT: <countryFlags.AT {...props} />,
  AZ: <countryFlags.AZ {...props} />,
  BS: <countryFlags.BS {...props} />,
  BH: <countryFlags.BH {...props} />,
  BD: <countryFlags.BD {...props} />,
  BB: <countryFlags.BB {...props} />,
  BY: <countryFlags.BY {...props} />,
  BE: <countryFlags.BE {...props} />,
  BZ: <countryFlags.BZ {...props} />,
  BJ: <countryFlags.BJ {...props} />,
  BM: <countryFlags.BM {...props} />,
  BT: <countryFlags.BT {...props} />,
  BO: <countryFlags.BO {...props} />,
  BQ: <countryFlags.BQ {...props} />,
  BA: <countryFlags.BA {...props} />,
  BW: <countryFlags.BW {...props} />,
  BV: <countryFlags.BV {...props} />,
  BR: <countryFlags.BR {...props} />,
  IO: <countryFlags.IO {...props} />,
  BN: <countryFlags.BN {...props} />,
  BG: <countryFlags.BG {...props} />,
  BF: <countryFlags.BF {...props} />,
  BI: <countryFlags.BI {...props} />,
  KH: <countryFlags.KH {...props} />,
  CM: <countryFlags.CM {...props} />,
  CA: <countryFlags.CA {...props} />,
  CV: <countryFlags.CV {...props} />,
  KY: <countryFlags.KY {...props} />,
  CF: <countryFlags.CF {...props} />,
  TD: <countryFlags.TD {...props} />,
  CL: <countryFlags.CL {...props} />,
  CN: <countryFlags.CN {...props} />,
  CX: <countryFlags.CX {...props} />,
  CC: <countryFlags.CC {...props} />,
  CO: <countryFlags.CO {...props} />,
  KM: <countryFlags.KM {...props} />,
  CG: <countryFlags.CG {...props} />,
  CD: <countryFlags.CD {...props} />,
  CK: <countryFlags.CK {...props} />,
  CR: <countryFlags.CR {...props} />,
  CI: <countryFlags.CI {...props} />,
  HR: <countryFlags.HR {...props} />,
  CU: <countryFlags.CU {...props} />,
  CW: <countryFlags.CW {...props} />,
  CY: <countryFlags.CY {...props} />,
  CZ: <countryFlags.CZ {...props} />,
  DK: <countryFlags.DK {...props} />,
  DJ: <countryFlags.DJ {...props} />,
  DM: <countryFlags.DM {...props} />,
  DO: <countryFlags.DO {...props} />,
  EC: <countryFlags.EC {...props} />,
  EG: <countryFlags.EG {...props} />,
  SV: <countryFlags.SV {...props} />,
  GQ: <countryFlags.GQ {...props} />,
  ER: <countryFlags.ER {...props} />,
  EE: <countryFlags.EE {...props} />,
  ET: <countryFlags.ET {...props} />,
  FK: <countryFlags.FK {...props} />,
  FO: <countryFlags.FO {...props} />,
  FJ: <countryFlags.FJ {...props} />,
  FI: <countryFlags.FI {...props} />,
  FR: <countryFlags.FR {...props} />,
  GF: <countryFlags.GF {...props} />,
  PF: <countryFlags.PF {...props} />,
  TF: <countryFlags.TF {...props} />,
  GA: <countryFlags.GA {...props} />,
  GM: <countryFlags.GM {...props} />,
  GE: <countryFlags.GE {...props} />,
  DE: <countryFlags.DE {...props} />,
  GH: <countryFlags.GH {...props} />,
  GI: <countryFlags.GI {...props} />,
  GR: <countryFlags.GR {...props} />,
  GL: <countryFlags.GL {...props} />,
  GD: <countryFlags.GD {...props} />,
  GP: <countryFlags.GP {...props} />,
  GU: <countryFlags.GU {...props} />,
  GT: <countryFlags.GT {...props} />,
  GG: <countryFlags.GG {...props} />,
  GN: <countryFlags.GN {...props} />,
  GW: <countryFlags.GW {...props} />,
  GY: <countryFlags.GY {...props} />,
  HT: <countryFlags.HT {...props} />,
  HM: <countryFlags.HM {...props} />,
  VA: <countryFlags.VA {...props} />,
  HN: <countryFlags.HN {...props} />,
  HK: <countryFlags.HK {...props} />,
  HU: <countryFlags.HU {...props} />,
  IS: <countryFlags.IS {...props} />,
  IN: <countryFlags.IN {...props} />,
  ID: <countryFlags.ID {...props} />,
  IR: <countryFlags.IR {...props} />,
  IQ: <countryFlags.IQ {...props} />,
  IE: <countryFlags.IE {...props} />,
  IM: <countryFlags.IM {...props} />,
  IL: <countryFlags.IL {...props} />,
  IT: <countryFlags.IT {...props} />,
  JM: <countryFlags.JM {...props} />,
  JP: <countryFlags.JP {...props} />,
  JE: <countryFlags.JE {...props} />,
  JO: <countryFlags.JO {...props} />,
  KZ: <countryFlags.KZ {...props} />,
  KE: <countryFlags.KE {...props} />,
  KI: <countryFlags.KI {...props} />,
  KP: <countryFlags.KP {...props} />,
  KR: <countryFlags.KR {...props} />,
  KW: <countryFlags.KW {...props} />,
  KG: <countryFlags.KG {...props} />,
  LA: <countryFlags.LA {...props} />,
  LV: <countryFlags.LV {...props} />,
  LB: <countryFlags.LB {...props} />,
  LS: <countryFlags.LS {...props} />,
  LR: <countryFlags.LR {...props} />,
  LY: <countryFlags.LY {...props} />,
  LI: <countryFlags.LI {...props} />,
  LT: <countryFlags.LT {...props} />,
  LU: <countryFlags.LU {...props} />,
  MO: <countryFlags.MO {...props} />,
  MK: <countryFlags.MK {...props} />,
  MG: <countryFlags.MG {...props} />,
  MW: <countryFlags.MW {...props} />,
  MY: <countryFlags.MY {...props} />,
  MV: <countryFlags.MV {...props} />,
  ML: <countryFlags.ML {...props} />,
  MT: <countryFlags.MT {...props} />,
  MH: <countryFlags.MH {...props} />,
  MQ: <countryFlags.MQ {...props} />,
  MR: <countryFlags.MR {...props} />,
  MU: <countryFlags.MU {...props} />,
  YT: <countryFlags.YT {...props} />,
  MX: <countryFlags.MX {...props} />,
  FM: <countryFlags.FM {...props} />,
  MD: <countryFlags.MD {...props} />,
  MC: <countryFlags.MC {...props} />,
  MN: <countryFlags.MN {...props} />,
  ME: <countryFlags.ME {...props} />,
  MS: <countryFlags.MS {...props} />,
  MA: <countryFlags.MA {...props} />,
  MZ: <countryFlags.MZ {...props} />,
  MM: <countryFlags.MM {...props} />,
  NA: <countryFlags.NA {...props} />,
  NR: <countryFlags.NR {...props} />,
  NP: <countryFlags.NP {...props} />,
  NL: <countryFlags.NL {...props} />,
  NC: <countryFlags.NC {...props} />,
  NZ: <countryFlags.NZ {...props} />,
  NI: <countryFlags.NI {...props} />,
  NE: <countryFlags.NE {...props} />,
  NG: <countryFlags.NG {...props} />,
  NU: <countryFlags.NU {...props} />,
  NF: <countryFlags.NF {...props} />,
  MP: <countryFlags.MP {...props} />,
  NO: <countryFlags.NO {...props} />,
  OM: <countryFlags.OM {...props} />,
  PK: <countryFlags.PK {...props} />,
  PW: <countryFlags.PW {...props} />,
  PS: <countryFlags.PS {...props} />,
  PA: <countryFlags.PA {...props} />,
  PG: <countryFlags.PG {...props} />,
  PY: <countryFlags.PY {...props} />,
  PE: <countryFlags.PE {...props} />,
  PH: <countryFlags.PH {...props} />,
  PN: <countryFlags.PN {...props} />,
  PL: <countryFlags.PL {...props} />,
  PT: <countryFlags.PT {...props} />,
  PR: <countryFlags.PR {...props} />,
  QA: <countryFlags.QA {...props} />,
  RE: <countryFlags.RE {...props} />,
  RO: <countryFlags.RO {...props} />,
  RU: <countryFlags.RU {...props} />,
  RW: <countryFlags.RW {...props} />,
  BL: <countryFlags.BL {...props} />,
  SH: <countryFlags.SH {...props} />,
  KN: <countryFlags.KN {...props} />,
  LC: <countryFlags.LC {...props} />,
  MF: <countryFlags.MF {...props} />,
  PM: <countryFlags.PM {...props} />,
  VC: <countryFlags.VC {...props} />,
  WS: <countryFlags.WS {...props} />,
  SM: <countryFlags.SM {...props} />,
  ST: <countryFlags.ST {...props} />,
  SA: <countryFlags.SA {...props} />,
  SN: <countryFlags.SN {...props} />,
  RS: <countryFlags.RS {...props} />,
  SC: <countryFlags.SC {...props} />,
  SL: <countryFlags.SL {...props} />,
  SG: <countryFlags.SG {...props} />,
  SX: <countryFlags.SX {...props} />,
  SK: <countryFlags.SK {...props} />,
  SI: <countryFlags.SI {...props} />,
  SB: <countryFlags.SB {...props} />,
  SO: <countryFlags.SO {...props} />,
  ZA: <countryFlags.ZA {...props} />,
  GS: <countryFlags.GS {...props} />,
  SS: <countryFlags.SS {...props} />,
  ES: <countryFlags.ES {...props} />,
  LK: <countryFlags.LK {...props} />,
  SD: <countryFlags.SD {...props} />,
  SR: <countryFlags.SR {...props} />,
  SJ: <countryFlags.SJ {...props} />,
  SZ: <countryFlags.SZ {...props} />,
  SE: <countryFlags.SE {...props} />,
  CH: <countryFlags.CH {...props} />,
  SY: <countryFlags.SY {...props} />,
  TW: <countryFlags.TW {...props} />,
  TJ: <countryFlags.TJ {...props} />,
  TZ: <countryFlags.TZ {...props} />,
  TH: <countryFlags.TH {...props} />,
  TL: <countryFlags.TL {...props} />,
  TG: <countryFlags.TG {...props} />,
  TK: <countryFlags.TK {...props} />,
  TO: <countryFlags.TO {...props} />,
  TT: <countryFlags.TT {...props} />,
  TN: <countryFlags.TN {...props} />,
  TR: <countryFlags.TR {...props} />,
  TM: <countryFlags.TM {...props} />,
  TC: <countryFlags.TC {...props} />,
  TV: <countryFlags.TV {...props} />,
  UG: <countryFlags.UG {...props} />,
  UA: <countryFlags.UA {...props} />,
  AE: <countryFlags.AE {...props} />,
  GB: <countryFlags.GB {...props} />,
  US: <countryFlags.US {...props} />,
  UM: <countryFlags.UM {...props} />,
  UY: <countryFlags.UY {...props} />,
  UZ: <countryFlags.UZ {...props} />,
  VU: <countryFlags.VU {...props} />,
  VE: <countryFlags.VE {...props} />,
  VN: <countryFlags.VN {...props} />,
  VG: <countryFlags.VG {...props} />,
  VI: <countryFlags.VI {...props} />,
  WF: <countryFlags.WF {...props} />,
  EH: <countryFlags.EH {...props} />,
  YE: <countryFlags.YE {...props} />,
  ZM: <countryFlags.ZM {...props} />,
  ZW: <countryFlags.ZW {...props} />,
});

export const flagComponentsNames = {
  AF: countryFlags.AF,
  AX: countryFlags.AX,
  AL: countryFlags.AL,
  DZ: countryFlags.DZ,
  AS: countryFlags.AS,
  AD: countryFlags.AD,
  AO: countryFlags.AO,
  AI: countryFlags.AI,
  AQ: countryFlags.AQ,
  AG: countryFlags.AG,
  AR: countryFlags.AR,
  AM: countryFlags.AM,
  AW: countryFlags.AW,
  AU: countryFlags.AU,
  AT: countryFlags.AT,
  AZ: countryFlags.AZ,
  BS: countryFlags.BS,
  BH: countryFlags.BH,
  BD: countryFlags.BD,
  BB: countryFlags.BB,
  BY: countryFlags.BY,
  BE: countryFlags.BE,
  BZ: countryFlags.BZ,
  BJ: countryFlags.BJ,
  BM: countryFlags.BM,
  BT: countryFlags.BT,
  BO: countryFlags.BO,
  BQ: countryFlags.BQ,
  BA: countryFlags.BA,
  BW: countryFlags.BW,
  BV: countryFlags.BV,
  BR: countryFlags.BR,
  IO: countryFlags.IO,
  BN: countryFlags.BN,
  BG: countryFlags.BG,
  BF: countryFlags.BF,
  BI: countryFlags.BI,
  KH: countryFlags.KH,
  CM: countryFlags.CM,
  CA: countryFlags.CA,
  CV: countryFlags.CV,
  KY: countryFlags.KY,
  CF: countryFlags.CF,
  TD: countryFlags.TD,
  CL: countryFlags.CL,
  CN: countryFlags.CN,
  CX: countryFlags.CX,
  CC: countryFlags.CC,
  CO: countryFlags.CO,
  KM: countryFlags.KM,
  CG: countryFlags.CG,
  CD: countryFlags.CD,
  CK: countryFlags.CK,
  CR: countryFlags.CR,
  CI: countryFlags.CI,
  HR: countryFlags.HR,
  CU: countryFlags.CU,
  CW: countryFlags.CW,
  CY: countryFlags.CY,
  CZ: countryFlags.CZ,
  DK: countryFlags.DK,
  DJ: countryFlags.DJ,
  DM: countryFlags.DM,
  DO: countryFlags.DO,
  EC: countryFlags.EC,
  EG: countryFlags.EG,
  SV: countryFlags.SV,
  GQ: countryFlags.GQ,
  ER: countryFlags.ER,
  EE: countryFlags.EE,
  ET: countryFlags.ET,
  FK: countryFlags.FK,
  FO: countryFlags.FO,
  FJ: countryFlags.FJ,
  FI: countryFlags.FI,
  FR: countryFlags.FR,
  GF: countryFlags.GF,
  PF: countryFlags.PF,
  TF: countryFlags.TF,
  GA: countryFlags.GA,
  GM: countryFlags.GM,
  GE: countryFlags.GE,
  DE: countryFlags.DE,
  GH: countryFlags.GH,
  GI: countryFlags.GI,
  GR: countryFlags.GR,
  GL: countryFlags.GL,
  GD: countryFlags.GD,
  GP: countryFlags.GP,
  GU: countryFlags.GU,
  GT: countryFlags.GT,
  GG: countryFlags.GG,
  GN: countryFlags.GN,
  GW: countryFlags.GW,
  GY: countryFlags.GY,
  HT: countryFlags.HT,
  HM: countryFlags.HM,
  VA: countryFlags.VA,
  HN: countryFlags.HN,
  HK: countryFlags.HK,
  HU: countryFlags.HU,
  IS: countryFlags.IS,
  IN: countryFlags.IN,
  ID: countryFlags.ID,
  IR: countryFlags.IR,
  IQ: countryFlags.IQ,
  IE: countryFlags.IE,
  IM: countryFlags.IM,
  IL: countryFlags.IL,
  IT: countryFlags.IT,
  JM: countryFlags.JM,
  JP: countryFlags.JP,
  JE: countryFlags.JE,
  JO: countryFlags.JO,
  KZ: countryFlags.KZ,
  KE: countryFlags.KE,
  KI: countryFlags.KI,
  KP: countryFlags.KP,
  KR: countryFlags.KR,
  KW: countryFlags.KW,
  KG: countryFlags.KG,
  LA: countryFlags.LA,
  LV: countryFlags.LV,
  LB: countryFlags.LB,
  LS: countryFlags.LS,
  LR: countryFlags.LR,
  LY: countryFlags.LY,
  LI: countryFlags.LI,
  LT: countryFlags.LT,
  LU: countryFlags.LU,
  MO: countryFlags.MO,
  MK: countryFlags.MK,
  MG: countryFlags.MG,
  MW: countryFlags.MW,
  MY: countryFlags.MY,
  MV: countryFlags.MV,
  ML: countryFlags.ML,
  MT: countryFlags.MT,
  MH: countryFlags.MH,
  MQ: countryFlags.MQ,
  MR: countryFlags.MR,
  MU: countryFlags.MU,
  YT: countryFlags.YT,
  MX: countryFlags.MX,
  FM: countryFlags.FM,
  MD: countryFlags.MD,
  MC: countryFlags.MC,
  MN: countryFlags.MN,
  ME: countryFlags.ME,
  MS: countryFlags.MS,
  MA: countryFlags.MA,
  MZ: countryFlags.MZ,
  MM: countryFlags.MM,
  NA: countryFlags.NA,
  NR: countryFlags.NR,
  NP: countryFlags.NP,
  NL: countryFlags.NL,
  NC: countryFlags.NC,
  NZ: countryFlags.NZ,
  NI: countryFlags.NI,
  NE: countryFlags.NE,
  NG: countryFlags.NG,
  NU: countryFlags.NU,
  NF: countryFlags.NF,
  MP: countryFlags.MP,
  NO: countryFlags.NO,
  OM: countryFlags.OM,
  PK: countryFlags.PK,
  PW: countryFlags.PW,
  PS: countryFlags.PS,
  PA: countryFlags.PA,
  PG: countryFlags.PG,
  PY: countryFlags.PY,
  PE: countryFlags.PE,
  PH: countryFlags.PH,
  PN: countryFlags.PN,
  PL: countryFlags.PL,
  PT: countryFlags.PT,
  PR: countryFlags.PR,
  QA: countryFlags.QA,
  RE: countryFlags.RE,
  RO: countryFlags.RO,
  RU: countryFlags.RU,
  RW: countryFlags.RW,
  BL: countryFlags.BL,
  SH: countryFlags.SH,
  KN: countryFlags.KN,
  LC: countryFlags.LC,
  MF: countryFlags.MF,
  PM: countryFlags.PM,
  VC: countryFlags.VC,
  WS: countryFlags.WS,
  SM: countryFlags.SM,
  ST: countryFlags.ST,
  SA: countryFlags.SA,
  SN: countryFlags.SN,
  RS: countryFlags.RS,
  SC: countryFlags.SC,
  SL: countryFlags.SL,
  SG: countryFlags.SG,
  SX: countryFlags.SX,
  SK: countryFlags.SK,
  SI: countryFlags.SI,
  SB: countryFlags.SB,
  SO: countryFlags.SO,
  ZA: countryFlags.ZA,
  GS: countryFlags.GS,
  SS: countryFlags.SS,
  ES: countryFlags.ES,
  LK: countryFlags.LK,
  SD: countryFlags.SD,
  SR: countryFlags.SR,
  SJ: countryFlags.SJ,
  SZ: countryFlags.SZ,
  SE: countryFlags.SE,
  CH: countryFlags.CH,
  SY: countryFlags.SY,
  TW: countryFlags.TW,
  TJ: countryFlags.TJ,
  TZ: countryFlags.TZ,
  TH: countryFlags.TH,
  TL: countryFlags.TL,
  TG: countryFlags.TG,
  TK: countryFlags.TK,
  TO: countryFlags.TO,
  TT: countryFlags.TT,
  TN: countryFlags.TN,
  TR: countryFlags.TR,
  TM: countryFlags.TM,
  TC: countryFlags.TC,
  TV: countryFlags.TV,
  UG: countryFlags.UG,
  UA: countryFlags.UA,
  AE: countryFlags.AE,
  GB: countryFlags.GB,
  US: countryFlags.US,
  UM: countryFlags.UM,
  UY: countryFlags.UY,
  UZ: countryFlags.UZ,
  VU: countryFlags.VU,
  VE: countryFlags.VE,
  VN: countryFlags.VN,
  VG: countryFlags.VG,
  VI: countryFlags.VI,
  WF: countryFlags.WF,
  EH: countryFlags.EH,
  YE: countryFlags.YE,
  ZM: countryFlags.ZM,
  ZW: countryFlags.ZW,
};
