import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Flex,
  Heading,
  Text,
  ButtonGroup,
  Box,
  Button,
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  CloseButton,
} from '@chakra-ui/react';

import { useAuth } from '../../contexts/AuthContext';

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

function EditPrivacy(props) {
  // const [showNewPsw, setShowNewPsw] = useState(false);
  const { reimpostaPassword } = useAuth();
  const [pswRequestStatus, setPswResetStatus] = useState('');
  // const [emailRequestStatus, setEmailResetStatus] = useState('');

  /*   const toggleNewPsw = () => {
    setShowNewPsw(!showNewPsw);
  }; */

  const handlePswFormSubmit = (evt) => {
    evt.preventDefault();
    reimpostaPassword(props.currentUser.email);
  };

  return (
    <Flex direction="column" alignItems="center" width={{ base: '100%', lg: '60%' }} pt={6} pl={6} mb={3}>
      <Heading as="h3" size="md" color="blackAlpha.700" width="100%" alignSelf="flex-start" mb={10}>
        Privacy
      </Heading>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          width: '100%',
          marginRight: '1.125rem',
        }}
        method="post"
        onSubmit={handlePswFormSubmit}>
        <ButtonGroup
          width="100%"
          border="1px solid rgba(0,0,0,0.1)"
          borderRadius="md"
          p={2}
          position="relative"
          display="flex"
          flexDirection="column">
          {(pswRequestStatus === 'error' || pswRequestStatus === 'info') && (
            <Alert status={pswRequestStatus} m={2} borderRadius="md" maxWidth="95%" transition="all 200ms">
              <AlertIcon />
              <Box flex="1">
                <AlertTitle textTransform="capitalize">Quasi fatto!</AlertTitle>
                <AlertDescription display="block">
                  {pswRequestStatus === 'info'
                    ? 'Ti abbiamo inviato una mail, segui le istruzioni per cambiare la password!'
                    : 'Ops... Si è verificato un errore, contattaci a info@feedinapp.com.'}
                </AlertDescription>
              </Box>
              <CloseButton
                position="absolute"
                right="8px"
                top="8px"
                onClick={() => {
                  setPswResetStatus('');
                }}
              />
            </Alert>
          )}

          <Text
            backgroundColor="white"
            fontSize="sm"
            color="blackAlpha.800"
            position="absolute"
            top="0"
            right="0"
            transform="translate(-50%, -50%)"
            pl={2}
            pr={2}
            fontWeight="medium">
            Reimposta password
          </Text>

          <Button
            colorScheme="green"
            size="md"
            type="submit"
            mt={6}
            alignSelf="flex-start"
            onClick={() => {
              setPswResetStatus('info');
            }}>
            <Text pl={3} pr={3}>
              Reimposta password
            </Text>
          </Button>
        </ButtonGroup>
      </form>
    </Flex>
  );
}

export default connect(mapStateToProps)(EditPrivacy);
