import React from 'react';
import { Flex, Text, Heading, Icon, List, ListItem, ListIcon, LinkBox, LinkOverlay, Link } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { connect } from 'react-redux';
import UnauthenticatedCard from '../../UnauthenticatedCard';

import { goToCheckout } from '../../../utils/stripe-utils';

import './Styles/Recap.css';

const mapStateToProps = (state) => ({
  currentUser: state.currentUser,
});

function Recap({ currentUser }) {
  const leftCardContent = (
    <Flex as="article" pl={2} direction="column">
      <Heading color="whitesmoke" width="100%" as="h3">
        Prova FeedInApp gratis
      </Heading>
      <List spacing={1} mt={5} color="white" ml="auto" mr="auto">
        <ListItem display="flex" alignItems="center">
          <ListIcon as={MdCheckCircle} boxSize="24px" color="#66ff66" />
          Menu digitale con codice QR
        </ListItem>
        <ListItem display="flex" alignItems="center">
          <ListIcon as={MdCheckCircle} boxSize="24px" color="#66ff66" />
          Social network
        </ListItem>
        <ListItem display="flex" alignItems="center">
          <ListIcon as={MdCheckCircle} boxSize="24px" color="#66ff66" />
          Modifiche illimitate
        </ListItem>
        <ListItem display="flex" alignItems="center">
          <ListIcon as={MdCheckCircle} boxSize="24px" color="#66ff66" />
          Immagini
        </ListItem>
        <ListItem display="flex" alignItems="center">
          <ListIcon as={MdCheckCircle} boxSize="24px" color="#66ff66" />
          Allergeni
        </ListItem>
        <ListItem display="flex" alignItems="center">
          <ListIcon as={MdCheckCircle} boxSize="24px" color="#66ff66" />
          Assistenza gratuita
        </ListItem>
        <ListItem display="flex" alignItems="center">
          <ListIcon as={MdCheckCircle} boxSize="24px" color="#66ff66" />
          Voci in inglese
        </ListItem>
      </List>
    </Flex>
  );

  return (
    <UnauthenticatedCard overlayContent={leftCardContent}>
      <Flex as="article" pl={3} direction="column" width="100%" alignItems="center">
        <LinkBox
          display="flex"
          flexDirection="column"
          borderRadius="md"
          border="1px solid"
          borderColor="#4bac61"
          overflow="hidden"
          maxWidth="80%"
          width="80%"
          p={0}
          pt={3}>
          <Text fontWeight="medium" fontSize="medium" color="blackAlpha.900" width="100%" pl={3} pr={3}>
            PIANO MENSILE
          </Text>
          <Flex direction="row" alignItems="flex-end" pl={3} pr={3}>
            <Text fontWeight="semibold" fontSize="2xl" color="blackAlpha.900" as="span" mr={1}>
              12.99{'\u20AC'}
            </Text>
            <Text fontWeight="semibold" fontSize="normal" color="blackAlpha.900" as="span" mr={1}>
              + IVA
            </Text>
            <Text fontWeight="normal" fontSize="normal" color="blackAlpha.800" as="span">
              /mese
            </Text>
          </Flex>
          <LinkOverlay
            onClick={() => goToCheckout(currentUser)}
            _hover={{
              color: 'blackAlpha.900',
              cursor: 'pointer',
            }}>
            <Flex
              alignSelf="flex-end"
              p={2}
              alignItems="center"
              width="100%"
              mt={3}
              pl={3}
              pr={3}
              className="transition-gradient">
              <Text color="white" fontSize="md" fontWeight="bold" zIndex="2">
                Prova gratis per 30 giorni
              </Text>
              <Icon as={ChevronRightIcon} h={8} w={8} color="white" ml="auto" zIndex="2" />
            </Flex>
          </LinkOverlay>
        </LinkBox>

        <LinkBox
          border="1px solid red"
          width="80%"
          textAlign="center"
          borderRadius="md"
          borderColor="gray.500"
          _hover={{ borderColor: 'gray.800' }}
          mt={5}>
          <LinkOverlay href="/" transition=".3s ease all" color="blackAlpha.700" _hover={{ color: 'blackAlpha.900' }}>
            <Text fontSize="sm" fontWeight="bold" p={3}>
              Salta e torna alla home
            </Text>
          </LinkOverlay>
        </LinkBox>

        <Text color="gray.800" fontSize="sm" mt={5} width="80%">
          Il tuo abbonamento verrà rinnovato in automatico. Potrai disattivare il rinnovo del tuo abbonamento in ogni
          momento.
        </Text>

        <Link to="/">
          <Text fontSize="md" color="gray.800" textDecoration="underline">
            Problemi con la registrazione?
          </Text>
        </Link>
      </Flex>
    </UnauthenticatedCard>
  );
}

export default connect(mapStateToProps)(Recap);
