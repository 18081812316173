import React from 'react';
import { connect } from 'react-redux';
import MakePostForm from './Home/MakePostForm';
import Post from './Home/Post';
import '../Styles/Overlay.css';

const mapStateToProps = (state) => ({
  posts: state.posts,
});

function Home({ posts }) {
  return (
    <>
      <MakePostForm />
      {Object.keys(posts)
        .reverse()
        .map((postID) => (
          <Post id={postID} key={postID} />
        ))}
    </>
  );
}

export default connect(mapStateToProps)(Home);
