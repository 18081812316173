import { useState } from 'react';
import PropTypes from 'prop-types';

function ImageLoader(props) {
  const [images, setImages] = useState([]);
  const [previewURLs, setPreviewURLs] = useState([]);

  const handleImagesSelected = (e) => {
    e.preventDefault();

    const reader = new Array(props.limit);
    const files = Array.from(e.target.files).filter(
      (file, idx) => idx < props.limit - Object.values(previewURLs).length,
    );

    files.forEach((image, idx) => {
      reader[idx] = new FileReader();

      reader[idx].onloadend = () => {
        setImages((images) => [...images, image]);
        setPreviewURLs((previewURLs) => {
          const newURLs = [...previewURLs, reader[idx].result];
          props.onLoadEnd(newURLs);
          return newURLs;
        });
      };

      reader[idx].readAsDataURL(image);
    });
  };

  const handleSingleImageSelected = (e) => {
    e.preventDefault();

    let reader = null;
    const file = e.target.files[0];

    if (file) {
      reader = new FileReader();

      reader.onloadend = () => {
        setImages([file]);
        setPreviewURLs([reader.result]);
        props.onLoadEnd(reader.result, file);
      };

      reader.readAsDataURL(file);
    }
  };

  const removeAt = (idx) => {
    let newIdx;
    if (idx < 0) newIdx = 0;
    else if (idx >= images.length) newIdx = images.length - 1;

    setImages((images) => images.splice(newIdx, 1));
    setPreviewURLs((previewURLs) => previewURLs.splice(newIdx, 1));
  };

  const flush = () => {
    setImages([]);
    setPreviewURLs([]);
  };

  const inputStyles = {
    display: 'none',
  };

  return props.children(
    images,
    previewURLs,
    inputStyles,
    props.multiple ? handleImagesSelected : handleSingleImageSelected,
    flush,
    removeAt,
  );
}

ImageLoader.propTypes = {
  children: PropTypes.func.isRequired,
  limit: PropTypes.number,
  multiple: PropTypes.bool,
  onLoadEnd: PropTypes.func,
};

ImageLoader.defaultProps = {
  children: () => {
    console.error('ImageLoader error: exprected function as children!');
  },
  limit: 1,
  multiple: false,
  onLoadEnd: () => {},
};

export default ImageLoader;
