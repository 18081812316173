/* eslint-disable default-param-last */
import {
  DATA_FETCHED,
  EDIT_RESTAURANT,
  DELETE_CATEGORY,
  ADD_POST,
  DELETE_POST,
  ADD_RESTAURANT,
  LOAD_CATEGORIES,
  LOAD_MENU,
  UPDATE_USER_CREDENTIALS,
  REPOSITION_CATEGORIES,
  LOAD_DISHES,
  SAVE_DISH,
  FETCH_POSTS,
  UPDATE_LOCAL_POST_IMAGES,
  APPEND_SUBSCRIPTION_STATUS,
} from '../Actions/ActionTypes';

/**
 * IMPORTANT DISCLAIMER ABOUT THE STATE ITEMS:
 *
 * @param: 'restaurant' will contain all the basic restaurant info.
 * @param: 'restaurantEdited' will contain all the edited informations about the restaurant
 *
 * @param: 'categories' will contain the fetched categories that are at the moment
 *          undeleted and unedited. Editing a category means to change its attributes, like
 *          description, image and such. As for now, editing/adding/removing dishes from a category
 *          will not flag the latter as edited.
 * @param: 'deletedCategories' will contain only the categories that are listed for deletion.
 * @param: 'editedCategories' will contain the pre-existing, undeleted categories that were changed, whereas the
 *          meaning of 'changed' is specified above.
 * @param: 'newCategories' will contain only the categories generated during this session. Editing or deleting
 *          one of those will not cause the new category to move in one of the other state components.
 *
 * Note that {'categories', 'deletedCategories', 'editedCategories', 'newCategories'} is a partition of the
 * categories space. As such, any category can belong to only one of the aforementioned and the union of those
 * will give us the full categories list.
 *
 * @param: 'dishes' will be an hashmap where the keys will be the categoryID of the dish.
 *          This array will contain all the previously existing dishes that weren't edited or deleted.
 * @param: 'deletedDishes' will be an hashmap where the keys will be the categoryID of the dish.
 *          This array will contain the deleted dishes. Deletion of a dish will result from deleting
 *          the category it belongs to or directly deleting it via the corresponding button.
 * @param:  'editedDished' will be an hashmap where the keys will be the categoryID of the dish.
 *          This array will contain only the previously existing, undeleted dishes that were changed in
 *          at least one attribute.
 * @param: 'newDishes' will be an hashmap where the keys will be the categoryID of the dish.
 *          This array will contain all the newly created dishes regarless of whether or not they were
 *          edited since its push to this array. Deleting a dish from this array will not move it to
 *          'deletedDishes' but will cause it to be immediately popped.
 */

const initialState = {
  restaurant: {},
  /* restaurantEdited: {}, */

  categories: [],
  /*    deletedCategories: [],
       editedCategories: [],
       newCategories: [], */

  dishes: {},
  /* deletedDishes: [],
    editedDishes: [],
    newDishes: [], */

  menu: {},

  posts: [],

  currentUser: {},
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER_CREDENTIALS:
      return {
        ...state,
        currentUser: action.payload.userCredentials,
      };

    case ADD_RESTAURANT:
      return {
        ...state,
        restaurant: action.payload.restaurant,
      };

    case LOAD_CATEGORIES:
      return {
        ...state,
        categories: action.payload.categories,
      };

    case REPOSITION_CATEGORIES:
      return {
        ...state,
        categories: action.payload.categories,
      };

    case LOAD_DISHES:
      return {
        ...state,
        dishes: action.payload.dishes,
      };

    case LOAD_MENU:
      return {
        ...state,
        menu: action.payload.menu,
      };

    case DATA_FETCHED:
      return {
        ...state,
        restaurant: action.payload.restaurant,
        categories: action.payload.categories,
        dishes: action.payload.dishesByCategory,
      };

    case EDIT_RESTAURANT:
      return {
        ...state,
        restaurant: { ...state.restaurant, ...action.payload.restaurant },
      };

    case DELETE_CATEGORY: {
      const idx = action.payload.previousCategory.findIndex((cat) => cat.id === action.payload.category.id);
      if (idx !== -1)
        return {
          ...state,
          [action.payload.previousCategory]: [
            ...action.payload.previousCategory.slice(0, idx),
            { ...action.payload.previousCategory[idx], ...action.payload.category },
            ...action.payload.previousCategory.slice(idx + 1),
          ],

          deletedCategories: state.deletedCategories.push(action.payload.category),
        };
      return state;
    }

    case SAVE_DISH:
      return {
        ...state,
        dishes: {
          ...state.dishes,
          [action.payload.categoryKey]: state.dishes[action.payload.categoryKey].map((dish) =>
            dish.itemKey === action.payload.dishID ? action.payload.dish : dish,
          ),
        },
      };

    case FETCH_POSTS: {
      return {
        ...state,
        posts: action.payload.posts,
      };
    }

    case ADD_POST: {
      return {
        ...state,
        posts: { ...state.posts, [action.payload.post.postKey]: action.payload.post },
      };
    }

    case DELETE_POST: {
      const newPosts = { ...state.posts };
      delete newPosts[action.payload.postKey];

      return {
        ...state,
        posts: newPosts,
      };
    }

    case UPDATE_LOCAL_POST_IMAGES: {
      const newPosts = { ...state.posts };
      Object.assign(newPosts[action.payload.postID], { image: action.payload.images });

      return {
        ...state,
        posts: newPosts,
      };
    }

    case APPEND_SUBSCRIPTION_STATUS: {
      console.log(action.payload.status);
      const newRestaurant = { ...state.restaurant };
      Object.assign(newRestaurant, { subscriptionStatus: action.payload.status });

      return {
        ...state,
        restaurant: newRestaurant,
      };
    }

    default:
      return state;
  }
};

export default rootReducer;
