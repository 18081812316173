import React from 'react';
import { useTranslation } from 'react-i18next';

import SyncLoader from 'react-spinners/SyncLoader';
import { Flex, Image, Text } from '@chakra-ui/react';
import logo from '../assets/logo.png';

function LoadingScreen() {
  const { t } = useTranslation();
  return (
    <Flex width="100vw" height="100vh" justifyContent="center" alignItems="center" direction="column">
      <Image src={logo} width="20%" maxWidth="150px" mb={4} />

      <SyncLoader
        size={15}
        color="#4BAC61"
        loading
        margin={2}
        style={{ position: 'absolute', left: '50%', top: '50%' }}
      />

      <Text pl={3} pr={3} fontSize="xl" fontWeight="medium" color="lightblack">
        {t('loadingText')}
      </Text>
      <Text pl={3} pr={3} mt={1} fontSize="normal" fontWeight="normal" color="lightblack">
        {t('loading')}
      </Text>
    </Flex>
  );
}

export default LoadingScreen;
