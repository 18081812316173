import 'firebase/database';
import 'firebase/storage';
import firebase from 'firebase/app';
import { loadCategories, loadDishes } from '../Redux/Actions/Actions';
import { database } from '../utils/firebase';
import { uploadImage } from './uploadImage';

export const putNewCategory = (category) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const newCategories = [...getState().categories, category];
    const { restaurantKey } = getState().restaurant;
    const newDishes = { ...getState().dishes };

    database
      .ref(`categories/${restaurantKey}/categories`)
      .push(category)
      .then((resp) => {
        database
          .ref(`categories/${restaurantKey}/categories`)
          .child(resp.key)
          .update({ categoryKey: resp.key })
          .then((res) => {
            newCategories[newCategories.length - 1].categoryKey = resp.key;
            newDishes[resp.key] = [];
            dispatch(loadDishes(newDishes));
            dispatch(loadCategories(newCategories));
            resolve(res);
          })
          .catch((err) => reject(err));
      })
      .catch((err) => resolve(err));
  });

/**
 * @param {Object} newPositions: index: the index of the category in 'categories', pos: the new position, updated: true
 */
export const repositionCategories = (newPositions) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { categories } = getState();
    const { restaurantKey } = getState().restaurant;

    const newCategories = { ...categories };

    newPositions.forEach((newPos) => {
      newCategories[newPos.index].pos = newPos.pos;
    });

    dispatch(loadCategories(newCategories));

    const updateTasks = newPositions.map((newPos) =>
      database
        .ref(`categories/${restaurantKey}/categories`)
        .child(categories[newPos.index].categoryKey)
        .update({ pos: newPos.pos }),
    );

    Promise.all(updateTasks)
      .then((responses) => {
        resolve(responses);
      })
      .catch((errors) => reject(errors));
  });

/**
 * @todo: delete the image category if there's any
 */
export const deleteCategory = (categoryKey) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { categories } = getState();
    const { dishes } = getState();
    const { restaurantKey } = getState().restaurant;

    let categoryFound = false;
    const categoriesToUpdate = [];
    const newCategories = categories
      .map((cat) => {
        if (cat.categoryKey === categoryKey) {
          categoryFound = true;
          return null;
        }
        if (!categoryFound) return cat;

        categoriesToUpdate.push({
          categoryKey: cat.categoryKey,
          pos: cat.pos - 1,
        });
        return { ...cat, pos: cat.pos - 1 };
      })
      .filter((cat) => cat !== null);

    database
      .ref(`categories/${restaurantKey}/categories`)
      .child(categoryKey)
      .remove()
      .catch((err) => reject(err));

    const updaterTasks = categoriesToUpdate
      .filter((cat) => cat.categoryKey !== categoryKey)
      .map((cat) =>
        database.ref(`categories/${restaurantKey}/categories`).child(cat.categoryKey).update({ pos: cat.pos }),
      );

    const imageDeletersTasks = dishes[categoryKey]
      .map((dish) => {
        if (!!dish.image) return firebase.storage().refFromURL(dish.image).delete();
        return null;
      })
      .filter((it) => it !== null);

    Promise.all(updaterTasks)
      .then((updateRes) => {
        if (dishes[categoryKey].length > 0) {
          const dishDeleterTasks = dishes[categoryKey].map((dish) =>
            database.ref(`menu/${restaurantKey}/items`).child(dish.itemKey).remove(),
          );
          Promise.all(dishDeleterTasks)
            .then((res) => {
              const newDishes = { ...dishes };

              delete newDishes[categoryKey];

              dispatch(loadCategories(newCategories));
              dispatch(loadDishes(newDishes));

              if (imageDeletersTasks.length > 0)
                Promise.all(imageDeletersTasks)
                  .then(() => resolve(res))
                  .catch((err) => reject(err));
              else resolve(res);
            })
            .catch((err) => reject(err));
        } else {
          dispatch(loadCategories(newCategories));
          resolve(updateRes);
        }
      })
      .catch((err) => reject(err));
  });

const updaterTask = (newCategory, reject, resolve) => {
  database
    .ref(`categories/${newCategory.restaurantKey}/categories`)
    .child(newCategory.categoryKey)
    .update({ ...newCategory })
    .then(() => {
      resolve(true);
    })
    .catch((err) => reject(err));
};

export const updateCategory = (newCategory, image) => () =>
  new Promise((resolve, reject) => {
    if (!!image)
      uploadImage(`images/${newCategory.restaurantKey}/categories/${newCategory.categoryKey}`, image)
        .then((downloadURL) => {
          Object.assign(newCategory, { image: downloadURL });
        })
        .then(() => {
          updaterTask(newCategory, reject, resolve);
        })
        .catch((err) => reject(err));
    else updaterTask(newCategory, reject, resolve);
  });
